import React, { Component } from 'react';
import PropTypes from 'prop-types';
import assets from '../../../assets';
import { shoppingCart } from '../../../actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

class DetailsDescription extends Component {
  versionsHandler = (event) => {
    const index = this.props.data.findIndex(
      (artifact) => artifact.version === event.target.value
    );
    this.props.setSelectedArtifactIndex(index);
    this.props.history.push(
      `/details/${this.props.data[index].name}/${this.props.data[index].version}`
    );
  };

  goToCartList = () => {
    if (this.props.currentUser !== null) {
      const { artifact } = this.props;
      this.props.shoppingCart(
        artifact.uniqueId,
        this.props.alreadyPurchased,
        this.props.shoppingCarData
      );
      this.props.history.push('/shoppingCart');
    } else {
      this.props.history.push('/login');
    }
  };

  render() {
    const { artifact, versions } = this.props;
    const imgSrc = artifact.imageUrl;
    return (
      <div className="row">
        <div className="col-lg-2 text-center">
          <img
            className="card-img-top-list"
            src={imgSrc || assets.images.ProEDMS}
            alt=""
          />
        </div>
        <div className="col-lg-10">
          <div className="">
            <div className="row">
              <h4 className="plugin-name-details">
                {artifact.converted_name
                  ? artifact.converted_name
                  : artifact.name}
              </h4>
              <div className="smallText-13 smallText-13-div">
                {artifact.widgets && artifact.widgets.length > 1 && (
                  <img className="pull-right" src={assets.images.cube} alt="" />
                )}
                <div className="row">
                  <label
                    style={{ marginTop: '10px' }}
                    className="fa fa-code-fork fa-2x"
                  />
                  <div className="mgLeft10">
                    <TextField
                      variant="outlined"
                      id="standard-select-version"
                      select
                      label="Version"
                      color="primary"
                      fullWidth
                      value={artifact.version}
                      onChange={(event) => this.versionsHandler(event)}
                    >
                      {versions.map((element, index) => (
                        <MenuItem key={element} value={element}>
                          {element}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </div>
              {artifact.isAlreadyPurchased ? (
                <label className="mg-cart-plus">
                  <button className="btn btn-gray">Already purchased</button>{' '}
                </label>
              ) : (
                artifact.commercial === true && (
                  <label className="mg-cart-plus">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.goToCartList();
                      }}
                    >
                      <label
                        className="fa fa-cart-plus fa-2x icon-cart-plus"
                        style={{ cursor: 'pointer', color: 'white', fontSize: 'medium' }}
                      />
                      ADD TO CART
                    </button>
                  </label>
                )
              )}
            </div>
            <ol />
            <p>{artifact.description}</p>
          </div>
        </div>
      </div>
    );
  }
}

DetailsDescription.propTypes = {
  currentUser: PropTypes.object,
  authToken: PropTypes.string,
  isUserSet: PropTypes.bool.isRequired,
  alreadyPurchased: PropTypes.array.isRequired,
  shoppingCarData: PropTypes.array.isRequired,
  shoppingCart: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  alreadyPurchased: state.shoppingCart.alreadyPurchased,
  shoppingCarData: state.shoppingCart.data,
  ...ownProps,
});

const mapActionsToProps = {
  shoppingCart: shoppingCart.addShoppingCart,
};

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(DetailsDescription)
);
