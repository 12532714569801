import {
    SET_ACTIVE_TAB,
    SET_ACTIVE_TAB_PIPELINE
} from './types';
export const SET_ATIVE_VIEW_TYPE = 'SET_ATIVE_VIEW_TYPE';

export const setActiveViewType = (activeViewType) => ({
    type: SET_ATIVE_VIEW_TYPE,
    payload: { activeViewType },
});

export const setActiveTab = (activeTab) => ({
    type: SET_ACTIVE_TAB,
    payload: {
        activeTab,
    }
});
export const setActiveTabPipeline = (activeTab) => ({
    type: SET_ACTIVE_TAB_PIPELINE,
    payload: {
        activeTab,
    }
})