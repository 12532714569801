import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pipelines } from '../../../actions';
import { set as setIn } from 'lodash';
import DeletePopup from '../DeletePopup';
import PipelineTable from './Table';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

class PipelineItem extends Component {
  state = {
    popup: false,
    openMenu: false,
    anchorEl: null,
  };

  fetchPipelines = async () => {
    await this.props.resetPipelineData();
    await this.props.getNamespaces(
      this.props.instanceData.uniqueId,
      this.props.currentUser.ma
    );
    await this.props.setNameSpace(
      this.props.instanceNamespaces !== null
        ? this.props.instanceNamespaces.namespaces[0]['name']
        : ''
    );
    if (this.props.nameSpace !== '') {
      await this.props.fetchInstancePipelines(
        this.props.instanceData.uniqueId,
        this.props.nameSpace,
        this.props.currentUser.ma
      );
    }
  };

  changeHandler = (event) => {
    const newState = setIn(this.state, event.target.name, event.target.value);
    this.setState({
      ...newState,
    });
  };

  popupHandler = () => {
    this.setState({
      popup: !this.state.popup,
    });
  };

  deleteInstance = async () => {
    const { currentUser } = this.props;
    await this.props.deleteInstance(
      { instance_id: this.props.instanceData.uniqueId },
      currentUser.uid,
      currentUser.ma
    );
    await this.props.fetchInstances(currentUser.uid, currentUser.ma);
    this.popupHandler();
  };

  changeNameSpaceEvent = async (event) => {
    let value = event.target.value;
    await this.props.setNameSpace(value);
    await this.props.fetchInstancePipelines(
      this.props.instanceData.uniqueId,
      this.props.nameSpace,
      this.props.currentUser.ma
    );
  };

  handleClick = () => {
    this.setState({
      openMenu: !this.state.openMenu,
    });
  };

  disableInstance = () => {
    const pendingAndProcessing = this.props.queueTasks.filter(
      (el) => el.status === 'pending' || el.status === 'processing'
    );
    const res = pendingAndProcessing.some(
      (el) => el.instance_id === this.props.instanceData.uniqueId
    );
    return res;
  };

  render() {
    const checkUniqueId =
      this.props.pipeline_data_instance_id === this.props.instanceData.uniqueId;
    return (
      <div className="col-lg-12">
        <div className="button-instance">
          <div className="row" style={{ padding: '0 30px' }}>
            <button
              className="instance-name row"
              style={{
                paddingTop: '8px',
                flex: '1%',
                cursor: 'pointer',
                background: 'none',
                border: 'none',
                color: '#525f7f',
              }}
              disabled={this.disableInstance()}
              onClick={
                !this.props.instanceData.expand === true &&
                this.props.instanceData &&
                this.props.instanceData.namespacesData
                  ? () => {
                      this.props.handleExpand(this.props.instanceIndex);
                    }
                  : this.props.instanceData.expand === true
                  ? () => {
                      this.props.handleExpand(this.props.instanceIndex);
                    }
                  : () => {
                      this.fetchPipelines();
                    }
              }
            >
              {this.props.isLoading && checkUniqueId ? (
                <div className="spinner-border mgLeft10" />
              ) : this.props.instanceData.expand === true ? (
                <i className="fa fa-chevron-down mgLeft10 mg-top-4" />
              ) : (
                <i className="fa fa-chevron-right mgLeft10 mg-top-4" />
              )}
              <div style={{ marginLeft: '15px' }}>
                {this.props.instanceData.instanceName}
              </div>
            </button>
            {this.props.instanceData.expand === true && (
              <div
                className="row"
                style={{
                  flex: '0.755 1 1%',
                  margin: '3px 0px 6px',
                }}
              >
                <Tooltip title="Refresh">
                  <button
                    className="btn btn-light"
                    onClick={() => {
                      this.fetchPipelines();
                    }}
                    style={{
                      padding: '0.25rem 0.8rem',
                      fontWeight: '100',
                      boxShadow: 'none',
                    }}
                  >
                    <i
                      className="fa fa-refresh"
                      style={{ marginRight: '5px', fontSize: '15px' }}
                    />
                    Refresh
                  </button>
                </Tooltip>
                <p
                  className="text-right"
                  style={{ paddingTop: '10px', fontSize: '14px' }}
                >
                  Namespace:
                </p>
                <select
                  className="select-namespace text-right"
                  style={{ margin: '6px 10px 0px' }}
                  name="nameSpace"
                  value={this.props.instanceData.currentNamespace}
                  onChange={(event) => {
                    this.changeNameSpaceEvent(event);
                  }}
                >
                  {this.props.instanceData &&
                    this.props.instanceData.namespacesData &&
                    this.props.instanceData.namespacesData.namespaces.map(
                      (element, index) => (
                        <option
                          style={{ background: 'transparent' }}
                          key={index}
                          value={element.name}
                        >
                          {element.name}
                        </option>
                      )
                    )}
                </select>
              </div>
            )}
            <div className="text-right">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => {
                  this.setState({
                    anchorEl: event.currentTarget,
                  });
                  this.handleClick();
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={this.state.anchorEl}
                id="long-menu"
                open={this.state.openMenu}
                onClose={() => {
                  this.handleClick();
                }}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: '23ch',
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    this.props.history.push({
                      pathname: '/instance/edit',
                      instanceIndex: this.props.instanceIndex,
                    });
                    this.handleClick();
                  }}
                >
                  <i className="fa fa-edit btn-edit pd-right-10"></i>
                  <Typography variant="inherit">Edit</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.popupHandler();
                    this.handleClick();
                  }}
                >
                  <i className="fa fa-trash btn-edit pd-right-10"></i>
                  <Typography variant="inherit">Delete</Typography>
                </MenuItem>
                {this.props.instanceData &&
                  this.props.instanceData.namespacesData && (
                    <MenuItem
                      onClick={() => {
                        this.props.exportAllPipelines(
                          this.props.currentUser.ma,
                          this.props.instanceData.uniqueId
                        );
                      }}
                    >
                      <i className="fa fa-file-archive-o btn-edit pd-right-10"></i>
                      <Typography variant="inherit">
                        Export all pipelines
                      </Typography>
                    </MenuItem>
                  )}
              </Menu>
            </div>
          </div>
          {this.props.instanceData.expand === true && (
            <div>
              <ul
                className="instance-info nav"
                style={{ paddingBottom: '15px', paddingLeft: '15px' }}
              >
                <li className="mg-right-10">
                  Version:{' '}
                  {this.props.instanceData &&
                    this.props.instanceData.namespacesData &&
                    this.props.instanceData.namespacesData.version.version}
                </li>
                <li className="mg-right-10">|</li>
                <li className="mg-right-10">
                  Namespaces:{' '}
                  {this.props.instanceData &&
                    this.props.instanceData.namespacesData &&
                    this.props.instanceData.namespacesData.namespaces.length}
                </li>
                <li className="mg-right-10">|</li>
                <li className="mg-right-10">
                  Pipelines: {this.props.instanceData.pipelines.length}
                </li>
              </ul>
              <PipelineTable
                instanceData={this.props.instanceData}
                instanceIndex={this.props.instanceIndex}
              />
            </div>
          )}
        </div>
        {this.state.popup && (
          <DeletePopup
            popupHandler={this.popupHandler.bind(this)}
            deleteInstance={this.deleteInstance.bind(this)}
            instanceName={this.props.instanceData.instanceName}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pipelineData: state.pipelines.data,
  instanceNamespaces: state.pipelines.instanceNamespaces,
  nameSpace: state.pipelines.nameSpace,
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  activeComponent: state.myPlugins.activeComponent,
  isLoading: state.pipelines.isLoading,
  pipeline_data_instance_id: state.pipelines.instance_id,
  queueTasks: state.pipelines.queueTasks,
});

const mapActionsToProps = {
  setNameSpace: pipelines.setNameSpace,
  fetchInstancePipelines: pipelines.fetchInstancePipelines,
  deleteInstance: pipelines.deleteInstance,
  resetPipelineData: pipelines.resetPipelineData,
  fetchInstances: pipelines.fetchInstances,
  getNamespaces: pipelines.getNamespaces,
  handleExpand: pipelines.handleExpand,
  exportAllPipelines: pipelines.exportAllPipelines,
};

export default connect(mapStateToProps, mapActionsToProps)(PipelineItem);
