import { combineReducers } from "redux";
import userReducer from "./user";
import oss from "./oss";
import activeState from './activeState';
import details from './details';
import myPlugins from './myPlugins';
import shoppingCart from './shoppingCart';
import paymentMethods from './paymentMethods';
import pipelines from './pipelines';
import transferPipelines from './transferPipelines';

const rootReducer = combineReducers({
  user: userReducer,
  oss: oss,
  activeState: activeState,
  details: details,
  myPlugins: myPlugins,
  shoppingCart: shoppingCart,
  paymentMethods: paymentMethods,
  pipelines: pipelines,
  transferPipelines: transferPipelines,
});

export default rootReducer;
