export default {
  images: {
    github:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fgithub.svg?alt=media&token=2c9d8c9e-f933-4b76-b78f-c2ffb602053f',
    google:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fgoogle.svg?alt=media&token=c8d16ee5-3a55-451b-a6fc-ff0b8fdca5e8',
    cube:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fcube.png?alt=media&token=363abbf8-2221-4112-acd9-8a92e8d86890',
    upload:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fupload.jpg?alt=media&token=5a1a6937-0056-4578-9127-846d155a5327',
    jar:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fjar.png?alt=media&token=115dd68c-3ac5-4b8d-b592-0de6a6b7f93c',
    json:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fjson.png?alt=media&token=1522af18-f59b-47f7-b640-764db7c8181d',
    ProEDMS:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2FProEDMS.png?alt=media&token=ac86a1f8-ea1c-4eff-a465-1fee40f4d1f9',
    openCube:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2FopenCube.png?alt=media&token=18628411-8b9e-4c85-909a-be40fd690da0',
    chain:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fchain.png?alt=media&token=eba08323-68e2-49b4-88c2-3814c41a5561',
    adaptiveScale:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Flogo_black_adaptiveescale.png?alt=media&token=57776c2e-023f-4d51-afaa-d787b9c4a7bd',
    defaultUser:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fdefault-user.png?alt=media&token=f624306d-ca35-4c2e-8404-ccb7911378b7',
    invalaid:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fvalidation.png?alt=media&token=1ad99758-9917-48f7-846c-9ca794947e33',
    commercialIcon:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2FcommercialIcon.png?alt=media&token=84f83715-caa9-4b46-ab2c-a3e920c02dba',
    checkIcon:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2FcheckIcon.png?alt=media&token=35d2b3b2-d339-4104-bce7-1f8d99e3da5e',
    FailedIcon:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2FFailedIcon.png?alt=media&token=da20cd7c-4a79-4ba9-a123-c6f656fe0164',
    windowsCli:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fartifact-cli%2Fwindows%2Fartifact-ctl.exe?alt=media&token=5a95d8c0-2792-4e75-9422-eeb276653206',
    linuxCli:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fartifact-cli%2Flinux%2Fartifact-ctl?alt=media&token=dd34a459-9159-42f5-85dd-cfb39cb607d4',
    macosCli:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fartifact-cli%2Fmacos%2Fartifact-ctl?alt=media&token=657239f6-d7c4-4862-a101-8f4c430c3029',
  },
  webImages: {
    adaptivescaleLogo:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Fadaptivescale-logo.png?alt=media&token=f6310349-1622-45e8-bf89-9868e25fb63e',
    cdapCertificatedPlugins:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Fcdap-certified-plugins.png?alt=media&token=cbff5803-d97b-4dbb-bba8-b10d17b39d93',
    cdapLogoOrange:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Fcdap-logo-orange.png?alt=media&token=e21f39d4-f483-49fd-9723-4f040fab1e7e',
    cdapFullLogo:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Fcdap_full_logo.png?alt=media&token=c9551334-33c4-45b9-9de8-b5f17c21086b',
    dataHub:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Fdata-hub.svg?alt=media&token=b486acde-e771-4bf9-be4e-eeb0216e965a',
    elasticSearch:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Felastic-search.png?alt=media&token=d834ca41-c452-4e39-9132-67c73d645ce1',
    gcp:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Fgcp.png?alt=media&token=35b9e81e-da42-4c79-bcf0-ecb955aab533',
    logoBlackAdaptiveScale:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Flogo_black_adaptiveescale.png?alt=media&token=c8064a66-f8c6-4570-b288-faa47d5a3894',
    manageCdap:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Fmanage-cdap.png?alt=media&token=0dfc5ba8-49ac-4dcc-882e-618010fcaa4e',
    managePipelines:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Fmanage-pipelines.png?alt=media&token=9e468127-5121-4faf-a134-8dbb68f27543',
    spark:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Fspark.png?alt=media&token=c7e1e595-796f-4d5a-a0bb-93c17aa654f5',
    icon1:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Ficon%20(1).png?alt=media&token=91b7c534-1140-4481-8f15-c640f0f2c454',
    icon10:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Ficon%20(10).png?alt=media&token=fdd1bb13-3a7b-40cf-a26c-4e189012b2ef',
    icon2:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Ficon%20(2).png?alt=media&token=055fab63-0a46-4aa3-bb6a-322a27d89700',
    icon3:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Ficon%20(3).png?alt=media&token=01ac408c-d165-4732-90c0-15372c96ea3f',
    icon4:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Ficon%20(4).png?alt=media&token=5bacf3f7-e47a-4aa5-8597-9d8acec7dd5f',
    icon5:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Ficon%20(5).png?alt=media&token=0d3d90c5-a73c-4893-ac57-f67d58814517',
    icon6:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Ficon%20(6).png?alt=media&token=cf5eb800-6ca0-4518-9f98-7bedfdf3cc08',
    icon7:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Ficon%20(7).png?alt=media&token=574c9d1d-0947-4fcb-a124-0c14b0c22b85',
    icon8:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Ficon%20(8).png?alt=media&token=a17138e2-57ee-43b2-90f2-a90fb8a0c29c',
    icon9:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Ficon%20(9).png?alt=media&token=e3036280-506b-413b-9a79-b8337dc1957f',
    icon:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Ficon.png?alt=media&token=77f6eb75-96d3-4319-8063-5a761f17e8c6',
    layers:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Flayers.svg?alt=media&token=f5585471-a71b-409f-bec5-da6c4b0029a9',
    checks:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Fcheck.svg?alt=media&token=75723686-076f-4ad9-a8b9-8d3c16ebdad1',
    cloud:
      'https://firebasestorage.googleapis.com/v0/b/pluginmarketplace-436f1.appspot.com/o/site%2Fhub-web%2Fcloud.svg?alt=media&token=d47a7294-6252-4b02-af6f-d6ccc266dc4c',
  },
};
