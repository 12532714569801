import * as actionTypes from "../actions/types";

const initialUserState = {
  currentPlan: null,
  currentUser: null,
  isLoading: true,
  userLoggedIn: false,
  authToken: null,
  isUserSet: false,
  userProfile: null,
  error: null,
};

export default (state = initialUserState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER: {
      console.debug(actionTypes.SET_USER, action);
      return {
        ...state,
        currentUser: action.payload.currentUser,
        isLoading: false
      };
    }
    case actionTypes.CLEAR_USER: {
      console.debug(actionTypes.CLEAR_USER, action);
      return {
        ...initialUserState,
        isLoading: false
      };
    }
    case actionTypes.CLEAR_ERROR: {
      console.debug(actionTypes.CLEAR_ERROR, action);
      return {
        ...initialUserState,
        error: null
      };
    }
    case actionTypes.LOGGED_IN: {
      console.debug(actionTypes.LOGGED_IN, action);
      return {
        ...initialUserState,
        isLoading: false,
        userLoggedIn: action.payload.loggedIn,
        currentUser: action.payload.currentUser,
        isUserSet: action.payload.isUserSet,
      }
    }
    case actionTypes.SET_USER_STATE: {
      console.debug(actionTypes.SET_USER_STATE, action);
      const {currentUser, authToken, currentPlan} = action.payload;
      return {
        ...state,
        currentUser,
        isLoading: false,
        userLoggedIn: currentUser !== null,
        isUserSet: true,
        authToken,
        currentPlan,
      }
    }
    case actionTypes.SET_USER_PLAN: {
      console.debug(actionTypes.SET_USER_PLAN, action);
      const {currentPlan} = action.payload;
      return {
        ...state,
        currentPlan,
      }
    }
    case actionTypes.FETCH_USER_PROFILE_FAILED: {
      console.debug(actionTypes.FETCH_USER_PROFILE_FAILED, action);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    }
    case actionTypes.USER_SIGN_IN_FAIL:{
      console.debug(actionTypes.FETCH_USER_PROFILE_FAILED, action);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    }
    case actionTypes.FETCH_USER_PROFILE_SUCCESS: {
      console.debug(actionTypes.FETCH_USER_PROFILE_SUCCESS, action);
      return {
        ...state,
        isLoading: false,
        userProfile: action.payload.userProfile,
      }
    }
    default:
      return state;
  }
};
