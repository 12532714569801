import React, { Component } from 'react';
import { set as setIn } from 'lodash';
import { pipelines } from '../../../actions';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import LoadingPopup from '../../TransferPipelines/LoadingPopup';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

class CDAPCredentials extends Component {
  state = {
    url: '',
    instanceName: '',
    username: '',
    password: '',
    type: 'CDAP',
    email: '',
    serviceAccJson: '',
    instanceToggle: false,
  };

  navTabChange(event, value) {
    this.setState({ type: value });
  }

  editInstanceForm = () => {
    const { location } = this.props.history;
    return (
      <div>
        <div className="col-sm-12" style={{ marginTop: '15px' }}>
          <TextField
            variant="outlined"
            name="instanceName"
            label="Instance name"
            color="primary"
            fullWidth
            onChange={this.changeHandler}
            required
            defaultValue={
              this.props.userInstances[location.instanceIndex].instanceName
            }
          />
        </div>
        <div className="col-sm-12" style={{ marginTop: '15px' }}>
          {this.props.userInstances[location.instanceIndex].type === 'CDAP' ? (
            <Tooltip title="http://example:11015">
              <TextField
                variant="outlined"
                name="url"
                label="API Endpoint"
                color="primary"
                fullWidth
                onChange={this.changeHandler}
                required
                defaultValue={
                  this.props.userInstances[location.instanceIndex].url
                }
              />
            </Tooltip>
          ) : this.props.userInstances[location.instanceIndex].type ===
            'CDF' ? (
            <Tooltip title="[hostname]/api">
              <TextField
                variant="outlined"
                name="url"
                label="API Endpoint"
                color="primary"
                fullWidth
                onChange={this.changeHandler}
                required
                defaultValue={
                  this.props.userInstances[location.instanceIndex].url
                }
              />
            </Tooltip>
          ) : (
            <Tooltip title="Repository's HTTPS URL">
              <TextField
                variant="outlined"
                name="url"
                label="Url"
                color="primary"
                fullWidth
                onChange={this.changeHandler}
                required
                defaultValue={
                  this.props.userInstances[location.instanceIndex].url
                }
              />
            </Tooltip>
          )}
        </div>
        {this.props.userInstances[location.instanceIndex].type === 'CDAP' ? (
          <div>
            <div className="col-sm-12" style={{ marginTop: '15px' }}>
              <TextField
                variant="outlined"
                name="username"
                label="Username"
                color="primary"
                fullWidth
                onChange={this.changeHandler}
                // required
                defaultValue={
                  this.props.userInstances[location.instanceIndex].username
                }
              />
            </div>
            <div className="col-sm-12" style={{ marginTop: '15px' }}>
              <TextField
                variant="outlined"
                name="password"
                type="password"
                label="Password"
                color="primary"
                fullWidth
                onChange={this.changeHandler}
              />
            </div>
          </div>
        ) : this.props.userInstances[location.instanceIndex].type === 'CDF' ? (
          <div className="col-sm-12">
            <textarea
              onFocus={(e) => (e.target.placeholder = '')}
              onChange={(event) => {
                this.textAreaHandler(event);
              }}
              onBlur={(e) => (e.target.placeholder = 'Service Account JSON')}
              placeholder="Service Account JSON"
              required
              name="serviceAccJson"
              className="text-area-curl"
              style={{ fontSize: '14px', marginTop: '25px' }}
            />
            {/* <input
              required
              className="form-control new-input-sizes bg-transparent"
              name="token"
              onChange={this.changeHandler}
            /> */}
          </div>
        ) : (
          <div>
            <div className="col-sm-12" style={{ marginTop: '15px' }}>
              <Tooltip title="GIT Email">
                <TextField
                  variant="outlined"
                  name="email"
                  label="Email"
                  color="primary"
                  fullWidth
                  onChange={this.changeHandler}
                  defaultValue={
                    this.props.userInstances[location.instanceIndex].email
                  }
                />
              </Tooltip>
            </div>
            <div className="col-sm-12" style={{ marginTop: '15px' }}>
              <Tooltip title="GIT Username">
                <TextField
                  variant="outlined"
                  name="username"
                  label="Username"
                  color="primary"
                  fullWidth
                  onChange={this.changeHandler}
                  defaultValue={
                    this.props.userInstances[location.instanceIndex].username
                  }
                />
              </Tooltip>
            </div>
            <div className="col-sm-12" style={{ marginTop: '15px' }}>
              <Tooltip title="GIT Password">
                <TextField
                  variant="outlined"
                  name="password"
                  type="password"
                  label="Password"
                  color="primary"
                  fullWidth
                  onChange={this.changeHandler}
                />
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    );
  };

  addInstanceForm = () => {
    return (
      <div>
        <div className="col-sm-12" style={{ marginTop: '15px' }}>
          <TextField
            variant="outlined"
            name="instanceName"
            label="Instance name"
            color="primary"
            fullWidth
            onChange={this.changeHandler}
            required
          />
        </div>
        <div className="col-sm-12" style={{ marginTop: '15px' }}>
          {this.state.type === 'CDAP' ? (
            <Tooltip title="http://example:11015">
              <TextField
                variant="outlined"
                name="url"
                label="API Endpoint"
                color="primary"
                fullWidth
                onChange={this.changeHandler}
                required
              />
            </Tooltip>
          ) : this.state.type === 'CDF' ? (
            <Tooltip title="[hostname]/api">
              <TextField
                variant="outlined"
                name="url"
                label="API Endpoint"
                color="primary"
                fullWidth
                onChange={this.changeHandler}
                required
              />
            </Tooltip>
          ) : (
            <Tooltip title="Repository's HTTPS URL">
              <TextField
                variant="outlined"
                name="url"
                label="Url"
                color="primary"
                fullWidth
                onChange={this.changeHandler}
                required
              />
            </Tooltip>
          )}
        </div>
        {this.state.type === 'CDAP' ? (
          <div>
            <div className="col-sm-12" style={{ marginTop: '15px' }}>
              <TextField
                variant="outlined"
                name="username"
                label="Username"
                color="primary"
                fullWidth
                onChange={this.changeHandler}
              />
            </div>
            <div className="col-sm-12" style={{ marginTop: '15px' }}>
              <TextField
                variant="outlined"
                name="password"
                type="password"
                label="Password"
                color="primary"
                fullWidth
                onChange={this.changeHandler}
              />
            </div>
          </div>
        ) : this.state.type === 'CDF' ? (
          <div className="col-sm-12">
            <textarea
              onFocus={(e) => (e.target.placeholder = '')}
              onChange={(event) => {
                this.textAreaHandler(event);
              }}
              onBlur={(e) => (e.target.placeholder = 'Service Account JSON')}
              placeholder="Service Account JSON"
              required
              name="serviceAccJson"
              className="text-area-curl"
              style={{ fontSize: '14px', marginTop: '25px' }}
            />
            {/* <input
              required
              className="form-control new-input-sizes bg-transparent"
              name="token"
              onChange={this.changeHandler}
            /> */}
          </div>
        ) : (
          <div>
            <div className="col-sm-12" style={{ marginTop: '15px' }}>
              <Tooltip title="GIT Email">
                <TextField
                  variant="outlined"
                  name="email"
                  label="Email"
                  color="primary"
                  fullWidth
                  onChange={this.changeHandler}
                  required
                />
              </Tooltip>
            </div>
            <div className="col-sm-12" style={{ marginTop: '15px' }}>
              <Tooltip title="GIT Username">
                <TextField
                  variant="outlined"
                  name="username"
                  label="Username"
                  color="primary"
                  fullWidth
                  onChange={this.changeHandler}
                  // required
                />
              </Tooltip>
            </div>
            <div className="col-sm-12" style={{ marginTop: '15px' }}>
              <Tooltip title="GIT Password">
                <TextField
                  variant="outlined"
                  name="password"
                  type="password"
                  label="Password"
                  color="primary"
                  fullWidth
                  onChange={this.changeHandler}
                  // required
                />
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    );
  };

  textAreaHandler = (event) => {
    const value = event.target.value;
    try {
      JSON.parse(value);
      this.setState({ serviceAccJson: JSON.parse(event.target.value) });
      return;
    } catch (e) {
      this.setState({ serviceAccJson: '' });
      return;
    }
  };

  changeHandler = (event) => {
    const newState = setIn(this.state, event.target.name, event.target.value);
    this.setState({
      ...newState,
    });
  };

  firestoreSave = async () => {
    const { location } = this.props.history;
    if (!this.instanceSubmitForm.checkValidity()) {
      this.instanceSubmitForm.reportValidity();
      return;
    }
    if (this.state.type === 'CDF') {
      if (this.state.serviceAccJson === '') {
        alert('Please set your Service account JSON');
        return;
      }
    }
    const newInstance = {
      url: this.state.url,
      instanceName: this.state.instanceName,
      password: this.state.password,
      username: this.state.username,
      type: this.state.type,
      serviceAccJson: this.state.serviceAccJson,
      userId: this.props.currentUser.uid,
      email: this.state.email,
    };
    location.pathname === '/instance/add'
      ? await this.props.addInstance(
          newInstance,
          this.props.currentUser.uid,
          this.props.currentUser.ma
        )
      : await this.props.editInstance(
          newInstance,
          this.props.userInstances[location.instanceIndex].uniqueId,
          this.props.currentUser.uid,
          this.props.currentUser.ma
        );
    this.props.fetchInstances(
      this.props.currentUser.uid,
      this.props.currentUser.ma
    );
    this.props.history.push('/pipelines');
  };

  componentDidMount = async () => {
    const { location } = this.props.history;
    if (location.pathname === '/instance/edit') {
      const { userInstances } = this.props;
      userInstances[location.instanceIndex].type === 'CDF'
        ? this.setState({
            url: userInstances[location.instanceIndex].url,
            instanceName: userInstances[location.instanceIndex].instanceName,
            username: userInstances[location.instanceIndex].username,
            type: 'CDF',
          })
        : userInstances[location.instanceIndex].type === 'GIT'
        ? this.setState({
            url: userInstances[location.instanceIndex].url,
            instanceName: userInstances[location.instanceIndex].instanceName,
            username: userInstances[location.instanceIndex].username,
            type: 'GIT',
            email: userInstances[location.instanceIndex].email,
          })
        : this.setState({
            url: userInstances[location.instanceIndex].url,
            instanceName: userInstances[location.instanceIndex].instanceName,
            username: userInstances[location.instanceIndex].username,
          });
    }
  };

  editTabs = () => {
    const { userInstances } = this.props;
    const { location } = this.props.history;
    const instanceType = userInstances[location.instanceIndex].type;
    if (instanceType === 'GIT') {
      return (
        <Paper className="paper-nav-tabs">
          <Tabs
            value={this.state.type}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, value) => {
              this.navTabChange(event, value);
            }}
          >
            <Tab label="CDAP Instance" value="CDAP" disabled />
            <Tab label="Cloud Data Fusion" value="CDF" disabled />
            <Tab label="GIT Repository" value="GIT" />
          </Tabs>
        </Paper>
      );
    }
    if (instanceType === 'CDF') {
      return (
        <Paper className="paper-nav-tabs">
          <Tabs
            value={this.state.type}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, value) => {
              this.navTabChange(event, value);
            }}
          >
            <Tab label="CDAP Instance" value="CDAP" disabled />
            <Tab label="Cloud Data Fusion" value="CDF" />
            <Tab label="GIT Repository" value="GIT" disabled />
          </Tabs>
        </Paper>
      );
    }
    if (instanceType === 'CDAP') {
      return (
        <Paper className="paper-nav-tabs">
          <Tabs
            value={this.state.type}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, value) => {
              this.navTabChange(event, value);
            }}
          >
            <Tab label="CDAP Instance" value="CDAP" />
            <Tab label="Cloud Data Fusion" value="CDF" disabled />
            <Tab label="GIT Repository" value="GIT" disabled />
          </Tabs>
        </Paper>
      );
    }
  };

  addTabs = () => {
    return (
      <Paper className="paper-nav-tabs">
        <Tabs
          value={this.state.type}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, value) => {
            this.navTabChange(event, value);
          }}
        >
          <Tab label="CDAP Instance" value="CDAP" />
          <Tab label="Cloud Data Fusion" value="CDF" />
          <Tab label="GIT Repository" value="GIT" />
        </Tabs>
      </Paper>
    );
  };

  render() {
    const { location } = this.props.history;
    return (
      <div>
        <div className="col-lg-12">
          <div
            className="nav-wrapper col-lg-12"
            style={{ paddingBottom: '50px' }}
          >
            {location.pathname === '/instance/edit'
              ? this.editTabs()
              : this.addTabs()}
            {/* <Paper className="paper-nav-tabs">
              <Tabs
                value={this.state.type}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, value) => {
                  this.navTabChange(event, value);
                }}
              >
                <Tab label="CDAP Instance" value="CDAP" />
                <Tab label="Cloud Data Fusion" value="CDF" />
                <Tab label="GIT Repository" value="GIT" />
              </Tabs>
            </Paper> */}
          </div>

          <form
            name="instanceSubmitForm"
            ref={(form) => (this.instanceSubmitForm = form)}
          >
            <div
              className="col-lg-6 bg-color-shoppingList rounder-borders"
              style={{ margin: 'auto', padding: '15px' }}
            >
              {location.pathname === '/instance/edit'
                ? this.editInstanceForm()
                : this.addInstanceForm()}
            </div>
          </form>
          <div className="row" style={{ marginTop: '30px' }}>
            <div className="col-lg-6" style={{ textAlign: 'right' }}>
              <button
                onClick={this.firestoreSave}
                className="btn btn-primary state"
              >
                {this.state.type === 'GIT' ? 'Save and Clone' : 'Save'}
              </button>
            </div>
            <div className="col-lg-6">
              <button
                onClick={() => {
                  this.props.userInstances.length === 0
                    ? this.props.history.push('/')
                    : this.props.history.goBack();
                }}
                className="btn btn-light cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {this.props.isLoading && <LoadingPopup textToShow="Loading..." />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  attachResponse: state.pipelines.attachResponse,
  error: state.pipelines.error,
  userInstances: state.pipelines.userInstances,
  isLoading: state.pipelines.isLoading,
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
});

const mapActionsToProps = {
  addInstance: pipelines.addInstance,
  editInstance: pipelines.editInstance,
  fetchInstances: pipelines.fetchInstances,
};

export default connect(mapStateToProps, mapActionsToProps)(CDAPCredentials);
