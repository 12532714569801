import React, { Component } from 'react';
import { connect } from 'react-redux';
import { transferPipelines, pipelines } from '../../../actions';
import { groupBy, map } from 'lodash';

class CheckBeforeDepRes extends Component {
  transferPipelines = async () => {
    let data = {
      sourceInstance: this.props.userInstances[this.props.instanceIndex]
        .uniqueId,
      sourceNamespace: this.props.userInstances[this.props.instanceIndex]
        .currentNamespace,
      targetInstance: this.props.targetInstanceId,
      targetInstanceNamespace: this.props.targetNamespace,
      pipelinesList: this.props.userInstances[this.props.instanceIndex]
        .checkedPipelines,
      save_to_drafts: this.props.saveToDrafts,
      targetInstanceType: this.props.targetInstance_type,
    };
    await this.props.clearCheckDeploingResponse();
    const body = document.body;
    body.style.overflow = 'auto';
    await this.props.batchTransfer(
      this.props.currentUser.uid,
      this.props.currentUser.ma,
      data
    );
    await this.props.fetchInstancePipelines(
      data.targetInstance,
      data.targetInstanceNamespace,
      this.props.currentUser.ma
    );
  };

  artifats_caltulation_status = (property) => {
    const { deployingResponse } = this.props;
    const artifactStatus = groupBy(
      deployingResponse[property]['deployed_plugin'],
      'status.code'
    );

    const listStatusMessage = () => {
      const items = map(artifactStatus[404], 'status.message');
      const result = items.map((item, index) => {
        return <li key={index}>{item}</li>;
      });
      return result;
    };

    return (
      <div>
        {artifactStatus[200] !== undefined && (
          <li>
            The following missing artifacts were added:{' '}
            {map(artifactStatus[200], 'name').join(', ')}
          </li>
        )}
        {artifactStatus[403] !== undefined && (
          <li>{map(artifactStatus[403], 'status.message')}</li>
        )}
        {artifactStatus[404] !== undefined && listStatusMessage()}
      </div>
    );
  };

  addedArtifacts = (property) => {
    const artifacts = this.artifacts_calculations(property);
    const addedArtifacts = map(artifacts, function(el) {
      return el.deployed_successfully;
    }).join(', ');
    return addedArtifacts;
  };

  responseHandler = () => {
    const { deployingResponse } = this.props;
    const items = Object.keys(deployingResponse).map((property, index) => {
      return (
        <div key={index} className="batch-transfer-status row">
          <div style={{ width: '80%' }}>
            <p>{property}</p>
            <ul style={{ margin: '10px 0 0 0' }}>
              {deployingResponse[property]['deployed_plugin'].length === 0
                ? ''
                : this.artifats_caltulation_status(property)}
              <li>
                {deployingResponse[property]['pipeline_status']['message']}
              </li>
            </ul>
          </div>
        </div>
      );
    });
    return items;
  };

  closeHandler = async () => {
    await this.props.clearCheckDeploingResponse();
    // await this.props.clearDeployResponse();
    // await this.props.clearActionResponse();
    // await this.props.fetchInstancePipelines(instance_id, namespace, authToken);
    const body = document.body;
    body.style.overflow = 'auto';
  };

  render() {
    return (
      <div className="popup" style={{ overflow: 'auto' }}>
        <div className="inerPopup col-lg-6" style={{ margin: '5.75rem auto' }}>
          <div className="batch-transfer-status">
            <p style={{ fontWeight: 'bold', fontSize: '20px' }}>
              Pre Deployment Check's
            </p>
          </div>
          {this.responseHandler()}
          <div
            className="row"
            style={{ justifyContent: 'center', marginTop: '20px' }}
          >
            <button
              className="btn btn-primary"
              onClick={() => {
                this.transferPipelines();
              }}
            >
              Run
            </button>
            <button
              className="btn btn-light"
              onClick={() => {
                this.closeHandler();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  userInstances: state.pipelines.userInstances,
  instanceIndex: state.transferPipelines.instanceIndex,
  targetInstaceData: state.transferPipelines.targetInstaceData,
  saveToDrafts: state.transferPipelines.saveToDrafts,
  targetInstanceId: state.transferPipelines.targetInstanceId,
  targetNamespace: state.transferPipelines.targetNamespace,
  targetInstance_type: state.transferPipelines.targetInstance_type,
});

const mapActionsToProps = {
  clearDeployResponse: transferPipelines.clearDeployResponse,
  clearActionResponse: pipelines.clearActionResponse,
  fetchInstancePipelines: pipelines.fetchInstancePipelines,
  clearCheckDeploingResponse: transferPipelines.clearCheckDeploingResponse,
  batchTransfer: transferPipelines.batchTransfer,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(CheckBeforeDepRes);
