import React, { Component } from 'react';

class LoadingSpinner extends Component {
  render() {
    return (
      <div className="col-lg-12">
        <div className="row">
          <div className="processing-container">
            <div
              style={{
                float: 'left',
                position: 'relative',
                width: '100%',
              }}
            >
              <div
                style={{
                  margin: '30px auto 0 auto',
                  width: '150px',
                  height: '40px',
                }}
              >
                <div style={{ float: 'left', marginRight: '10px' }}>
                  {this.props.textToShow}
                </div>
                <div className="spinner-border" style={{ float: 'left' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoadingSpinner;
