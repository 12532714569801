import {
  INDIVIDUAL_PIPELINE_BEGIN,
  INDIVIDUAL_PIPELINE_SUCCESS,
  INDIVIDUAL_PIPELINE_FAIL,
  COMPARE_INSTANCE_BEGIN,
  COMPARE_INSTANCE_SUCCESS,
  COMPARE_INSTANCE_FAIL,
  DEPLOY_PIPELINE_BEGIN,
  DEPLOY_PIPELINE_SUCCESS,
  DEPLOY_PIPELINE_FAIL,
  DEPLOY_PLUGIN_BEGIN,
  DEPLOY_PLUGIN_SUCCESS,
  DEPLOY_PLUGIN_FAIL,
  CLEAR_DEPLOY_RESPONSE,
  GET_TARGET_NAMESPACES_BEGIN,
  GET_TARGET_NAMESPACES_SUCCESS,
  GET_TARGET_NAMESPACES_FAIL,
  CLEAR_COMPARE_DATA,
  CLEAR_TARGET_NAMESPACES,
  CLEAR_INDIVIDUAL_PIPELINE,
  BATCH_TRANSFER_BEGIN,
  BATCH_TRANSFER_FAIL,
  BATCH_TRANSFER_SUCCESS,
  SAVE_DRAFT_BEGIN,
  SAVE_DRAFT_SUCCESS,
  SAVE_DRAFT_FAIL,
  CHECK_BATCH_TRANSFER_BEGIN,
  CHECK_BATCH_TRANSFER_FAIL,
  CHECK_BATCH_TRANSFER_SUCCESS,
  CLEAR_CHECK_DEPLOING_RESPONSE,
  SET_TARGET_INSTANCE_ID_AND_NS,
  SET_SAVE_TO_DRAFTS,
  GIT_TRANSFER_BEGIN,
  GIT_TRANSFER_FAIL,
  GIT_TRANSFER_SUCCESS,
} from './types';
import Properties from '../config/properties';

const individualPipelineBegin = () => ({
  type: INDIVIDUAL_PIPELINE_BEGIN,
});

const individualPipelineSuccess = (data) => ({
  type: INDIVIDUAL_PIPELINE_SUCCESS,
  payload: { data },
});

const individualPipelineFail = (error) => ({
  type: INDIVIDUAL_PIPELINE_FAIL,
  payload: { error },
});

export const individualPipeline = (
  instance_id,
  namespace,
  pipeline,
  authToken,
) => {
  return async (dispatch) => {
    try {
      dispatch(individualPipelineBegin());
      const res = await fetch(
        `${Properties.API_HOST}i/${instance_id}/ns/${namespace}/${pipeline}`,
        {
          method: 'POST',
          // body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      const response = await res.json();
      // response['instance'] = instance.instance;
      dispatch(individualPipelineSuccess(response));
      return response;
    } catch (error) {
      return dispatch(individualPipelineFail(error));
    }
  };
};

const compareInstanceBegin = () => ({
  type: COMPARE_INSTANCE_BEGIN,
});

const compareInstanceSuccess = (data) => ({
  type: COMPARE_INSTANCE_SUCCESS,
  payload: { data },
});

const compareInstanceFail = (error) => ({
  type: COMPARE_INSTANCE_FAIL,
  payload: { error },
});

export const compareInstance = (userId, data, authToken) => {
  return async (dispatch) => {
    try {
      dispatch(compareInstanceBegin());
      const res = await fetch(
        `${Properties.API_HOST}u/${userId}/instance/compare`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      const response = await res.json();
      dispatch(compareInstanceSuccess(response));
      return response;
    } catch (error) {
      return dispatch(compareInstanceFail(error));
    }
  };
};

const deployPipelineBegin = () => ({
  type: DEPLOY_PIPELINE_BEGIN,
});

const deployPipelineSuccess = (data) => ({
  type: DEPLOY_PIPELINE_SUCCESS,
  payload: { data },
});

const deployPipelineFail = (error) => ({
  type: DEPLOY_PIPELINE_FAIL,
  payload: { error },
});

export const deployPipeline = (target_instance_id, namespace, data, authToken) => {
  return async (dispatch) => {
    try {
      dispatch(deployPipelineBegin());
      const res = await fetch(
        `${Properties.API_HOST}i/${target_instance_id}/ns/${namespace}/pipeline/${data.config.name}`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      const response = await res.json();
      dispatch(deployPipelineSuccess(response));
      return response;
    } catch (error) {
      return dispatch(deployPipelineFail(error));
    }
  };
};

const deployPluginBegin = () => ({
  type: DEPLOY_PLUGIN_BEGIN,
});

const deployPluginSuccess = (data) => ({
  type: DEPLOY_PLUGIN_SUCCESS,
  payload: { data },
});

const deployPluginFail = (error) => ({
  type: DEPLOY_PLUGIN_FAIL,
  payload: { error },
});

export const deployPlugin = (userId, authToken, data) => {
  return async (dispatch) => {
    try {
      dispatch(deployPluginBegin());
      const res = await fetch(
        `${Properties.API_HOST}u/${userId}/ns/${data.namespace}/artifact/${data.artifact.name}`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      const response = await res.json();
      dispatch(deployPluginSuccess(response));
      return response;
    } catch (error) {
      return dispatch(deployPluginFail(error));
    }
  };
};

export const clearDeployResponse = () => ({
  type: CLEAR_DEPLOY_RESPONSE,
  payload: null,
});

const getTargetInstanceNamespacesBegin = () => ({
  type: GET_TARGET_NAMESPACES_BEGIN,
});

const getTargetInstanceNamespacesSuccess = (data) => ({
  type: GET_TARGET_NAMESPACES_SUCCESS,
  payload: { data },
});

const getTargetInstanceNamespacesFail = (error) => ({
  type: GET_TARGET_NAMESPACES_FAIL,
  payload: { error },
});

export const getTargetInstanceNamespaces = (instance_id, authToken) => {
  return async (dispatch) => {
    try {
      dispatch(getTargetInstanceNamespacesBegin());
      const res = await fetch(`${Properties.API_HOST}i/${instance_id}/namespaces`, {
        method: 'POST',
        // body: JSON.stringify(instance),
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      });
      const response = await res.json();
      if (res.status === 200) {
        dispatch(getTargetInstanceNamespacesSuccess(response));
      } 
      else {
        // dispatch(getNamespacesSuccess(null));
        dispatch(getTargetInstanceNamespacesFail(response));
      }
      return response;
    } catch (error) {
      return dispatch(getTargetInstanceNamespacesFail(error));
    }
  };
};  


const batchTransferBegin = () => ({
  type: BATCH_TRANSFER_BEGIN,
});

const batchTransferSuccess = (data) => ({
  type: BATCH_TRANSFER_SUCCESS,
  payload: { data },
});

const batchTransferFail = (error) => ({
  type: BATCH_TRANSFER_FAIL,
  payload: { error },
});

export const batchTransfer = (user_id, authToken, data) => {
  return async (dispatch) => {
    try {
      dispatch(batchTransferBegin());
      data['targetNamespace'] = data.targetInstanceNamespace
      const res = await fetch(`${Properties.API_HOST}u/${user_id}/batch-transfer`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      });
      const response = await res.json();
      if (res.status === 200) {
        dispatch(batchTransferSuccess(response));
      } else {
        // dispatch(getNamespacesSuccess(null));
        // dispatch(getTargetInstanceNamespacesFail(response));
      }
      return response;
    } catch (error) {
      return dispatch(batchTransferFail(error));
    }
  };
};


const checkBatchTransferBegin = () => ({
  type: CHECK_BATCH_TRANSFER_BEGIN,
});

const checkBatchTransferSuccess = (data, instanceIndex) => ({
  type: CHECK_BATCH_TRANSFER_SUCCESS,
  payload: { data, instanceIndex },
});

const checkBatchTransferFail = (error) => ({
  type: CHECK_BATCH_TRANSFER_FAIL,
  payload: { error },
});

export const checkBatchTransfer = (user_id, authToken, data, instanceIndex) => {
  return async (dispatch) => {
    try {
      dispatch(checkBatchTransferBegin());
      data['targetNamespace'] = data.targetInstanceNamespace
      const res = await fetch(`${Properties.API_HOST}u/${user_id}/check-batch-transfer`, {
      method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      });
      const response = await res.json();
      if (res.status === 200) {
        dispatch(checkBatchTransferSuccess(response, instanceIndex));
      } else {
        // dispatch(getNamespacesSuccess(null));
        // dispatch(getTargetInstanceNamespacesFail(response));
      }
      return response;
    } catch (error) {
      return dispatch(checkBatchTransferFail(error));
    }
  };
};

export const clearCheckDeploingResponse = () => ({
  type: CLEAR_CHECK_DEPLOING_RESPONSE,
});

export const setTargetInstanceIdandNs = (id, ns, type) => ({
  type: SET_TARGET_INSTANCE_ID_AND_NS,
  payload: {id, ns, type},
});

export const setSaveToDrafts = () => ({
  type: SET_SAVE_TO_DRAFTS,
  // payload: saveToDrafts,
});

const saveDraftBegin = () => ({
  type: SAVE_DRAFT_BEGIN,
});

const saveDraftSuccess = (data) => ({
  type: SAVE_DRAFT_SUCCESS,
  payload: { data },
});

const saveDraftFail = (error) => ({
  type: SAVE_DRAFT_FAIL,
  payload: { error },
});

export const saveDraft = (target_instance_id, namespace, data, authToken) => {
  return async (dispatch) => {
    try {
      dispatch(saveDraftBegin());
      const res = await fetch(
        `${Properties.API_HOST}i/${target_instance_id}/ns/${namespace}/pipeline/save-draft`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      const response = await res.json();
      dispatch(saveDraftSuccess(response));
      return response;
    } catch (error) {
      return dispatch(saveDraftFail(error));
    }
  };
};

const gitTransferBegin = () => ({
  type: GIT_TRANSFER_BEGIN,
});

const gitTransferSuccess = (response) => ({
  type: GIT_TRANSFER_SUCCESS,
  payload: { response },
});

const gitTransferFail = (error) => ({
  type: GIT_TRANSFER_FAIL,
  payload: { error },
});

export const gitTransferPipelines = (
  userId,
  instanceId,
  authToken,
  data
) => {
  return async (dispatch) => {
    try {
      dispatch(gitTransferBegin());
      const res = await fetch(
        `${Properties.API_HOST}u/${userId}/i/${instanceId}/git-transfer`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      if (res.status === 200) {
        const response = await res.json();
        dispatch(gitTransferSuccess(response));
        return response;
      } else {
        const error = await res.json();
        return dispatch(gitTransferFail(error.message));
      }
    } catch (error) {
      return dispatch(gitTransferFail(error));
    }
  };
};

export const clearTargetNamespaces = () => ({
  type: CLEAR_TARGET_NAMESPACES,
  payload: null,
})


export const clearCompareData = () => ({
  type: CLEAR_COMPARE_DATA,
  payload: null,
});

export const clearIndividualPipeline = () => ({
  type: CLEAR_INDIVIDUAL_PIPELINE,
  payload: null,
});
