import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shoppingCart } from '../../../actions';
import assets from '../../../assets'

class PricingTable extends Component {
  professionalPlan = () => {
    let professionalPlan = '';
    if (this.props.currentPlan === 'professional') {
      professionalPlan = <div className="current-plan">Current Plan</div>;
    }
    if (this.props.currentPlan === 'enterprise-basic' || this.props.currentPlan === 'enterprise-plus') {
      professionalPlan = (
        <button
        className="btn btn-outline-primary"
          onClick={() => {
            this.props.setPrice(199);
            this.props.currentUser !== null
              ? this.props.history.push('/payment/professional')
              : this.props.history.push('/login');
          }}
        >
          Downgrade
        </button>
      );
    }
    return professionalPlan;
  };


  enterpriseBasic = () => {
    let enterpriseBasic = '';
    if (this.props.currentPlan === 'enterprise-basic') {
      enterpriseBasic = <div className="current-plan">Current Plan</div>;

    }
    if (this.props.currentPlan === 'enterprise-plus') {
      enterpriseBasic = (
        <button
        className="btn btn-outline-primary"
          onClick={() => {
            this.props.setPrice(499);
            this.props.currentUser !== null
              ? this.props.history.push('/payment/enterprise-basic')
              : this.props.history.push('/login');
          }}
        >
          Downgrade
        </button>
      );
    }
    return enterpriseBasic;
  };

  basicPlan = () => {
    let basicPlan = '';
    if (this.props.currentPlan === 'basic') {
      basicPlan = <div className="current-plan">Current Plan</div>;

    } else if (
      this.props.currentPlan === 'enterprise-basic' ||
      this.props.currentPlan === 'enterprise-plus' ||
      this.props.currentPlan === 'professional'
    ) {
      basicPlan = (
        <button
        className="btn btn-outline-primary"
        onClick={() => {
            this.props.setPrice(49);
            this.props.currentUser !== null
              ? this.props.history.push('/payment/basic')
              : this.props.history.push('/login');
          }}
        >
          Downgrade
        </button>
      );
    }
    return basicPlan;
  };

  cancelSubscription = () => {
    this.props.cancelSubscription(
      this.props.currentUser.ma,
      this.props.currentUser.uid
    );
    this.props.history.push('/receipt/cancel-subscription');
  };

  render() {
    return (
      <table className="table text-center mt-5 d-none d-lg-table pricing-details">
        <tbody>
          <tr>
            <th scope="row" className="border-0">
              <img
                alt=""
                style={{ width: '160px', marginLeft: '30px' }}
                src={assets.webImages.adaptivescaleLogo}
              />
            </th>
            <td className="text-center border-0">
              <h5 className="font-weight-light">
                FREE <br />{' '}
              </h5>
              <p className="lead">$0 / month</p>
              <p style={{ marginTop: '8px' }}>
                {this.props.currentPlan === 'free' ? (
                  <div className="current-plan">Current Plan</div>
                ) : (
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      //   this.props.setPrice(150);
                      this.props.currentUser !== null
                        ? this.cancelSubscription()
                        : this.props.history.push('/login');
                    }}
                  >
                    Close
                    <br />
                    Subscription
                  </button>
                )}
              </p>
            </td>
            <td className="text-center border-0">
              <h4 className="font-weight-light">
                Basic &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </h4>
              <p className="lead">$49 / month</p>
              <p style={{ marginTop: '8px' }}>
                {this.props.currentPlan === 'free' ? (
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      this.props.setPrice(49);
                      this.props.currentUser !== null
                        ? this.props.history.push('/payment/basic')
                        : this.props.history.push('/login');
                    }}
                  >
                    Upgrade
                  </button>
                ) : (
                  <div>{this.basicPlan()}</div>
                )}
              </p>
            </td>
            <td className="text-center border-0">
              <h4 className="font-weight-light">Professional &nbsp;</h4>
              <p className="lead">$199 / month</p>
              <p style={{ marginTop: '8px' }}>
                {this.props.currentPlan === 'free' ||
                this.props.currentPlan === 'basic' ? (
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      this.props.setPrice(199);
                      this.props.currentUser !== null
                        ? this.props.history.push('/payment/professional')
                        : this.props.history.push('/login');
                    }}
                  >
                    Upgrade
                  </button>
                ) : (
                  <div>{this.professionalPlan()}</div>
                )}
              </p>
            </td>

            <td className="text-center border-0">
              <h4 className="font-weight-light">Enterprise Basic</h4>
              <p className="lead">$499 / month</p>
              <p style={{ marginTop: '8px' }}>
                {this.props.currentPlan === 'free' ||
                this.props.currentPlan === 'basic' ||
                this.props.currentPlan === 'professional' ? (
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      this.props.setPrice(499);
                      this.props.currentUser !== null
                        ? this.props.history.push('/payment/enterprise-basic')
                        : this.props.history.push('/login');
                    }}
                  >
                    Upgrade
                  </button>
                ) : (
                  <div>{this.enterpriseBasic()}</div>
                )}
              </p>
            </td>

            <td className="text-center border-0">
              <h4 className="font-weight-light">Enterprise Plus</h4>
              <p className="lead">$2,750 / month</p>
              <p style={{ marginTop: '8px' }}>
                {this.props.currentPlan === 'enterprise-plus' ? (
                  <div className="current-plan">Current Plan</div>
                ) : (
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      this.props.setPrice(2750);
                      this.props.currentUser !== null
                        ? this.props.history.push('/payment/enterprise-plus')
                        : this.props.history.push('/login');
                    }}
                  >
                    Upgrade
                  </button>
                )}
              </p>
            </td>
            {/* <td className="text-center border-0">
<h5 className="font-weight-light">Enterprise plus</h5>
<p className="lead">$100 / month</p>
<p><a href="https://www.froala.com" className="btn btn-outline-primary">Buy Now</a></p>
</td> */}
          </tr>
          <tr>
            <th scope="row">Unlimited OSS plugins</th>
            <td className="text-blue">✓</td>
            <td className="text-blue">✓</td>
            <td className="text-blue">✓</td>
            <td className="text-blue">✓</td>
            <td className="text-blue">✓</td>
            {/* <td>✓</td> */}
          </tr>
          <tr>
            <th scope="row">Store artifacts centrally and securely </th>
            <td>X</td>
            <td className="text-blue">✓</td>
            <td className="text-blue">✓</td>
            <td className="text-blue">✓</td>
            <td className="text-blue">✓</td>
            {/* <td>✓</td> */}
          </tr>
          <tr>
            <th scope="row">Limited number of private plugins</th>
            <td>X</td>
            <td className="text-blue">10</td>
            <td className="text-blue">50</td>
            <td className="text-blue">Unlimited</td>
            <td className="text-blue">Unlimited</td>
            {/* <td>✓</td> */}
          </tr>
          <tr>
            <th scope="row">
              Plugins can be shared publicly and may contain closed source
              artifacts and commercial license
            </th>
            <td>X</td>
            <td className="text-blue">✓</td>
            <td className="text-blue">✓</td>
            <td className="text-blue">✓</td>
            <td className="text-blue">✓</td>
            {/* <td>✓</td> */}
          </tr>
          <tr>
            <th scope="row">
              Limited number of CDAP sandbox instance's that can use our product{' '}
            </th>
            <td>X</td>
            <td className="text-blue">1 (single IP / dynamic)</td>
            <td className="text-blue">3 IP's</td>
            <td className="text-blue">Unlimited</td>
            <td className="text-blue">Unlimited</td>
            {/* <td>✓</td> */}
          </tr>
          {/* <tr>
            <th scope="row">Sell Plugins on MarketPlace </th>
            <td>X</td>
            <td>X</td>
            <td>X</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr> */}
          {/* <tr>
            <th scope="row">
              Companies that chose to sell plugins will have a page to include
              company info and support details for their plugin{' '}
            </th>
            <td>X</td>
            <td>X</td>
            <td>X</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr> */}
          <tr>
            <th scope="row">
              Pipelines Feature (Managing pipelines across multiple instances.
              Transfer from one instance to anothe with batch operations or
              singleton. Including plugin deployment that are relevat to their
              pipelines. Also start, delete, export pipelines in batch
              operations are avaliable.)
            </th>
            <td>X</td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
            <td className="text-blue">✓</td>
            {/* <td>✓</td> */}
          </tr>
          <tr>
            <th scope="row">Must include a GitHub repo </th>
            <td className="text-blue">✓</td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
            {/* <td>✓</td> */}
          </tr>
        </tbody>
      </table>

      // <div>
      //   <div className="col-xl-12 col-lg-12" style={{ padding: '17px' }}>
      //     <table>
      //       <tbody>
      //         <tr>
      //           <td className="w300" style={{width: "600px"}}>Unlimited OSS plugins</td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //         </tr>
      //         <tr>
      //           <td className="w300">Store artifacts centrally and securely</td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //         </tr>
      //         <tr>
      //           <td className="w300">Limited number of private plugins</td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td className="text-blue">10</td>
      //           <td className="text-blue">20</td>
      //           <td className="text-blue">Unlimited</td>
      //         </tr>
      //         <tr>
      //           <td className="w300">
      //             Plugins can be shared publicly and may contain closed source
      //             artifacts and commercial license
      //           </td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //         </tr>
      //         <tr>
      //           <td className="w300">
      //             Limited number of CDAP sandbox instance's that can use our
      //             product
      //           </td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td className="text-blue">1 (single IP / dynamic)</td>
      //           <td className="text-blue">3 IP's</td>
      //           <td className="text-blue">Unlimited</td>
      //         </tr>
      //         <tr>
      //           <td className="w300">Sell Plugins on MarketPlace</td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //         </tr>
      //         <tr>
      //           <td className="w300">
      //             Companies that chose to sell plugins will have a page to
      //             include company info and support details for their plugin
      //           </td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //         </tr>
      //         <tr>
      //           <td className="w300">
      //             Enterprises, and professional ISVs or developers that can add
      //             CDAP or CDF instances and download or transfer their pipelines
      //             from one instance to another with batch operation or
      //             singleton. Including plugin deployment on respective instance
      //             that are relevat to their pipelines.
      //           </td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //         </tr>
      //         <tr>
      //           <td className="w300">Must include a GitHub repo</td>
      //           <td>
      //             <i className="fa fa-check text-blue" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //           <td>
      //             <i className="fa fa-times white" aria-hidden="true" />
      //           </td>
      //         </tr>
      //         <tr>
      //           <td
      //             className="w300"
      //             style={{ backgroundColor: 'white', border: 'white' }}
      //           ></td>
      //           <td style={{ backgroundColor: 'white', border: 'white' }}>
      //             {this.props.currentPlan === 'professional' ||
      //             this.props.currentPlan === 'enterprise' ||
      //             this.props.currentPlan === 'basic' ? (
      //               <button
      //                 className="btn btn-primary"
      //                 onClick={() => {
      //                   //   this.props.setPrice(150);
      //                   this.props.currentUser !== null
      //                     ? this.cancelSubscription()
      //                     : this.props.history.push('/login');
      //                 }}
      //               >
      //                 Close
      //                 <br />
      //                 Subscription
      //               </button>
      //             ) : (
      //               <div>Current Plan</div>
      //             )}
      //           </td>
      //           <td style={{ backgroundColor: 'white', border: 'white' }}>
      //             {this.props.currentPlan === 'free' ? (
      //               <button
      //                 className="btn btn-primary"
      //                 onClick={() => {
      //                   this.props.setPrice(100);
      //                   this.props.currentUser !== null
      //                     ? this.props.history.push('/payment/basic')
      //                     : this.props.history.push('/login');
      //                 }}
      //               >
      //                 Upgrade
      //               </button>
      //             ) : (
      //               <div>{this.basicPlan()}</div>
      //             )}
      //           </td>
      //           <td style={{ backgroundColor: 'white', border: 'white' }}>
      //             {this.props.currentPlan === 'free' ||
      //             this.props.currentPlan === 'basic' ? (
      //               <button
      //                 className="btn btn-primary"
      //                 onClick={() => {
      //                   this.props.setPrice(150);
      //                   this.props.currentUser !== null
      //                     ? this.props.history.push('/payment/professional')
      //                     : this.props.history.push('/login');
      //                 }}
      //               >
      //                 Upgrade
      //               </button>
      //             ) : (
      //               <div>{this.professionalPlan()}</div>
      //             )}
      //           </td>
      //           <td style={{ backgroundColor: 'white', border: 'white' }}>
      //             {' '}
      //             {this.props.currentPlan === 'enterprise' ? (
      //               <div>Current Plan</div>
      //             ) : (
      //               <button
      //                 className="btn btn-primary"
      //                 onClick={() => {
      //                   this.props.setPrice(495);
      //                   this.props.currentUser !== null
      //                     ? this.props.history.push('/payment/enterprise')
      //                     : this.props.history.push('/login');
      //                 }}
      //               >
      //                 Upgrade
      //               </button>
      //             )}
      //           </td>
      //         </tr>
      //       </tbody>
      //     </table>
      //   </div>
      // </div>
    );
  }
}

const mapActionsToProps = {
  setPrice: shoppingCart.setPrice,
  cancelSubscription: shoppingCart.cancelSubscription,
};

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  currentPlan: state.user.currentPlan,
  // ...ownProps,
});
export default connect(mapStateToProps, mapActionsToProps)(PricingTable);
