import React, { Component } from 'react';
import assets from '../../assets';
import { myPlugins } from '../../actions';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';

class ArtifactListViewItem extends Component {
  state = {
    popup: false,
    openMenu: false,
    anchorEl: null,
  };

  handleClick = () => {
    this.setState({
      openMenu: !this.state.openMenu,
    });
  };

  render() {
    const { artifact } = this.props;
    const imgSrc =
      artifact.name === 'core-plugins' || artifact.name === 'transform-plugins'
        ? assets.images.ProEDMS
        : artifact.imageUrl;
    return (
      <div className="shadow col-lg-12 border mg-bottom-10">
                {this.props.activeTab === 'myPlugins' && (
          <div
            style={{
              position: 'absolute',
              top: '3px',
              left: '0px',
              width: '0px',
              zIndex:"1"
            }}
          >
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => {
                this.setState({
                  anchorEl: event.currentTarget,
                });
                this.handleClick();
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={this.state.anchorEl}
              id="long-menu"
              open={this.state.openMenu}
              onClose={() => {
                this.handleClick();
              }}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: '19ch',
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  this.props.EditPluginPopupHandler(artifact);
                  this.handleClick();
                }}
              >
                <i className="fa fa-edit btn-edit pd-right-10"></i>
                <Typography variant="inherit">Edit</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.props.DeletePluginPopupHandler(artifact);
                  this.handleClick();
                }}
              >
                <i className="fa fa-trash btn-edit pd-right-10"></i>
                <Typography variant="inherit">Delete</Typography>
              </MenuItem>
            </Menu>
          </div>
        )}
        {artifact.visibility === 'pending' &&
          this.props.activeTab === 'myPlugins' && (
            <div className="banners-shifted-listView">
              <div className="pending-banner">
                <p
                  style={{
                    fontSize: '12px',
                    marginLeft: '10px',
                  }}
                >
                  PENDING
                </p>
              </div>
            </div>
          )}

        {artifact.commercial === true && (
          <img
            className="img-commercial-listView"
            src={assets.images.commercialIcon}
            alt="Not found"
          />
        )}
        <div className="col-lg-12 mgTopBottom">
          <button
            className="full-link-list btn-no-bg"
            onClick={() => this.props.goToDetails(artifact, null)}
          />
          <div className="row">
            <div className="col-lg-2 text-center" style={{ height: '130px' }}>
              <img
                className="card-img-top-list"
                src={imgSrc}
                alt="Not found"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = assets.images.ProEDMS;
                }}
                style={{ width: '90px', height: '90px' }}
              />
            </div>
            <div className="col-lg-10">
              <div className="row">
                <h4
                  className="col-lg-4"
                  style={{ maxWidth: '35%', flexBasis: '45%' }}
                >
                  {artifact.converted_name}
                </h4>
                <div className="version-listView smallText-13">
                  {artifact.widgets && artifact.widgets.length > 1 && (
                    <img
                      className="pull-right"
                      src={assets.images.cube}
                      alt=""
                    />
                  )}
                  <label
                    className="pull-right mgLeft10"
                    style={{ marginTop: '6px' }}
                  >
                    <i className="fa fa-code-fork fa-lg" /> {artifact.version}
                  </label>
                </div>
              </div>
              <p className="block-with-text list-view-description">
                {artifact.description}
              </p>
              <div
                className="row"
                style={{
                  position: 'absolute',
                  bottom: '-13px',
                  marginLeft: '3px',
                }}
              >
                <div className="smallText-13">
                  <label className="mgLeft-10">
                    <i
                      className="fa fa-info-circle"
                      style={{ color: 'gray' }}
                    />{' '}
                    {artifact.widgets && artifact.widgets.length > 1
                      ? 'Bundle'
                      : artifact.widgets && artifact.widgets[0]['pluginType']}
                  </label>
                  <label className="mgLeft10">
                    <i
                      className="fa fa-balance-scale"
                      style={{ color: 'gray' }}
                    />
                    {artifact.License ? artifact.License : artifact.license}
                  </label>
                  {artifact.userId ? (
                    <label className="mgLeft10">
                      <i className="fa fa-link" /> Uploaded{' '}
                    </label>
                  ) : (
                    <label className="mgLeft10">
                      <i className="fa fa-github github" />
                      <a
                        href={artifact.widgets && artifact.widgets[0].html_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {artifact.widgets && artifact.widgets[0].orgName}
                      </a>
                    </label>
                  )}
                  {artifact.tags && (
                    <label className="mgLeft10">
                      <i className="fa fa-tags" />
                      {artifact.tags.join(', ')}
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  activeTab: state.activeState.activeTab,
  ...ownProps,
});

const mapActionsToProps = {
  EditPluginPopupHandler: myPlugins.EditPluginPopupHandler,
  DeletePluginPopupHandler: myPlugins.DeletePluginPopupHandler,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ArtifactListViewItem);
