import * as actionTypes from "./types";
import firebase from '../firebase';
import { Properties } from '../config';
import {
    oss,
  shoppingCart,
  pipelines
} from './index';

export const setUser = user => {
    return {
        type: actionTypes.SET_USER,
        payload: {
            currentUser: user,
        }
    };
};

export const clearUser = () => {
    return {
        type: actionTypes.CLEAR_USER
    };
};

export const loggedIn = (logedIn, user) => {
    return {
        type: actionTypes.LOGGED_IN,
        payload: {
            loggedIn: logedIn,
            currentUser: user,
            isUserChecked: true,
        }
    };
};

export const setUserState = (currentUser, authToken, currentPlan) =>{
    return {
        type: actionTypes.SET_USER_STATE,
        payload: {
            currentUser,
            authToken,
            currentPlan,
        },
    }
};

export const setUserPlan = (currentPlan) => {
    return {
        type: actionTypes.SET_USER_PLAN,
        payload: {
            currentPlan,
        }
    }
};

export const singInBegin = () =>{
    return {
        type: actionTypes.USER_SIGN_IN_BEGIN,
    }
};

export const signInSuccess = (currentUser) => {
    return {
        type: actionTypes.USER_SIGN_IN_SUCCESS,
        payload: {
            currentUser,
        },
    };
};

export const signInFail = (error) => {
    return {
        type: actionTypes.USER_SIGN_IN_FAIL,
        payload: {
            error,
        },
    };
};

export const setErrorToNull = () => {
    return{
        type: actionTypes.CLEAR_ERROR,
        payload: null
    }
}

export const gitHubSignIn = () => {
    return async dispatch => {
        dispatch(singInBegin());
        try {
            const user = await firebase.auth().signInWithPopup(new firebase.auth.GithubAuthProvider());
            dispatch(signInSuccess(user));
            return user;
        }catch (error) {
            return dispatch(signInFail(error));
        }
    }
};

export const googleSignIn = () =>{
    return async dispatch => {
        dispatch(singInBegin());
        try {
            const user = await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
            dispatch(signInSuccess(user));
            return user;
        }catch (error) {
            return dispatch(signInFail(error));
        }
    }
};

export const regularSignIn = (email, pass) => {
    return async dispatch => {
        dispatch(singInBegin());
        try {
            const user = await firebase.auth().signInWithEmailAndPassword(email, pass);
            dispatch(signInSuccess(user));
            return user;
        }catch (error) {
            return dispatch(signInFail(error));
        }
    }
};

export const regularSignUp = (email, pass) =>{
    return async dispatch => {
        dispatch(singInBegin());
        try {
            const user = await firebase.auth().createUserWithEmailAndPassword(email, pass);
            dispatch(signInSuccess(user));
            return user;
        }catch (error) {
            return dispatch(signInFail(error));
        }
    }
};

export const setUserStateFail = (error) => {
    return {
        type: actionTypes.FETCH_USER_PROFILE_FAILED,
        payload: {
            error,
        },
    };
};

export const fetchUserProfileSuccess = (userProfile) =>{
    return {
        type: actionTypes.FETCH_USER_PROFILE_SUCCESS,
        payload: {
            userProfile,
        },
    };
};

export const fetchUserProfile = (user) => {
    return async dispatch => {
        try {
            const userProfile = await fetch(`${Properties.API_HOST}u/${user.uid}/profile`,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.ma,
                },
            }).then((data)=>data.json());
            dispatch(setUserState(user, userProfile.authToken, userProfile.planName));
            dispatch(fetchUserProfileSuccess(userProfile));
            // dispatch(oss.fetchOSS(user.uid, userProfile.authToken));
            dispatch(oss.fetchPagination(user.uid, userProfile.authToken));
            dispatch(shoppingCart.fetchAlreadyPurchased(user.uid, user.ma));
            dispatch(pipelines.fetchInstances(user.uid, user.ma));
            return userProfile;
        }catch (error) {
            dispatch(setUserStateFail(error));
        }
    }
};
