import React, { Component } from 'react';
import { connect } from 'react-redux';
import PipelineItem from '../PipelineItem';
import GitItem from '../GitItem';
import { pipelines } from '../../../actions';
import { LoadingSpinner } from '../../../components';
// import TransferPopup from '../TransferPopup';
import LoadingPopup from '../../TransferPipelines/LoadingPopup';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class PipelineView extends Component {
  state = {
    transferPopup: false,
  };

  // componentDidMount = async () => {
  //   if (
  //     this.props.userInstances.length !== 0 &&
  //     this.props.instanceNamespaces &&
  //     this.props.instanceNamespaces.namespaces
  //   ) {
  //     await this.props.getNamespaces(
  //       this.props.userInstances[0]['uniqueId'],
  //       this.props.currentUser.ma
  //     );
  //     await this.props.setNameSpace(
  //       this.props.instanceNamespaces.namespaces[0]['name']
  //     );
  //     await this.props.fetchInstancePipelines(
  //       this.props.userInstances[0]['uniqueId'],
  //       this.props.instanceNamespaces.namespaces[0]['name'],
  //       this.props.currentUser.ma
  //     );
  //   }
  // };

  // transferPopupHandler = () => {
  //   this.setState({
  //     transferPopup: !this.state.transferPopup,
  //   });
  // };

  transferPipelinesHandler = () => {
    // if (this.props.checkedPipelines.length > 0) {
    //   this.transferPopupHandler();
    //   return;
    // }
    this.props.history.push('/transfer-pipelines');
    // return;
  };

  // exportPipelinesHandler = async () => {
  //   if (this.props.checkedPipelines.length > 0) {
  //     const {
  //       currentUser,
  //       sourceNamespace,
  //       sourceInstance,
  //       checkedPipelines,
  //     } = this.props;
  //     const obj = { pipelinesList: checkedPipelines };
  //     await this.props.exportPipelines(
  //       currentUser.ma,
  //       sourceInstance,
  //       sourceNamespace,
  //       obj
  //     );
  //     return;
  //   }
  //   return;
  // };

  render() {
    const items = this.props.userInstances.map((instanceData, index) => {
      if (instanceData.type !== 'GIT') {
        return (
          <PipelineItem
            key={index}
            instanceData={instanceData}
            history={this.props.history}
            instanceIndex={index}
          />
        );
      }
      return (
        <GitItem
          key={index}
          instanceData={instanceData}
          history={this.props.history}
          instanceIndex={index}
        />
      );
    });
    if (this.props.isLoadingFetchInstance) {
      return <LoadingSpinner textToShow="Loading..." />;
    }
    if (this.props.userInstances.length === 0) {
      return <div>{this.props.history.push('/instance/add')}</div>;
    }
    return (
      <div className="pipeline-view">
        <div
          className="bg-color-shoppingList"
          style={{ border: '1px solid #efebeb', borderRadius: '5px' }}
        >
          <div className="col-lg-12" style={{ margin: '20px 0px' }}>
            <div className="row button-instance-buttons">
              <div className="col pl-0">
                <button
                  className="btn btn-primary"
                  style={{ padding: '0.525rem 1.05rem' }}
                  onClick={() => {
                    this.props.history.push('/instance/add');
                  }}
                >
                  <i className="fa fa-plus"></i>
                  Add Instance
                </button>
              </div>
              <div className="col pr-0 text-right" style={{ padding: '0 5px' }}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.transferPipelinesHandler();
                  }}
                >
                  <i className="fa fa-exchange"></i>
                  1:1 Transfer
                </button>
              </div>
            </div>
            <FormControlLabel
              style={{ marginLeft: '24px' }}
              control={
                <Switch
                  checked={this.props.dense}
                  onChange={() => {
                    this.props.densePadding();
                  }}
                />
              }
              label="Dense padding"
            />
          </div>
          {items}
        </div>
        {(this.props.transferPipelinesisLoading ||
          this.props.isLoadingExport) && (
          <LoadingPopup textToShow="Loading..." />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoadingFetchInstance: state.pipelines.isLoadingFetchInstance,
  transferPipelinesisLoading: state.transferPipelines.isLoading,
  isLoadingExport: state.pipelines.isLoadingExport,
  userInstances: state.pipelines.userInstances,
  instanceNamespaces: state.pipelines.instanceNamespaces,
  checkedPipelines: state.pipelines.checkedPipelines,
  sourceInstance: state.pipelines.instance_id,
  sourceNamespace: state.pipelines.nameSpace,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  activeComponent: state.myPlugins.activeComponent,
  dense: state.pipelines.densePadding,
});

const mapActionsToProps = {
  fetchInstancePipelines: pipelines.fetchInstancePipelines,
  getNamespaces: pipelines.getNamespaces,
  setNameSpace: pipelines.setNameSpace,
  exportPipelines: pipelines.exportPipelines,
  densePadding: pipelines.densePadding,
};

export default connect(mapStateToProps, mapActionsToProps)(PipelineView);
