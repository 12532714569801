import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { userActions } from '../../actions';
import { connect } from 'react-redux';
import Assets from '../../assets';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: [],
      loading: false,
      showPassword: false,
    };
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  done = () => {
    console.log('done');
  };

  isFormValid = ({ email, password }) => {
    let errors = [];
    let error;
    if (this.isFormEmpty(this.state)) {
      error = { message: 'Fill in all fields!' };
      this.setState({ errors: errors.concat(error) });
      return false;
    }
    return email && password;
  };

  isFormEmpty = ({ email, password }) => {
    return !email.length || !password.length;
  };

  displayError = (errors) => {
    return errors.map((errors, i) => <i key={i}>{errors.message}</i>);
  };

  googleSignIn = async () => {
    this.props.setErrorToNull();
    await this.props.googleSignIn();
    if (this.props.error) {
      this.setState({
        errors: this.state.errors.concat(this.props.error),
        loading: false,
      });
      return;
    }
    this.props.history.push('/');
  };

  gitHubSignIn = async () => {
    this.props.setErrorToNull();
    await this.props.gitHubSignIn();
    if (this.props.error) {
      this.setState({
        errors: this.state.errors.concat(this.props.error),
        loading: false,
      });
      return;
    }
    this.props.history.push('/');
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.props.setErrorToNull();
    if (this.isFormValid(this.state)) {
      this.setState({ errors: [], loading: true });
      await this.props.regularSignIn(this.state.email, this.state.password);
      if (this.props.error) {
        this.setState({
          errors: this.state.errors.concat(this.props.error),
          loading: false,
        });
        return;
      }
      this.props.history.push('/');
    }
  };

  render() {
    const { email, password } = this.state;
    return (
      <div>
        <div className="auth-header bg-gradient-primary login-page">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-6 text-center">
              <img
                src="https://d33wubrfki0l68.cloudfront.net/23317a66889fb01027aeb64af443fce2c03623b4/74d74/adaptivescale/img/logo.png"
                alt=""
                style={{ width: '40%', padding: '0px 0px 35px 0px' }}
              />
              <div style={{ marginBottom: '50px' }}>
                <center>
                  <p className="text-white" style={{ fontSize: '25px' }}>
                    Sign in on AdaptiveScale Hub
                  </p>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div className="background" style={{ background: '#172B4D' }}>
          <div className="container h-74vh">
            <div className="row justify-content-center">
              <div className="row justify-content-center">
                <div className="card bg-secondary shadow border-0">
                  <div className="card-header bg-transparent pb-5">
                    <div className="text-muted text-center mt-2 mb-3">
                      <small>Sign in with</small>
                    </div>
                    <div className="btn-wrapper text-center">
                      <button
                        className="btn btn-neutral btn-icon"
                        onClick={(event) => this.gitHubSignIn(event)}
                      >
                        <span className="btn-inner--icon">
                          <img src={Assets.images.github} alt="" />
                        </span>
                        <span className="btn-inner--text">Github</span>
                      </button>
                      <button
                        onClick={(event) => this.googleSignIn(event)}
                        className="btn btn-neutral btn-icon"
                      >
                        <span className="btn-inner--icon">
                          <img src={Assets.images.google} alt="" />
                        </span>
                        <span className="btn-inner--text">Google</span>
                      </button>
                    </div>
                  </div>
                  <div className="card-body px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Or sign in with credentials</small>
                    </div>
                    <form onSubmit={(event) => this.handleSubmit(event)}>
                      <div className="form-group mb-3">
                        <FormControl
                          className="input-group bg-white"
                          variant="outlined"
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Email
                          </InputLabel>
                          <OutlinedInput
                            name="email"
                            label="Email"
                            value={email}
                            onChange={(event) => this.handleChange(event)}
                            id="standard-start-adornment"
                          />
                        </FormControl>
                      </div>
                      <div className="form-group mb-3">
                        <FormControl
                          className="input-group bg-white"
                          variant="outlined"
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Password
                          </InputLabel>
                          <OutlinedInput
                            value={password}
                            name="password"
                            label="Password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            onChange={(event) => this.handleChange(event)}
                            id="standard-start-adornment"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    this.handleClickShowPassword();
                                  }}
                                  edge="end"
                                >
                                  {this.state.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-primary my-4">
                          Sign in
                        </button>
                      </div>
                    </form>
                    <div className="row mt-3">
                      <div className="col-6"></div>
                      <div className="col-6 text-right">
                        <Link to="/register" className="text-light">
                          <small>Sign up</small>
                        </Link>
                      </div>
                    </div>
                    {this.state.errors.length > 0 && (
                      <div className="login-error">
                        <p
                          className="inline-block"
                          style={{ fontSize: '15px', color: '#c14343' }}
                        >
                          {this.displayError(this.state.errors)}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateFromProps = (state) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
});

const mapActionsToProps = {
  setUser: userActions.setUser,
  googleSignIn: userActions.googleSignIn,
  gitHubSignIn: userActions.gitHubSignIn,
  regularSignIn: userActions.regularSignIn,
  setErrorToNull: userActions.setErrorToNull,
};

export default connect(mapStateFromProps, mapActionsToProps)(Login);
