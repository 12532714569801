import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pipelines } from '../../../actions';
import ActionsToolbar from './ActionsToolbar';
import DeletePopup from '../DeletePopup';
import CheckboxTree from 'react-checkbox-tree-enhanced';
import { set as setIn } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import 'react-checkbox-tree-enhanced/lib/react-checkbox-tree.css';

class GitItem extends Component {
  state = {
    popup: false,
    openMenu: false,
    anchorEl: null,
    error: false,
    disableButton: false,
    branch: this.props.instanceData.currentBranch,
  };

  handleClick = () => {
    this.setState({
      openMenu: !this.state.openMenu,
    });
  };

  popupHandler = () => {
    this.setState({
      popup: !this.state.popup,
    });
  };

  deleteInstance = async () => {
    const { currentUser } = this.props;
    await this.props.deleteInstance(
      { instance_id: this.props.instanceData.uniqueId },
      currentUser.uid,
      currentUser.ma
    );
    await this.props.fetchInstances(currentUser.uid, currentUser.ma);
    this.popupHandler();
  };

  onCheck = (checked) => {
    const checkedData_filter = checked.filter((element) =>
      element
        .split('/')
        .pop()
        .includes('.json')
    );
    this.props.setCheckedPipelines(
      checkedData_filter,
      this.props.instanceIndex
    );
  };

  onExpand = (expanded) => {
    this.props.gitInstanceExpandTree(expanded, this.props.instanceIndex);
  };

  expandAndFetch = async () => {
    if (this.props.instanceData.jsonTreeUrl === null) {
      this.props.setErrorMessage({
        code: 400,
        message: 'Task added on queue, please wait.',
      });
      await this.props.updateGitInstance(
        this.props.currentUser.uid,
        this.props.currentUser.ma,
        this.props.instanceIndex
      );
      return;
    }
    if (this.props.instanceData.jsonTreeUrl === 'Authentication failed') {
      this.props.setErrorMessage({
        code: 401,
        message:
          'Authentication failed. Please check your username/password of GIT instance.',
      });
      return;
    }
    await this.props.fetchJSONTree(
      this.props.instanceData.jsonTreeUrl,
      this.props.instanceIndex,
      this.props.instanceData.uniqueId
    );
    this.props.handleExpand(this.props.instanceIndex);
    return;
  };

  changeBranchEvent = async (event) => {
    let value = event.target.value;
    const { currentUser, instanceData } = this.props;
    this.setState({
      branch: value,
    });
    await this.props.cloneRepo(
      currentUser.uid,
      currentUser.ma,
      instanceData.uniqueId,
      { branch: value }
    );
    this.props.setErrorMessage({
      code: 400,
      message:
        'Task added on queue, the instance will be disabled till the task will be completed.',
    });
  };

  disableInstance = () => {
    const pendingAndProcessing = this.props.queueTasks.filter(
      (el) => el.status === 'pending' || el.status === 'processing'
    );
    const res = pendingAndProcessing.some(
      (el) => el.instance_id === this.props.instanceData.uniqueId
    );
    setIn(this.state, 'disableButton', res);
    if (res) {
      this.props.updateGitInstance(
        this.props.currentUser.uid,
        this.props.currentUser.ma,
        this.props.instanceIndex
      );
      // setIn(this.state, 'disableButton', res);
    }
  };

  render() {
    if (this.props.queueTasks.length > 0) {
      this.disableInstance();
    }
    const { instanceData, pipeline_data_instance_id } = this.props;
    const checked =
      instanceData.checkedPipelines === undefined
        ? []
        : instanceData.checkedPipelines;
    const expanded =
      instanceData.expandTree === undefined ? [] : instanceData.expandTree;
    const checkUniqueId = pipeline_data_instance_id === instanceData.uniqueId;
    return (
      <div className="col-lg-12">
        <div className='button-instance'
          // className={
          //   this.state.disableButton
          //     ? 'disabled-button-instance'
          //     : 'button-instance'
          // }
        >
          <div className="row" style={{ padding: '0 30px' }}>
            <button
            className='instance-name row'
              // className={
              //   this.state.disableButton
              //     ? 'disabled-instance-name row'
              //     : 'instance-name row'
              // }
              disabled={this.state.disableButton}
              onClick={
                instanceData.expand === false &&
                instanceData &&
                instanceData.json_tree
                  ? () => {
                      this.props.handleExpand(this.props.instanceIndex);
                    }
                  : instanceData.expand === true
                  ? () => {
                      this.props.handleExpand(this.props.instanceIndex);
                    }
                  : async () => {
                      await this.expandAndFetch();
                    }
              }
            >
              {this.props.isLoading && checkUniqueId ? (
                <div className="spinner-border mgLeft10" />
              ) : instanceData.expand === true ? (
                <i className="fa fa-chevron-down mgLeft10 mg-top-4" />
              ) : (
                <i className="fa fa-chevron-right mgLeft10 mg-top-4" />
              )}
              <div style={{ marginLeft: '15px', color: 'none' }}>
                {instanceData.instanceName}
              </div>
            </button>
            {instanceData.expand === true && (
              <div
                className="row"
                style={{
                  flex: '0.755 1 1%',
                  margin: '3px 0px 6px',
                }}
              >
                {/* <Tooltip title="Refresh"> */}
                <button
                  className="btn btn-light"
                  onClick={async () => {
                    await this.props.updateGitInstance(
                      this.props.currentUser.uid,
                      this.props.currentUser.ma,
                      this.props.instanceIndex
                    );
                  }}
                  style={{
                    padding: '0.25rem 0.8rem',
                    fontWeight: '100',
                    boxShadow: 'none',
                  }}
                >
                  <i
                    className="fa fa-refresh"
                    style={{ marginRight: '5px', fontSize: '15px' }}
                  />
                  Refresh
                </button>
                {/* </Tooltip> */}
                <p
                  className="text-right"
                  style={{ paddingTop: '10px', fontSize: '14px' }}
                >
                  Branches:
                </p>
                <select
                  className="select-namespace text-right"
                  style={{ margin: '6px 10px 0px' }}
                  name="branch"
                  // value={this.props.instanceData.currentBranch}
                  value={this.state.branch}
                  onChange={(event) => {
                    this.changeBranchEvent(event);
                  }}
                >
                  {instanceData &&
                    instanceData.branches.map((element, index) => (
                      <option
                        style={{ background: 'transparent' }}
                        key={index}
                        value={element}
                      >
                        {element}
                      </option>
                    ))}
                </select>
              </div>
            )}
            <div className="text-right">
              <IconButton
                disabled={this.state.disableButton}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => {
                  this.setState({
                    anchorEl: event.currentTarget,
                  });
                  this.handleClick();
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={this.state.anchorEl}
                id="long-menu"
                open={this.state.openMenu}
                onClose={() => {
                  this.handleClick();
                }}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: '23ch',
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    this.props.history.push({
                      pathname: '/instance/edit',
                      instanceIndex: this.props.instanceIndex,
                    });
                    this.handleClick();
                  }}
                >
                  <i className="fa fa-edit btn-edit pd-right-10"></i>
                  <Typography variant="inherit">Edit</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.popupHandler();
                    this.handleClick();
                  }}
                >
                  <i className="fa fa-trash btn-edit pd-right-10"></i>
                  <Typography variant="inherit">Delete</Typography>
                </MenuItem>
                {instanceData && instanceData.namespacesData && (
                  <MenuItem
                    onClick={() => {
                      this.props.exportAllPipelines(
                        this.props.currentUser.ma,
                        this.props.instanceData.uniqueId
                      );
                    }}
                  >
                    <i className="fa fa-file-archive-o btn-edit pd-right-10"></i>
                    <Typography variant="inherit">
                      Export all pipelines
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            </div>
          </div>
          {this.state.disableButton && (
            <div className="git-progress-bar">
              <LinearProgress style={{ borderRadius: '40px' }} />
            </div>
          )}
          {instanceData.expand && (
            <div>
              <p style={{ margin: '0 0 10px 13px' }}>
                Pipelines repository tree.
              </p>
              <ActionsToolbar
                numSelected={checked.length}
                instanceData={instanceData}
                instanceIndex={this.props.instanceIndex}
              />
              <div className="tree" style={{ margin: '20px' }}>
                <CheckboxTree
                  checked={checked}
                  expanded={expanded}
                  iconsClass="fa5"
                  nodes={[instanceData.json_tree]}
                  onCheck={this.onCheck}
                  onExpand={this.onExpand}
                  // disabled={true}
                />
              </div>
            </div>
          )}
        </div>
        {this.state.popup && (
          <DeletePopup
            popupHandler={this.popupHandler.bind(this)}
            deleteInstance={this.deleteInstance.bind(this)}
            instanceName={instanceData.instanceName}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pipelineData: state.pipelines.data,
  instanceNamespaces: state.pipelines.instanceNamespaces,
  nameSpace: state.pipelines.nameSpace,
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  activeComponent: state.myPlugins.activeComponent,
  isLoading: state.pipelines.isLoading,
  pipeline_data_instance_id: state.pipelines.instance_id,
  queueTasks: state.pipelines.queueTasks,
});

const mapActionsToProps = {
  setCheckedPipelines: pipelines.setCheckedPipelines,
  gitInstanceExpandTree: pipelines.gitInstanceExpandTree,
  deleteInstance: pipelines.deleteInstance,
  fetchInstances: pipelines.fetchInstances,
  handleExpand: pipelines.handleExpand,
  exportAllPipelines: pipelines.exportAllPipelines,
  fetchJSONTree: pipelines.fetchJSONTree,
  cloneRepo: pipelines.cloneRepo,
  resetGitItemData: pipelines.resetGitItemData,
  setErrorMessage: pipelines.setErrorMessage,
  updateGitInstance: pipelines.updateGitInstance,
};

export default connect(mapStateToProps, mapActionsToProps)(GitItem);
