import {
  USER_PAYMENT_METHOD_FETCH_BEGIN,
  USER_PAYMENT_METHOD_FETCH_SUCCESS,
  USER_PAYMENT_METHOD_FETCH_FAIL,
  ATTACH_PAYMENT_METHOD_BEGIN,
  ATTACH_PAYMENT_METHOD_SUCCESS,
  ATTACH_PAYMENT_METHOD_FAIL,
  DETACH_PAYMENT_METHOD_BEGIN,
  DETACH_PAYMENT_METHOD_FAIL,
  DETACH_PAYMENT_METHOD_SUCCESS,
  SET_DEFAULT_PAYMENT_METHOD_BEING,
  SET_DEFAULT_PAYMENT_METHOD_SUCESS,
  SET_DEFAULT_PAYMENT_METHOD_FAIL,
} from './types';
import Properties from "../config/properties";


const fetchUserPaymentMethodBegin = () => ({
  type: USER_PAYMENT_METHOD_FETCH_BEGIN,
});

const fetchUserPaymentMethodSuccess = (data) => ({
  type: USER_PAYMENT_METHOD_FETCH_SUCCESS,
  payload: { data },
});

const fetchUserPaymentMethodFail = (error) => ({
  type: USER_PAYMENT_METHOD_FETCH_FAIL,
  payload: { error },
});

export const fetchUserPaymentMethod = (currentUserId, authToken) => {
  return async dispatch => {
    try {
      dispatch(fetchUserPaymentMethodBegin());
      const res = await fetch(`${Properties.API_HOST}u/${currentUserId}/payment-methods`,{
          headers: {
              'Content-Type': 'application/json',
              'Authorization': authToken,
          },
      });
      const response = await res.json();
      dispatch(fetchUserPaymentMethodSuccess(response));
      return response;
    } catch (error) {
      return dispatch(fetchUserPaymentMethodFail(error))
    }
  };
};

const attachPaymentMethodBegin = () => ({
  type: ATTACH_PAYMENT_METHOD_BEGIN,
});

const attachPaymentMethodSuccess = (response) => ({
  type: ATTACH_PAYMENT_METHOD_SUCCESS,
  payload: { response },
});

const attachPaymentMethodFail = (error) => ({
  type: ATTACH_PAYMENT_METHOD_FAIL,
  payload: { error },
});

export const attachPaymentMethod = (currentUserId, authToken, paymentMethod) => {
  return async dispatch => {
    try {
      dispatch(attachPaymentMethodBegin());
      const res = await fetch(`${Properties.API_HOST}u/${currentUserId}/payment-methods`, {
        method: 'POST',
        body: JSON.stringify(paymentMethod),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken,
        },
      });
      const response = await res.json();
      dispatch(attachPaymentMethodSuccess(response));
      dispatch(fetchUserPaymentMethod(currentUserId, authToken));
      return response;
    } catch (error) {
      return dispatch(attachPaymentMethodFail(error))
    }
  }
};

const detachPaymentMethodBegin = () => ({
  type: DETACH_PAYMENT_METHOD_BEGIN,
});

const detachPaymentMethodSuccess = (response) => ({
  type: DETACH_PAYMENT_METHOD_SUCCESS,
  payload: { response },
});

const detachPaymentMethodFail = (error) => ({
  type: DETACH_PAYMENT_METHOD_FAIL,
  payload: { error },
});

export const detachPaymentMethod = (currentUserId, authToken, paymentMethod) => {
  return async dispatch => {
    try {
      dispatch(detachPaymentMethodBegin());
      const res = await fetch(`${Properties.API_HOST}u/${currentUserId}/payment-methods/${paymentMethod.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken,
        },
      });
      const response = await res.json();
      dispatch(detachPaymentMethodSuccess(response));
      dispatch(fetchUserPaymentMethod(currentUserId, authToken));
      return response;
    } catch (error) {
      return dispatch(detachPaymentMethodFail(error))
    }
  }
};

const setDefaultPaymentMethodBegin = () => ({
  type: SET_DEFAULT_PAYMENT_METHOD_BEING,
});

const setDefaultPaymentMethodSuccess = (response) => ({
  type: SET_DEFAULT_PAYMENT_METHOD_SUCESS,
  payload: { response },
});

const setDefaultPaymentMethodFail = (error) => ({
  type: SET_DEFAULT_PAYMENT_METHOD_FAIL,
  payload: { error },
});

export const setDefaultPaymentMethod = (currentUserId, authToken, paymentMethod) => {
  return async dispatch => {
    try {
      dispatch(setDefaultPaymentMethodBegin());
      const res = await fetch(`${Properties.API_HOST}u/${currentUserId}/payment-methods/${paymentMethod.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken,
        },
      });
      const response = await res.json();
      dispatch(setDefaultPaymentMethodSuccess(response));
      dispatch(fetchUserPaymentMethod(currentUserId, authToken));
      return response;
    } catch (error) {
      return dispatch(setDefaultPaymentMethodFail(error));
    }
  }
};

