import React, {Component} from "react";
import {connect} from "react-redux";
import ShoppingCartHeader from "../ShoppingCart/ShoppingCartHeader";
import {
    StripeProvider,
    Elements,
} from 'react-stripe-elements';
import SecureCheckout from './SecureCheckout'
import {Properties} from "../../config";

class PaymentScreen extends Component {
    render() {
        return (
            <div>
                < div className="header pt-md-6">
                    <div className="container">
                        <div className="header-body">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card-stats mb-4 mb-xl-0 pd-10">
                                        <div className="card-body bg-white rounder-borders card-body-shadow">
                                            <ShoppingCartHeader
                                                goBack={() => {
                                                    this.props.history.goBack()
                                                }}
                                                history={this.props.history}
                                                componentName="Pricing Details"
                                            />
                                            <div className='row'>
                                                <div className="col-lg-12">
                                                    <div className="card-body">
                                                        <div className="col-lg-12">
                                                            <div className="col-lg-12 mgTopBottom border-top pd-10">
                                                                <StripeProvider
                                                                    apiKey={Properties.STRIPE_API_KEY}>
                                                                    <Elements>
                                                                        <SecureCheckout
                                                                            pathname={this.props.location.pathname}
                                                                            history = {this.props.history}
                                                                        />
                                                                    </Elements>
                                                                </StripeProvider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.currentUser,
    authToken: state.user.authToken,
    isUserSet: state.user.isUserSet,
    ...ownProps,
});
export default connect(mapStateToProps)(PaymentScreen);
