import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <div className='container'>
        <div className="row">
          <div className="col-lg-12">
            <div className="card-stats mb-4 mb-xl-0 pd-10">
              <div className="card-body bg-white rounder-borders card-body-shadow">
                <div className="row">
                  <ul className="footer-terms" style={{ marginBottom: '1em' }}>
                    <li>
                      <p
                        onClick={() => {
                          this.props.history.push('/terms-conditions');
                        }}
                        style={{ color: 'grey', cursor:"pointer"}}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Terms of service
                      </p>
                    </li>
                    <li> | </li>
                    <li>
                      <p
                        onClick={() => {
                          this.props.history.push('/privacy-policy');
                        }}
                        style={{ color: 'grey', cursor:"pointer"}}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Privacy policy
                      </p>
                    </li>
                    <li> | </li>
                    <li>
                      <p
                        onClick={() => {
                          this.props.history.push('/legal-agreement');
                        }}
                        style={{ color: 'grey', cursor:"pointer"}}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Legal Agreement
                      </p>
                    </li>
                  </ul>
                  <div
                    className="col text-lg-right"
                    style={{ margin: '1em 15px' }}
                  >
                    <a
                      href="https://www.facebook.com/AdaptiveScaleOfficial/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-facebook social-net-icon" />
                    </a>
                    <a
                      href="https://twitter.com/AdaptiveScale"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-twitter social-net-icon" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/adaptivescale/about/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin social-net-icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    );
  }
}
export default withRouter(Footer);
