import Fuse from 'fuse.js';
import * as actionTypes from '../actions/types';
import { isEmpty, uniqBy, orderBy, sortBy, filter } from 'lodash';
import { stripExtension } from '../helpers';

const initialOSSState = {
  error: null,
  data: [],
  allOss: [],
  isLoading: false,
  filteredData: [],
  commercialData: [],
  commercialFilteredData: [],
  myPlugins: [],
  myPluginsFilteredData: [],
  privateData: [],
  fuseKeys: [
    { name: 'converted_name', weight: 0.4 },
    { name: 'widgets.name', weight: 0.4 },
    { name: 'widgets.data.display-name', weight: 0.4 },
    { name: 'tags', weight: 0.4 },
  ],
  fuseOptions: { shouldSort: true },
  artifactTypes: [],
  filters: {
    cdapVersion: '6.2.0',
    artifactType: 'all',
    showBundles: true,
  },
  itemsCount: '',
  keyword: '',
  activeTab: 'all',
  paginationIsLoading: false
};

function fitlerArtifactsByVersion(data, version) {
  if (version === 'all') {
    return data;
  }
  return data.filter(function(element) {
    if (element.converted_cdapVersion) {
      const cdapFrom = parseFloat(element.converted_cdapVersion.from);
      const cdapTo = parseFloat(element.converted_cdapVersion.to);
      const versionToCompare = parseFloat(version);
      if (
        element.converted_cdapVersion.includeFrom === true &&
        element.converted_cdapVersion.includeTo === true
      ) {
        return versionToCompare >= cdapFrom && versionToCompare <= cdapTo;
      } else if (
        element.converted_cdapVersion.includeFrom === true &&
        element.converted_cdapVersion.includeTo === false
      ) {
        return versionToCompare >= cdapFrom && versionToCompare < cdapTo;
      }
    }
    return false;
  });
}

function fitlerArtifactsByType(data, pluginType) {
  if (pluginType !== 'all') {
    const result = [];
    const dataFilter = [...data.filter((element) => element.widgets)];
    dataFilter.forEach((artifact) => {
      const widgetPluginTypeFound = artifact.widgets.findIndex(
        (widget) => widget.pluginType === pluginType
      );
      let tempArtifact = {
        ...artifact,
      };
      if (widgetPluginTypeFound !== -1) {
        tempArtifact['widgets'] = filter(artifact.widgets, {
          pluginType: pluginType,
        });
        result.push(tempArtifact);
      }
    });
    return result;
  } else {
    return data;
  }
}

function mapWidgetDocs(data) {
  return data.map((artifact) => {
    if (artifact.widgets) {
      artifact.widgets = artifact.widgets.map((widget) => {
        const widgetDocIndex = artifact.docs_file
          ? artifact.docs_file.findIndex((doc) => {
              if (doc.name === undefined) {
                return false;
              }
              if (widget.name === undefined) {
                return false;
              }
              const tempDocFileName = stripExtension(doc.name).toLowerCase();
              const tempWidgetFileName = stripExtension(
                widget.name
              ).toLowerCase();
              return tempDocFileName === tempWidgetFileName;
            })
          : -1;
        widget['doc_file'] = null;
        if (widgetDocIndex !== -1) {
          widget['doc_file'] = artifact.docs_file[widgetDocIndex];
          widget['doc_file']['content'] = null;
        }
        return widget;
      });
    }
    return artifact;
  });
}

function getArtifactsUniqueByName(data) {
  return sortBy(
    uniqBy(orderBy(data, ['name', 'version'], ['asc', 'desc']), 'name'),
    'converted_name'
  );
}

function getFilteredData(dataToFilter, filters) {
  const versionFiltered = getArtifactsUniqueByName(
    fitlerArtifactsByVersion(dataToFilter, filters.cdapVersion)
  );
  const typeFiltered = fitlerArtifactsByType(
    versionFiltered,
    filters.artifactType
  );
  return sortBy(typeFiltered, 'converted_name');
}

function extractArtifactTypes(data) {
  let pluginTypes = [];
  data
    .filter((artifact) => artifact.widgets)
    .flatMap((artifact) => artifact.widgets)
    .forEach((widget) => {
      if (pluginTypes.indexOf(widget.pluginType) === -1) {
        pluginTypes.push(widget.pluginType);
      }
    });
  pluginTypes.sort();
  pluginTypes.unshift('all');
  return pluginTypes;
}

function setDataToFilter(state, keyword, filters) {
  let dataToFilter = '';
  let fuseSearch = '';
  if (state.activeTab === 'all' || state.activeTab === 'openSource') {
    dataToFilter =
      filters.cdapVersion === 'all'
        ? [...state.dataUniqueByName]
        : [...state.data];
    fuseSearch = new Fuse([...state.filteredData], {
      keys: initialOSSState.fuseKeys,
      ...state.fuseOptions,
    });
    return {
      ...state,
      keyword: keyword,
      filters,
      filteredData: !isEmpty(keyword)
        ? fuseSearch.search(keyword)
        : getFilteredData(dataToFilter, filters),
    };
  }
  if (state.activeTab === 'commercial') {
    dataToFilter = [...state.commercialData];
    fuseSearch = new Fuse([...state.commercialData], {
      keys: initialOSSState.fuseKeys,
      ...state.fuseOptions,
    });
    return {
      ...state,
      keyword: keyword,
      filters,
      commercialFilteredData: !isEmpty(keyword)
        ? fuseSearch.search(keyword)
        : getFilteredData(dataToFilter, filters),
    };
  }
  if (state.activeTab === 'myPlugins') {
    dataToFilter = [...state.myPlugins];
    fuseSearch = new Fuse([...state.myPlugins], {
      keys: initialOSSState.fuseKeys,
      ...state.fuseOptions,
    });
    return {
      ...state,
      keyword: keyword,
      filters,
      myPluginsFilteredData: !isEmpty(keyword)
        ? fuseSearch.search(keyword)
        : getFilteredData(dataToFilter, filters),
    };
  }
}

export default (state = initialOSSState, action) => {
  switch (action.type) {
    case actionTypes.BEGIN_PAGINATION_FETCH_CONTINUE: {
        return {
          ...state,
          paginationIsLoading: true,
        };
      }
    case actionTypes.FIRST_BEGIN_PAGINATION_FETCH: {
      return {
        ...state,
        data: [],
        isLoading: true,
      };
    }
    case actionTypes.PAGINATION_FETCH_SUCCESS: {
    // case actionTypes.OSS_FETCH_SUCCESS: {
      // console.debug(actionTypes.OSS_FETCH_SUCCESS, action);
      const datas = state.data.concat(action.payload.oss)
      const dataUniqueByName = getArtifactsUniqueByName(
        mapWidgetDocs(datas)
      );
      const filteredData = getFilteredData(datas, state.filters);
      const artifactTypes = extractArtifactTypes(dataUniqueByName);
      const commerciaData = filteredData.filter(
        (artifact) => artifact.commercial === true
      );
      const privateData = filteredData.filter(
        (artifact) => artifact.visibility === 'private'
      );
      return {
        ...state,
        data: datas,
        dataUniqueByName,
        filteredData: filteredData,
        commercialData: commerciaData,
        commercialFilteredData: commerciaData,
        privateData: privateData,
        privateFilteredData: privateData,
        artifactTypes,
        isLoading: false,
        paginationIsLoading: false,
        error: null,
      };
    }
    case actionTypes.PAGINATION_FETCH_FAIL: {
    // case actionTypes.OSS_FETCH_FAIL: {
      // console.debug(actionTypes.OSS_FETCH_FAIL, action);
      return {
        ...state,
        data: [],
        filteredData: [],
        isLoading: false,
        paginationIsLoading: false,
        error: action.payload.error,
      };
    }
    case actionTypes.OSS_SEARCH: {
      console.debug(actionTypes.OSS_SEARCH, action);
      const keyword = action.payload.keyword;
      const filters = state.filters;
      // setDataToFilter(state, keyword, filters);
      // const dataToFilter = state.filters.cdapVersion === 'all' ? [...state.dataUniqueByName] : [...state.data];
      // const fuseSearch = new Fuse([...state.filteredData], {keys: initialOSSState.fuseKeys, ...state.fuseOptions});
      // return {
      //   ...state,
      //   keyword: action.payload.keyword,
      //   filteredData: !isEmpty(action.payload.keyword) ? fuseSearch.search(action.payload.keyword) : getFilteredData(dataToFilter, state.filters),
      // };

      let dataToFilter = '';
      let fuseSearch = '';
      if (state.activeTab === 'all' || state.activeTab === 'openSource') {
        dataToFilter =
          filters.cdapVersion === 'all'
            ? [...state.dataUniqueByName]
            : [...state.data];
        fuseSearch = new Fuse([...state.filteredData], {
          keys: initialOSSState.fuseKeys,
          ...state.fuseOptions,
        });
        return {
          ...state,
          keyword: keyword,
          filters,
          filteredData: !isEmpty(keyword)
            ? fuseSearch.search(keyword)
            : getFilteredData(dataToFilter, filters),
        };
      }
      if (state.activeTab === 'commercial') {
        dataToFilter = [...state.commercialData];
        fuseSearch = new Fuse([...state.commercialData], {
          keys: initialOSSState.fuseKeys,
          ...state.fuseOptions,
        });
        return {
          ...state,
          keyword: keyword,
          filters,
          commercialFilteredData: !isEmpty(keyword)
            ? fuseSearch.search(keyword)
            : getFilteredData(dataToFilter, filters),
        };
      }
      if (state.activeTab === 'myPlugins') {
        dataToFilter = [...state.myPlugins];
        fuseSearch = new Fuse([...state.myPlugins], {
          keys: initialOSSState.fuseKeys,
          ...state.fuseOptions,
        });
        return {
          ...state,
          keyword: keyword,
          filters,
          myPluginsFilteredData: !isEmpty(keyword)
            ? fuseSearch.search(keyword)
            : getFilteredData(dataToFilter, filters),
        };
      }
      break;
    }
    case actionTypes.SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };
    }
    case actionTypes.SET_OSS_FILTERS: {
      console.debug(actionTypes.SET_OSS_FILTERS, action);
      const { filters } = action.payload;
      const keyword = state.keyword;
      return setDataToFilter(state, keyword, filters);
    }
    case actionTypes.UPDATE_PURCHASE_STATUS: {
      console.debug(actionTypes.UPDATE_PURCHASE_STATUS, action);
      const updatedData = state.data.map((artifact) =>
        action.payload.purchasedArtifacts.indexOf(artifact.uniqueId) !== -1
          ? { ...artifact, isAlreadyPurchased: true }
          : artifact
      );
      const filteredData = state.filteredData.map((artifact) =>
        action.payload.purchasedArtifacts.indexOf(artifact.uniqueId) !== -1
          ? { ...artifact, isAlreadyPurchased: true }
          : artifact
      );
      return {
        ...state,
        data: updatedData,
        filteredData,
      };
    }
    case actionTypes.SET_ITEMS_COUNT: {
      console.debug(actionTypes.SET_ITEMS_COUNT, action);
      return {
        ...state,
        itemsCount: action.payload.itemsNr,
      };
    }
    case actionTypes.BEGIN_MYPLUGINS_FETCH: {
      console.info(actionTypes.BEGIN_MYPLUGINS_FETCH);
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionTypes.MYPLUGINS_FETCH_SUCCESS: {
      console.info(actionTypes.MYPLUGINS_FETCH_SUCCESS);
      return {
        ...state,
        error: null,
        isLoading: false,
        myPlugins: action.payload.data,
        myPluginsFilteredData: action.payload.data,
      };
    }
    case actionTypes.MYPLUGINS_FETCH_FAIL: {
      console.info(actionTypes.MYPLUGINS_FETCH_FAIL);
      return {
        ...state,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
};