import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { activeState, myPlugins, oss } from '../../actions';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

class NavigationTabs extends Component {
  navTabChange = async (event, value) => {
    this.props.setActiveTab(value);
    const newFilters = {
      ...this.props.filters,
    };
    await this.props.setOssFilters(newFilters);
    this.props.changeMyPluginState('myPlugins');
  };
  render() {
    const { itemsCount, currentPlan, activeTab, privateData } = this.props;
    this.props.Search(this.props.searchKeyword);
    return (
      <div className="col-lg-12 col-xl-10 row mb-md-3">
        <div className="nav-wrapper col-12">
          <Paper className="paper-nav-tabs col">
            <Tabs
              value={this.props.activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, value) => {
                this.navTabChange(event, value);
              }}
              aria-label="disabled tabs example"
            >
              <Tab label="All" value="all" />
              <Tab label="Open Source" value="openSource" />
              <Tab label="Commercial" value="commercial" />
              {this.props.userLoggedIn ? (
                <Tab label="My Plugins" value="myPlugins" />
              ) : (
                <Tab label="My Plugins" value="myPlugins" disabled />
              )}
              {(function() {
                switch (currentPlan) {
                  case 'professional':
                    return (
                      <Tab
                        label={itemsCount}
                        disabled
                        className="button-nr-plugins"
                        style={
                          activeTab === 'myPlugins' && privateData.length >= 50
                            ? { background: '#de6767' }
                            : { background: '#3f51b5' }
                        }
                      />
                    );
                  case 'basic':
                    return (
                      <Tab
                        label={itemsCount}
                        disabled
                        className="button-nr-plugins"
                        style={
                          activeTab === 'myPlugins' && privateData.length >= 10
                            ? { background: '#de6767' }
                            : { background: '#3f51b5' }
                        }
                      />
                    );
                  default:
                    return (
                      <Tab
                        label={itemsCount}
                        disabled
                        className="button-nr-plugins"
                        style={{ background: '#3f51b5' }}
                      />
                    );
                }
              })()}
            </Tabs>
          </Paper>
        </div>
      </div>
    );
  }
}

NavigationTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
};

const mapStateFromProps = (state) => ({
  activeTab: state.activeState.activeTab,
  userLoggedIn: state.user.userLoggedIn,
  itemsCount: state.oss.itemsCount,
  currentPlan: state.user.currentPlan,
  privateData: state.oss.privateData,
  filters: state.oss.filters,
  searchKeyword: state.oss.keyword,
});

const mapActionsToProps = {
  setActiveTab: activeState.setActiveTab,
  changeMyPluginState: myPlugins.changeMyPluginState,
  setOssFilters: oss.setOssFilters,
  Search: oss.Search,
};

export default connect(mapStateFromProps, mapActionsToProps)(NavigationTabs);
