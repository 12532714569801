import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { shoppingCart, paymentMethods } from '../../../actions';
import { LoadingSpinner } from '../../../components';
import PaymentCardItem from '../../UserPaymentMethods/PaymentCardItem';
import { set as setIn } from 'lodash';
import countryList from 'react-select-country-list';

export class SecureCheckout extends Component {
  state = {
    name: '',
    lastName: '',
    address_line1: '',
    address_city: '',
    address_state: '',
    address_zip: '',
    errorMessage: '',
    activeTab: 'defaultCard',
    proccessing: false,
    statesOptions: countryList().getData(),
    email: this.props.userProfile
      ? this.props.currentUser.email !== null && this.props.currentUser.email
      : '',
  };

  componentDidMount() {
    if (this.props.userLoggedIn) {
      const { uid } = this.props.currentUser;
      this.props.fetchUserPaymentMethod(uid, this.props.currentUser.ma);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userLoggedIn !== this.props.userLoggedIn &&
      nextProps.userLoggedIn === true
    ) {
      this.props.fetchUserPaymentMethod(
        nextProps.currentUser.uid,
        nextProps.currentUser.ma
      );
    }
    if (nextProps.paymentMethodsData.length === 0) {
      this.setState({
        activeTab: 'anotherCard',
      });
    }
  }

  errorHandler = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  changeHandler = (event) => {
    const newState = setIn(this.state, event.target.name, event.target.value);
    this.setState({
      ...newState,
    });
  };

  defaultPaymentMethod = () => {
    if (this.props.paymentMethodsData.length > 0) {
      const paymentDefaultCard = this.props.paymentMethodsData
        .filter((viewItem) => viewItem.default)
        .map((viewItem, index) => {
          return <PaymentCardItem key={index} data={viewItem} />;
        });
      return paymentDefaultCard;
    } else {
      return '';
    }
  };

  handleSubmit = async (evt) => {
    if (this.state.activeTab !== 'defaultCard') {
      if (!this.paymentForm.checkValidity()) {
        this.paymentForm.reportValidity();
        return;
      }
    }
    evt.preventDefault();
    if (this.props.stripe) {
      let token = '';
      if (this.state.activeTab === 'defaultCard') {
        token = {
          token: {
            id: this.props.paymentMethodsData.filter(
              (viewItem) => viewItem.default
            )[0].id,
          },
        };
      } else {
        token = await this.props.stripe.createToken({
          name: this.state.name,
          // lastName: this.state.lastName,
          email: this.state.email,
          address_line1: this.state.address,
          address_city: this.state.address_city,
          address_state: this.state.address_state,
          address_zip: this.state.address_zip,
        });
      }
      if (!token['error']) {
        this.setState({
          proccessing: true,
        });
        if (this.props.pathname === '/checkout') {
          token.shoppingCartArticles = this.props.shoppingCartData;
          await this.props.fetchCharge(
            token,
            this.props.currentUser.ma,
            this.props.currentUser.uid
          );
          this.props.history.push('/receipt/checkout');
        }
        if (this.props.pathname.includes('/payment/'))
         {
          const planName =
            this.props.pathname === '/payment/professional'
              ? 'professional'
              : this.props.pathname === '/payment/basic'
              ? 'basic'
              : this.props.pathname === '/payment/enterprise-basic'
              ? 'enterprise-basic'
              : 'enterprise-plus';
          this.props.fetchSubscription(
            token,
            planName,
            this.props.currentUser.ma,
            this.props.currentUser.uid
          );
          this.props.history.push('/receipt/subscription');
        }
      } else {
        console.debug('Show message to user', token.error);
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    if (!this.props.userLoggedIn) {
      return <div>Please Sign in</div>;
    }
    if (this.state.proccessing) {
      return <LoadingSpinner textToShow="Proccessing..." />;
    }
    if (this.props.currentUser === null || this.props.paymentMethodIsLoading) {
      return <LoadingSpinner textToShow="Loading..." />;
    }
    if (
      this.props.pathname === '/checkout' &&
      this.props.shoppingCartData.length === 0
    ) {
      return <div>You don't have checkout amount</div>;
    }
    if (this.props.pathname !== '/checkout' && this.props.price === 0) {
      return <div>You don't have checkout amount</div>;
    }
    return (
      <div className="row">
        <div className="col-lg-8 payment">
          <div className="card-stats mb-4 mb-xl-0 pd-10">
            <div className="card-body bg-color-shoppingList rounder-borders">
              <div className="col-lg-12">
                {this.props.paymentMethodsData.length > 0 && (
                  <div className="nav-wrapper col-lg-12">
                    <ul
                      className="nav nav-tabs tab-main-page tabs"
                      style={{ marginTop: '-13px', marginBottom: '10px' }}
                    >
                      <li>
                        <button
                          name="defaultCard"
                          className={
                            this.state.activeTab === 'defaultCard'
                              ? 'btn-no-bg active-tab'
                              : 'btn-no-bg'
                          }
                          onClick={() =>
                            this.setState({ activeTab: 'defaultCard' })
                          }
                          style={{
                            cursor: 'pointer',
                            alignself: 'center',
                            color: 'inherit',
                          }}
                        >
                          Default card
                        </button>
                      </li>
                      {this.props.pathname === '/checkout' && (
                        <li>
                          <button
                            name="anotherCard"
                            className={
                              this.state.activeTab === 'anotherCard'
                                ? 'btn-no-bg active-tab'
                                : 'btn-no-bg'
                            }
                            onClick={() =>
                              this.setState({ activeTab: 'anotherCard' })
                            }
                            style={{
                              cursor: 'pointer',
                              alignself: 'center',
                              color: 'inherit',
                            }}
                          >
                            Use another card
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                {this.state.activeTab === 'defaultCard' ? (
                  <div className="default-payment">
                    {this.defaultPaymentMethod()}
                    <button
                      style={{ width: '20%', margin: 'auto' }}
                      className="btn btn-primary btn-block"
                      onClick={(event) => this.handleSubmit(event)}
                    >
                      Pay ${this.props.price}
                    </button>
                  </div>
                ) : (
                  <form
                    onSubmit={(event) => {
                      this.handleSubmit(event);
                    }}
                    name="paymentForm"
                    ref={(form) => (this.paymentForm = form)}
                  >
                    <div className="form-group row">
                      <div className="col-sm-9">
                        First Name
                        <input
                          name="name"
                          className="form-control new-input-sizes"
                          onChange={(event) => {
                            this.changeHandler(event);
                          }}
                          required
                          style={{ padding: '17px' }}
                        />
                      </div>
                      <div className="col-sm-9">
                        Last Name
                        <input
                          name="lastName"
                          className="form-control new-input-sizes"
                          required
                          onChange={(event) => {
                            this.changeHandler(event);
                          }}
                          style={{ padding: '17px' }}
                        />
                      </div>
                      {this.props.currentUser.email === null && (
                        <div className="col-sm-9">
                          Email
                          <input
                            name="email"
                            className="form-control new-input-sizes"
                            onChange={(event) => {
                              this.changeHandler(event);
                            }}
                            style={{ padding: '17px' }}
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            required
                          />
                        </div>
                      )}
                      <div className="col-sm-9">
                        Address
                        <input
                          name="address_line1"
                          className="form-control new-input-sizes"
                          required
                          onChange={(event) => {
                            this.changeHandler(event);
                          }}
                          style={{ padding: '17px', minWidth: '0px' }}
                        />
                      </div>
                      <div className="col-sm-4">
                        City
                        <input
                          name="address_city"
                          className="form-control new-input-sizes"
                          required
                          onChange={(event) => {
                            this.changeHandler(event);
                          }}
                          style={{ padding: '17px', minWidth: '0px' }}
                        />
                      </div>

                      <div className="col-sm-4">
                        State
                        <select
                          name="address_state"
                          required
                          onChange={(value) => {
                            this.changeHandler(value);
                          }}
                          className="dropdown-states form-control"
                          style={{
                            height: 'calc(2.10rem + 2px)',
                            fontSize: '15px',
                          }}
                          value={this.state.address_state}
                        >
                          <option value="">Select State</option>
                          {this.state.statesOptions.map((element, index) => (
                            <option key={index} value={element.label}>
                              {element.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-3">
                        ZIP
                        <input
                          name="address_zip"
                          className="form-control new-input-sizes"
                          required
                          onChange={(event) => {
                            this.changeHandler(event);
                          }}
                          style={{ padding: '17px', minWidth: '0px' }}
                        />
                      </div>
                    </div>
                    <div>
                      Card details
                      <CardElement onChange={this.errorHandler} />
                    </div>
                    <div className="error" role="alert">
                      {this.state.errorMessage}
                    </div>
                    <button
                      className="btn btn-primary btn-block"
                      // onClick={(event) => this.handleSubmit(event)}
                    >
                      Pay ${this.props.price}
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card-stats mb-4 mb-xl-0 pd-10">
            <div className="card-body bg-color-shoppingList rounder-borders">
              <div className="col-lg-12">
                <h4 className="text-center ">Your checkout amount</h4>
                <h2 className="text-center ">${this.props.price}.00</h2>
                <div className="text-center">
                  <div className="smallText-13" style={{ marginTop: '20px' }}>
                    Price displayed excludes sales tax.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  userProfile: state.user.userProfile,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  price: state.shoppingCart.price,
  customerData: state.shoppingCart.customerData,
  shoppingCartData: state.shoppingCart.data,
  paymentMethodsData: state.paymentMethods.data,
  paymentMethodIsLoading: state.paymentMethods.isLoading,
  ...ownProps,
});

const mapActionsToProps = {
  fetchSubscription: shoppingCart.fetchSubscription,
  fetchCharge: shoppingCart.fetchCharge,
  fetchUserPaymentMethod: paymentMethods.fetchUserPaymentMethod,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(injectStripe(SecureCheckout));
