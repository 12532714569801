import * as actionTypes from '../actions/types';

const initialTransferPipelinesState = {
  error: null,
  targetInstaceData: null,
  targetInstanceNamespaces: null,
  targetNamespacesError: null,
  individualPipelineData: null,
  compareInstanceData: [],
  deployingResponse: null,
  checkDeployingResponse: null,
  targetInstanceId: null,
  targetNamespace: null,
  targetInstance_type: null,
  saveToDrafts: false,
  instanceIndex: null,
  isLoading: false,
  isLoadingDeploing: false,
};

export default (state = initialTransferPipelinesState, action) => {
  switch (action.type) {
    case actionTypes.INDIVIDUAL_PIPELINE_BEGIN:
      console.debug(actionTypes.INDIVIDUAL_PIPELINE_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.INDIVIDUAL_PIPELINE_SUCCESS:
      console.debug(actionTypes.INDIVIDUAL_PIPELINE_SUCCESS);
      return {
        ...state,
        isLoading: false,
        individualPipelineData: action.payload.data,
      };
    case actionTypes.INDIVIDUAL_PIPELINE_FAIL:
      console.debug(actionTypes.INDIVIDUAL_PIPELINE_FAIL);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.COMPARE_INSTANCE_BEGIN:
      console.debug(actionTypes.COMPARE_INSTANCE_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.COMPARE_INSTANCE_SUCCESS:
      console.debug(actionTypes.COMPARE_INSTANCE_SUCCESS);
      return {
        ...state,
        isLoading: false,
        compareInstanceData: action.payload.data,
      };
    case actionTypes.COMPARE_INSTANCE_FAIL:
      console.debug(actionTypes.COMPARE_INSTANCE_FAIL);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.DEPLOY_PIPELINE_BEGIN:
      console.debug(actionTypes.DEPLOY_PIPELINE_BEGIN);
      return {
        ...state,
        isLoadingDeploing: true,
        deployingResponse: null,
      };
    case actionTypes.DEPLOY_PIPELINE_SUCCESS:
      console.debug(actionTypes.DEPLOY_PIPELINE_SUCCESS);
      return {
        ...state,
        isLoadingDeploing: false,
        deployingResponse: action.payload.data,
      };
    case actionTypes.DEPLOY_PIPELINE_FAIL:
      console.debug(actionTypes.DEPLOY_PIPELINE_FAIL);
      return {
        ...state,
        isLoadingDeploing: false,
        error: action.payload.error,
      };
    case actionTypes.DEPLOY_PLUGIN_BEGIN:
      console.debug(actionTypes.DEPLOY_PLUGIN_BEGIN);
      return {
        ...state,
        isLoadingDeploing: true,
        deployingResponse: null,
      };
    case actionTypes.DEPLOY_PLUGIN_SUCCESS:
      console.debug(actionTypes.DEPLOY_PLUGIN_SUCCESS);
      return {
        ...state,
        isLoadingDeploing: false,
        deployingResponse: action.payload.data,
      };
    case actionTypes.DEPLOY_PLUGIN_FAIL:
      console.debug(actionTypes.DEPLOY_PLUGIN_FAIL);
      return {
        ...state,
        isLoadingDeploing: false,
        error: action.payload.error,
      };
    case actionTypes.CLEAR_DEPLOY_RESPONSE:
      console.debug(actionTypes.CLEAR_DEPLOY_RESPONSE);
      return {
        ...state,
        deployingResponse: null,
      };
    case actionTypes.GET_TARGET_NAMESPACES_BEGIN:
      console.debug(actionTypes.GET_TARGET_NAMESPACES_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_TARGET_NAMESPACES_SUCCESS:
      console.debug(actionTypes.GET_TARGET_NAMESPACES_SUCCESS);
      return {
        ...state,
        isLoading: false,
        targetInstanceNamespaces: action.payload.data,
        targetNamespacesError: null,
      };
    case actionTypes.GET_TARGET_NAMESPACES_FAIL:
      console.debug(actionTypes.GET_TARGET_NAMESPACES_FAIL);
      return {
        ...state,
        isLoading: false,
        targetNamespacesError: action.payload.error,
      };
    case actionTypes.CLEAR_COMPARE_DATA:
      console.debug(actionTypes.CLEAR_COMPARE_DATA);
      return {
        ...state,
        compareInstanceData: [],
      };
    case actionTypes.CLEAR_TARGET_NAMESPACES:
      console.debug(actionTypes.CLEAR_TARGET_NAMESPACES);
      return {
        ...state,
        targetInstanceNamespaces: null,
      };
    case actionTypes.CLEAR_INDIVIDUAL_PIPELINE:
      console.debug(actionTypes.CLEAR_INDIVIDUAL_PIPELINE);
      return {
        ...state,
        individualPipelineData: null,
      };
    case actionTypes.BATCH_TRANSFER_BEGIN:
      console.debug(actionTypes.BATCH_TRANSFER_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.BATCH_TRANSFER_SUCCESS:
      console.debug(actionTypes.BATCH_TRANSFER_SUCCESS);
      return {
        ...state,
        isLoading: false,
        deployingResponse: action.payload.data,
        saveToDrafts: false,
      };
    case actionTypes.BATCH_TRANSFER_FAIL:
      console.debug(actionTypes.BATCH_TRANSFER_FAIL);
      return {
        ...state,
        isLoading: false,
        error: action.payload.data,
        saveToDrafts: false,
      };
    case actionTypes.SAVE_DRAFT_BEGIN:
      console.debug(actionTypes.SAVE_DRAFT_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.SAVE_DRAFT_SUCCESS:
      console.debug(actionTypes.SAVE_DRAFT_SUCCESS);
      return {
        ...state,
        isLoading: false,
        deployingResponse: action.payload.data,
      };
    case actionTypes.SAVE_DRAFT_FAIL:
      console.debug(actionTypes.SAVE_DRAFT_FAIL);
      return {
        ...state,
        isLoading: false,
        error: action.payload.data,
      };
    case actionTypes.CHECK_BATCH_TRANSFER_BEGIN:
      console.debug(actionTypes.CHECK_BATCH_TRANSFER_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.CHECK_BATCH_TRANSFER_SUCCESS:
      console.debug(actionTypes.CHECK_BATCH_TRANSFER_SUCCESS);
      return {
        ...state,
        isLoading: false,
        checkDeployingResponse: action.payload.data,
        instanceIndex: action.payload.instanceIndex,
      };
    case actionTypes.CHECK_BATCH_TRANSFER_FAIL:
      console.debug(actionTypes.CHECK_BATCH_TRANSFER_FAIL);
      return {
        ...state,
        isLoading: false,
        error: action.payload.data,
      };
    case actionTypes.CLEAR_CHECK_DEPLOING_RESPONSE:
      console.debug(actionTypes.CLEAR_CHECK_DEPLOING_RESPONSE);
      return {
        ...state,
        checkDeployingResponse: null,
      };
    case actionTypes.SET_TARGET_INSTANCE_ID_AND_NS:
      console.debug(actionTypes.SET_TARGET_INSTANCE_ID_AND_NS);
      return {
        ...state,
        targetInstanceId: action.payload.id,
        targetNamespace: action.payload.ns,
        targetInstance_type: action.payload.type,
      };
    case actionTypes.SET_SAVE_TO_DRAFTS:
      console.debug(actionTypes.SET_SAVE_TO_DRAFTS);
      return {
        ...state,
        saveToDrafts: !state.saveToDrafts,
      };
    case actionTypes.GIT_TRANSFER_BEGIN:
      console.debug(actionTypes.GIT_TRANSFER_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GIT_TRANSFER_SUCCESS:
      console.debug(actionTypes.GIT_TRANSFER_SUCCESS);
      return {
        ...state,
        isLoading: false,
        deployingResponse: action.payload.response,
        saveToDrafts: false,
      };
    case actionTypes.GIT_TRANSFER_FAIL:
      console.debug(actionTypes.GIT_TRANSFER_FAIL);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        saveToDrafts: false,
      };
    default:
      return state;
  }
};
