import React, { Component } from "react";
import { connect } from "react-redux";
import { oss } from '../../actions';
// import { Search as ossSearch } from "../../actions/oss";

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

class Search extends Component {
  changeHandler = (event) => {
    const keyword = event.target.value;
    // this.setState({ keyword });
    this.props.Search(keyword);
    // this.props[`${this.props.activeReducer}Search`](keyword);
  };

  render() {
    if (window.location.pathname.includes('/search/keys=')) {
      this.props.Search(this.props.keyword);
    }
    return (
      <div className="col" style={{ paddingTop: '10px' }}>
        <form _lpchecked="1">
          <Grid container spacing={1} style={{ flexDirection: 'row-reverse' }}>
            <TextField
              id="outlined"
              label={
                <div>
                  <i className="fa fa-search" style={{ color: 'gray' }}></i>{' '}
                  Search
                </div>
              }
              color="primary"
              variant="outlined"
              fullWidth
              value={this.props.searchKeyword}
              onChange={(event) => this.changeHandler(event)}
            />
          </Grid>
        </form>
      </div>
    );
  }
}
const mapStateToProps = state => ({
    activeReducer: state.activeState.activeSearchReducer,
    searchKeyword: state.oss.keyword,
});

// const mapDispatchToProps = dispatch => ({
//     ossSearch: (keyword) => dispatch(ossSearch(keyword)),
// });

const mapActionToProps ={
  Search: oss.Search,
}
export default connect(mapStateToProps, mapActionToProps)(Search);
