import React, { Component } from 'react';
import './App.scss';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Login,
  Register,
  OSSPlugins,
  Details,
  UserProfile,
  Navbar,
  ShoppingCart,
  PricingDetails,
  PricingPlans,
  PaymentScreen,
  ReceiptScreen,
  Credentials,
  Pipelines,
  TransferPipelines,
  LegalAgreement,
  TermsAndConditions,
  PrivacyPolicy,
  Footer,
} from './components/';
import './assets/fonts/font.css';

class App extends Component {
  render() {
    const noFooterAndNavbar = [
      '/login',
      '/register',
      '/register-payment/basic',
      '/register-payment/professional',
      '/register-payment/enterprise-basic',
      '/register-payment/enterprise-plus',
    ];
    return (
      <div>
        {noFooterAndNavbar.indexOf(this.props.location.pathname) === -1 && (
          <div className="main-content container">
            <Navbar />
          </div>
        )}

        <Switch>
          <Route exact path="/" component={OSSPlugins} />
          <Route path="/search/keys=:keyword" component={OSSPlugins} />
          <Route path="/register-payment/:planName" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route
            path="/details/:artifactName/:artifactVersion/:widgetName/"
            component={Details}
          />
          <Route
            path="/details/:artifactName/:artifactVersion/"
            component={Details}
          />
          <Route path="/userProfile" component={UserProfile} />
          <Route path="/shoppingCart" component={ShoppingCart} />
          <Route path="/pricingDetails" component={PricingDetails} />
          <Route path="/pricingPlans" component={PricingPlans} />
          <Route path="/payment/:planName/" component={PaymentScreen} />
          <Route path="/checkout" component={PaymentScreen} />
          <Route path="/receipt/:receiptType/" component={ReceiptScreen} />
          {this.props.userLoggedIn && (
            <Route path="/pipelines" component={Pipelines} />
          )}
          <Route path="/instance/:opType/" component={Credentials} />
          <Route path="/transfer-pipelines" component={TransferPipelines} />
          <Route path="/legal-agreement" component={LegalAgreement} />
          <Route path="/terms-conditions" component={TermsAndConditions} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
        </Switch>
        {noFooterAndNavbar.indexOf(this.props.location.pathname) === -1 && (
          <Footer />
        )}
      </div>
    );
  }
}

const mapStateFromProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentPlan: state.user.currentPlan
});

export default withRouter(connect(mapStateFromProps)(App));
