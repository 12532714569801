import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
// Your web app's Firebase configuration

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBmqNcgfIBvndg9KBf1KEpW6NhYAvg0iK8",
  authDomain: "hub-kuti-io-91549.firebaseapp.com",
  databaseURL: "https://hub-kuti-io-91549.firebaseio.com",
  projectId: "hub-kuti-io-91549",
  storageBucket: "hub-kuti-io-91549.appspot.com",
  messagingSenderId: "497504382449",
  appId: "1:497504382449:web:95f7641e54e1f3e1e92850",
  measurementId: "G-1FFKTNES7H"
};

firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const firestore = firebase.firestore();
const database = firebase.database();

export {
  storage,
  firestore,
  database,
  firebase as default
}
