import React, { Component } from 'react';
import ArtifactListViewItem  from '../ArtifactListViewItem';
import WidgetListViewItem from '../WidgetListViewItem';

class ListView extends Component {
    render() {
        const items = this.props.data.map((viewItem, index)=>{
            if(viewItem.type==='artifact'){
                return <ArtifactListViewItem
                                key={index}
                                artifact={viewItem.artifact}
                                goToDetails={() => this.props.goToDetails(viewItem.artifact, null)} />
            }
            return  <WidgetListViewItem
                            key={index}
                            artifact={viewItem.artifact}
                            artifactItemWidget={viewItem.widget}
                            goToDetails={() => this.props.goToDetails(viewItem.artifact, viewItem.widget)} />
        });

        return (
            <div className="col-lg-12">
                <div className="row">{items}</div>
            </div>
        )
    }
}

export default ListView;