import {
  SHOPPING_CART_FETCH_BEGIN,
  SHOPPING_CART_FETCH_FAIL,
  SHOPPING_CART_FETCH_SUCCESS,
  SHOPPING_CART_ADD_SUCCESS,
  SHOPPING_CART_ADD_FAIL,
  SHOPPING_CART_REMOVE_SUCCESS,
  SHOPPING_CART_REMOVE_FAIL,
  SET_CHECKOUT_PRICE,
  CHARGE_FETCH_BEGIN,
  CHARGE_FETCH_SUCCESS,
  CHARGE_FETCH_FAIL,
  CLEAR_SHOPPING_CART,
  SUBSCRIPTION_FETCH_BEGIN,
  SUBSCRIPTION_FETCH_SUCCESS,
  SUBSCRIPTION_FETCH_FAIL,
  ALREADY_PURCHASED_FETCH_BEGIN,
  ALREADY_PURCHASED_FETCH_SUCCESS,
  ALREADY_PURCHASED_FETCH_FAIL,
  CANCEL_SUBSCRIPTION_BEGIN,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAIL
} from './types';
import {oss, userActions} from './index';
import Properties from "../config/properties";

const shoppingCartFetchBegin = () => ({
  type: SHOPPING_CART_FETCH_BEGIN,
});

const shoppingCartFetchSuccess = (data) => ({
  type: SHOPPING_CART_FETCH_SUCCESS,
  payload: {data},
});

const shoppingCartFetchFail = (error) => ({
  type: SHOPPING_CART_FETCH_FAIL,
  payload: {error},
});

export const fetchShoppingCart = (ids) => {
  return async dispatch => {
    try {
      dispatch(shoppingCartFetchBegin());
      const res = await fetch(`${Properties.API_HOST}shoppingCart`,
                              {
                                method: 'POST',
                                body: JSON.stringify(ids),
                                headers: {'Content-Type': 'application/json'},
                              });
      const json = await res.json();
      dispatch(shoppingCartFetchSuccess(json));
      return json;
    } catch (error) {
      dispatch(shoppingCartFetchFail(error))
    }
  }
};

const addToShoppingCartSuccess = (pluginID) => ({
  type: SHOPPING_CART_ADD_SUCCESS,
  payload: {pluginID}
});

const addToShoppingCartFail = (pluginID) => ({
  type: SHOPPING_CART_ADD_FAIL,
  payload: {
    pluginID,
  }
});

export const addShoppingCart = (pluginID, alreadyPurchased, shoppingCartData) => {
  return dispatch => {
    if (alreadyPurchased.indexOf(pluginID) === -1 && shoppingCartData.indexOf(pluginID) === -1) {
      sessionStorage.setItem(
        'shoppingCartData',
        JSON.stringify(
          [
            ...shoppingCartData,
            pluginID,
          ])
      );
      dispatch(addToShoppingCartSuccess(pluginID));
    } else {
      dispatch(addToShoppingCartFail(pluginID));
    }
  }
};

const removeArticleFromShoppingCartSuccess = (pluginID) => ({
  type: SHOPPING_CART_REMOVE_SUCCESS,
  payload: {pluginID}
});

const removeArticleFromShoppingCartFail = (pluginID) => ({
  type: SHOPPING_CART_REMOVE_FAIL,
  payload: {
    pluginID,
  }
});

export const removeArticleFromShoppingCart = (artifactId, shoppingCartData) => {
  return dispatch => {
    if (shoppingCartData.indexOf(artifactId) !== -1) {
      sessionStorage.setItem(
        'shoppingCartData',
        JSON.stringify(
          [
            ...shoppingCartData.filter((tempId) => artifactId !== tempId),
          ])
      );
      dispatch(removeArticleFromShoppingCartSuccess(artifactId));
    } else {
      dispatch(removeArticleFromShoppingCartFail(artifactId));
    }
  }
};

const beginFetchCharge = () => ({
  type: CHARGE_FETCH_BEGIN,
});

const onFetchChargeSuccess = (chargeRes) => ({
  type: CHARGE_FETCH_SUCCESS,
  payload: {chargeRes},
});

const onFetchChargeFail = (error) => ({
  type: CHARGE_FETCH_FAIL,
  payload: {error},
});

export const fetchCharge = (token, authToken, currentUserId) => {
  return async dispatch => {
    try {
      dispatch(beginFetchCharge());
      const res = await fetch(`${Properties.API_HOST}u/${currentUserId}/charge`,
                              {
                                method: 'POST',
                                body: JSON.stringify(token),
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': authToken,
                                },
                              });
      const response = await res.json();
      dispatch(onFetchChargeSuccess(response));
      dispatch(oss.updatePurchaseStatus(token.shoppingCartArticles));
      dispatch(clearShoppingCart());
      return response;
    } catch (error) {
      return dispatch(onFetchChargeFail(error))
    }

  };
};

const beginFetchSubscription = () => ({
  type: SUBSCRIPTION_FETCH_BEGIN,
});

const onFetchSubscriptionSuccess = (subscriptionRes) => ({
  type: SUBSCRIPTION_FETCH_SUCCESS,
  payload: {subscriptionRes},
});

const onFetchSubscriptionFail = (error) => ({
  type: SUBSCRIPTION_FETCH_FAIL,
  payload: {error},
});

export const fetchSubscription = (token, planName, authToken, currentUserId) => {
  return async dispatch => {
    try {
      dispatch(beginFetchSubscription());
      const res = await fetch(`${Properties.API_HOST}u/${currentUserId}/subscription/${planName}`,
                              {
                                method: 'POST',
                                body: JSON.stringify(token),
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': authToken,
                                },
                              });
      const response = await res.json();
      dispatch(onFetchSubscriptionSuccess(response));
      dispatch(userActions.setUserPlan(planName));
      return response;
    } catch (error) {
      return dispatch(onFetchSubscriptionFail(error))
    }

  };
};

export const setPrice = (price) => ({
  type: SET_CHECKOUT_PRICE,
  payload: {
    price,
  }
});

export const clearShoppingCart = () => ({
  type: CLEAR_SHOPPING_CART,
});

const fetchAlreadyPurchasedBegin = () => ({
  type: ALREADY_PURCHASED_FETCH_BEGIN,
});

const fetchAlreadyPurchasedSuccess = (data) => ({
  type: ALREADY_PURCHASED_FETCH_SUCCESS,
  payload: {data},
});

const fetchAlreadyPurchasedFail = (error) => ({
  type: ALREADY_PURCHASED_FETCH_FAIL,
  payload: {error},
});

export const fetchAlreadyPurchased = (currentUserId, authToken) => {
  return async dispatch => {
    try {
      dispatch(fetchAlreadyPurchasedBegin());
      const res = await fetch(`${Properties.API_HOST}u/${currentUserId}/purchases`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken,
        },
      });
      const response = await res.json();
      dispatch(fetchAlreadyPurchasedSuccess(response));
      return response;
    } catch (error) {
      return dispatch(fetchAlreadyPurchasedFail(error))
    }

  };
};

const beginCancelSubscription = () => ({
  type: CANCEL_SUBSCRIPTION_BEGIN,
});

const cancelSubscriptionSuccess = (subscriptionRes) => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  payload: {subscriptionRes},
});

const cancelSubscriptionFail = (error) => ({
  type: CANCEL_SUBSCRIPTION_FAIL,
  payload: {error},
});

export const cancelSubscription = (authToken, currentUserId) => {
  return async dispatch => {
    try {
      dispatch(beginCancelSubscription());
      const res = await fetch(`${Properties.API_HOST}u/${currentUserId}/cancel-subscription`,
                              {
                                method: 'POST',
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': authToken,
                                },
                              });
      const response = await res.json();
      dispatch(cancelSubscriptionSuccess(response));
      dispatch(userActions.setUserPlan('free'));
      return response;
    } catch (error) {
      return dispatch(cancelSubscriptionFail(error))
    }

  };
};
