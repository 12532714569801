import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ShoppingCartHeader from './ShoppingCartHeader';
import ShoppingCartList from './ShoppingCartList';
import { shoppingCart, details } from '../../actions';
import { connect } from 'react-redux';
import { find, get, isEmpty, orderBy, reduce } from 'lodash';
import { stripPluginSuffixPrefix } from '../../helpers';

class ShoppingCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bottomOfPage: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.props.fetchShoppingCart(this.props.shoppingCartData);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = window.innerHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      this.setState({
        bottomOfPage: true,
      });
    }
  }

  exctractArtifactJsonInfo = (artifact) => {
    const artifactArguments = get(
      artifact,
      'spec_json.actions[0].arguments',
      []
    );

    const findArtifactJsonFileName = find(artifactArguments, {
      name: 'config',
    });
    const artifactJsonFileName = !isEmpty(findArtifactJsonFileName)
      ? findArtifactJsonFileName.value
      : null;

    const findArtifactName = find(artifactArguments, { name: 'name' });
    const artifactName = !isEmpty(findArtifactName)
      ? findArtifactName.value
      : null;

    const findArtifactJarFileName = find(artifactArguments, { name: 'jar' });
    const artifactJarFileName = !isEmpty(findArtifactJarFileName)
      ? findArtifactJarFileName.value
      : null;

    return {
      artifactJsonFileName,
      artifactName,
      artifactJarFileName,
    };
  };

  goToDetails = (selectedArtifact, selectedWidget) => {
    const allArtifactVersions = orderBy(
      this.props.allPlugins.filter(
        (artifact) => artifact.name === selectedArtifact.name
      ),
      'version',
      'desc'
    ).map((artifactVersion) => ({
      ...artifactVersion,
      activeTab: 'plugins',
      activeSubTab: 'cli',
      jsonFileContent: {
        parents: [],
      },
      jsonInfo: this.exctractArtifactJsonInfo(artifactVersion),
      hasDocumentation:
        artifactVersion.docs_file &&
        artifactVersion.docs_file.length === 1 &&
        artifactVersion.docs_file[0].link.startsWith('http'),
    }));
    const widgetName =
      selectedWidget && selectedWidget.name ? selectedWidget.name : null;
    const widgetIndex =
      selectedWidget !== null
        ? selectedArtifact.widgets.findIndex(
            (widget) => widget.name === selectedWidget.name
          )
        : -1;
    const widgetStrippedName =
      widgetIndex !== -1
        ? stripPluginSuffixPrefix(selectedWidget.name).toLowerCase()
        : null;
    this.props.setDetailsArtifact(
      allArtifactVersions,
      0,
      widgetIndex,
      widgetName
    );
    this.props.history.push({
      pathname:
        widgetIndex === -1
          ? `/details/${selectedArtifact.name}/${selectedArtifact.version}/`
          : `/details/${selectedArtifact.name}/${selectedArtifact.version}/${widgetStrippedName}/`,
    });
  };

  totalPrice = () => {
    const price = reduce(
      this.props.shoppingCartArticle.map((item) => parseFloat(item.price)),
      (sum, price) => sum + price
    );
    return price;
  };

  render() {
    if (this.props.isLoading) {
      return (
        <div className="header pt-md-6">
          <div className="container">
            <div className="header-body">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card-stats mb-4 mb-xl-0 pd-10">
                    <div className="card-body bg-white rounder-borders card-body-shadow">
                      <ShoppingCartHeader
                        goBack={() => {
                          this.props.history.goBack();
                        }}
                        componentName={'Shopping Cart'}
                      />
                      <div className="col-lg-12">
                        <div className="card-body">
                          <div className="col-lg-12">
                            <div className="col-lg-12 mgTopBottom border-top pd-10">
                              <div>Loading...</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="header pt-md-6">
        <div className="container">
          <div className="header-body">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="card-stats mb-4 mb-xl-0 pd-10">
                  <div className="card-body bg-white rounder-borders card-body-shadow">
                    <ShoppingCartHeader
                      goBack={() => {
                        this.props.history.goBack();
                      }}
                      componentName="Shopping Cart"
                    />
                    <div className="col-lg-12">
                      <div className="card-body">
                        <div className="col-lg-12 mgTopBottom border-top pd-10">
                          <div className="row">
                            <ShoppingCartList
                              goToAllPlugins={() => {
                                this.props.history.push('/');
                              }}
                              artifact={this.props.shoppingCartArticle}
                              goToDetails={(artifact, widget) => {
                                this.goToDetails(artifact, widget);
                              }}
                            />
                            <div className="col-lg-4">
                              <div className="card-stats mb-4 mb-xl-0 pd-10">
                                <div className="card-body bg-color-shoppingList rounder-borders">
                                  <h2 className="text-center ">
                                    Your Cart Total
                                  </h2>
                                  <h4 className="text-center ">
                                    $
                                    {this.totalPrice() === undefined
                                      ? 0
                                      : this.totalPrice()}
                                    .00
                                  </h4>
                                  <div className="text-center">
                                    <button
                                      className="btn btn-primary"
                                      style={{ marginTop: '20px' }}
                                      onClick={() => {
                                        this.props.setPrice(
                                          this.totalPrice() === undefined
                                            ? 0
                                            : this.totalPrice()
                                        );
                                        this.props.history.push('/checkout');
                                      }}
                                    >
                                      Secure Checkout
                                    </button>
                                    <div
                                      className="smallText-13"
                                      style={{ marginTop: '20px' }}
                                    >
                                      Price displayed excludes sales tax.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.state.bottomOfPage && (
                              <div className="col-lg-8">
                                <div className="card-stats mb-4 mb-xl-0 pd-10">
                                  <div className="card-body bg-color-shoppingList rounder-borders">
                                    <div className="row">
                                      <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                          this.props.setPrice(
                                            this.totalPrice() === undefined
                                              ? 0
                                              : this.totalPrice()
                                          );
                                          this.props.history.push('/checkout');
                                        }}
                                      >
                                        Secure Checkout
                                      </button>
                                      <div className="col-lg-8">
                                        <h2
                                          className="text-right"
                                          style={{ paddingTop: '5px' }}
                                        >{`Total   $${
                                          this.totalPrice() === undefined
                                            ? 0
                                            : this.totalPrice()
                                        }`}</h2>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ShoppingCart.propTypes = {
  currentUser: PropTypes.object,
  authToken: PropTypes.string,
  isUserSet: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  allPlugins: PropTypes.array.isRequired,
  shoppingCartData: PropTypes.array.isRequired,
  shoppingCartArticle: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchShoppingCart: PropTypes.func.isRequired,
  setDetailsArtifact: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  data: state.oss.filteredData,
  allPlugins: state.oss.data,
  shoppingCartData: state.shoppingCart.data,
  shoppingCartArticle: state.shoppingCart.shoppingCartArticles,
  isLoading: state.shoppingCart.isLoading,
  price: state.shoppingCart.price,
  ...ownProps,
});

const mapActionsToProps = {
  fetchShoppingCart: shoppingCart.fetchShoppingCart,
  setDetailsArtifact: details.setDetailsArtifact,
  setPrice: shoppingCart.setPrice,
};
export default connect(mapStateToProps, mapActionsToProps)(ShoppingCart);
