import React, { Component } from 'react';
import assets from '../../assets';
import isEmpty from 'lodash/isEmpty';
import getByKey from 'lodash/get';
import { Properties } from '../../config';

class WidgetListViewItem extends Component {
  state = {
    pluginName: '',
  };
  generateName = () => {
    const { artifactItemWidget } = this.props;
    const pluginTypes = [
      '-action.json',
      '-batchsource.json',
      '-batchsink.json',
      '-splittertransform.json',
      '-streamingsource.json',
      '-transform.json',
      '-postaction.json',
      '-sparksink.json',
      '-sparkcompute.json',
      '-sparkprogram.json',
      '-batchaggregator.json',
      '-batchjoiner.json',
      '-alertpublisher.json',
      '-windower.json',
    ];
    let name = artifactItemWidget.name;
    pluginTypes.forEach((element) => {
      if (artifactItemWidget.name.includes(element)) {
        name = artifactItemWidget.name.replace(element, '');
      }
    });
    return name;
  };

  render() {
    const { artifact } = this.props;
    const { artifactItemWidget } = this.props;
    const openCube = assets.images.openCube;
    let imgSrc = artifactItemWidget.icon_link
      ? artifactItemWidget.icon_link
      : `${Properties.API_HOST}packages/${artifact.name}/${artifact.version}/icon.png`;
    if (
      artifactItemWidget.package_name === 'core-plugins' ||
      artifactItemWidget.package_name === 'transform-plugins'
    ) {
      imgSrc =
        artifactItemWidget.icon_link !== ''
          ? artifactItemWidget.icon_link
          : assets.images.ProEDMS;
      // imgSrc = artifactItemWidget['icon'] && artifactItemWidget['icon'] ? artifactItemWidget['icon']['arguments']['data'] : assets.images.ProEDMS
    }
    const name =
      artifactItemWidget['data'] && artifactItemWidget['data']['display-name']
        ? artifactItemWidget['data']['display-name']
        : this.generateName();
    return (
      <div className="shadow col-lg-12 border mg-bottom-10">
        {artifact.commercial === true && (
          <img
            className="img-commercial-listView"
            src={assets.images.commercialIcon}
            alt="Not found"
          />
        )}
        <div className="col-lg-12 mgTopBottom">
          <button
            className="full-link-list btn-no-bg"
            onClick={() => this.props.goToDetails(artifact, artifactItemWidget)}
          ></button>
          <div className="row">
            <div className="col-lg-2 text-center" style={{ height: '130px' }}>
              <img
                className="card-img-top-list"
                src={imgSrc}
                alt="Not found"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = assets.images.ProEDMS;
                }}
                style={{ width: '90px', height: '90px' }}
              />
            </div>
            <div className="col-lg-10">
              <div className="row">
                <h4
                  className="col-lg-4"
                  style={{ maxWidth: '35%', flexBasis: '45%' }}
                >
                  {!isEmpty(name)
                    ? name
                    : getByKey(
                        artifactItemWidget.data,
                        'configuration-groups.0.label',
                        'Missing name'
                      )}
                </h4>
                <div className="version-listView smallText-13">
                {artifactItemWidget && (
                    <img className="pull-right" src={openCube} alt="" />
                  )}
                  <label className="pull-right mgLeft10" style={{ marginTop: '6px' }}>
                    <i className="fa fa-code-fork fa-lg" /> {artifact.version}
                  </label>
                </div>
              </div>
              <p className="block-with-text list-view-description">
                {artifact.description}
              </p>
              <div
                className="row"
                style={{
                  position: 'absolute',
                  bottom: '-13px',
                  marginLeft: '3px',
                }}
              >
                <div className="smallText-13">
                  <label className="mgLeft-10">
                    <i
                      className="fa fa-info-circle"
                      style={{ color: 'gray' }}
                    />{' '}
                    {artifactItemWidget.pluginType}
                  </label>
                  <label className="mgLeft10">
                    <i
                      className="fa fa-balance-scale"
                      style={{ color: 'gray' }}
                    />
                    {artifact.license}
                  </label>
                  {artifact.userId ? (
                    <label className="mgLeft10">
                      <i className="fa fa-link" /> Uploaded{' '}
                    </label>
                  ) : (
                    <label className="mgLeft10">
                      <i className="fa fa-github github" />
                      <a
                        href={artifactItemWidget.html_url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {artifactItemWidget.orgName}
                      </a>
                    </label>
                  )}
                  {artifact.tags && (
                    <label className="mgLeft10">
                      <i className="fa fa-tags" />
                      {artifact.tags.join(', ')}
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WidgetListViewItem;
