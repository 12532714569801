
import React, { Component } from "react";
// import { connect } from "react-redux";

class ControlBaner extends Component {
    render() {
        return (
            <div className="row" style={{ margin: "37px 0px" }}>
                <i className="fa fa-filter" style={{ color: "gray" }}></i>
                <label>Cdap Version:</label>
                <div className="col-lg-3">
                    <select name="a" id="version_mode_1" className="col-lg-10 select-style-change"
                        defaultValue={this.state.version}
                        onChange={this.filterDataVersions}>
                        <option key="All versions:">All versions:</option>
                        {this.state.dataVersions.map((element, index) =>
                            <option key={element} selected={this.state.version === element}>{element}</option>)
                        }
                    </select>
                </div>
                <label>Type:</label>
                <div className="col-lg-3">
                    <select name="b" id="version_mode_2" className="col-lg-10 select-style-change"
                        defaultValue={this.state.pluginType}
                        onChange={this.filterByPluginType}>
                        {this.state.dataPluginTypes.map((element, index) =>
                            <option key={index} selected={this.state.pluginType === element}>{element}</option>)
                        }
                    </select>
                </div>
                <div className="col-lg-3 text-right">
                    <label className="pd-right-label">Show Plugin Bundles</label>
                    <label className="switch" >
                        <input type="checkbox" onChange={this.toggleHeandler} checked={this.state.bundle} /><span className="slider round"></span>
                    </label>
                </div>
                <label style={{ paddingLeft: "30px", fontSize: "16px" }} onClick={this.goToListView}>
                    <i className={`fa ${this.props.activeViewType === 'list' ? 'fa-list' : 'fa-th'} button-click`} style={{ color: "gray", fonts: "21px", cursor: "pointer" }}></i>
                </label>
            </div>
        )
    }
}

// export default connect(mapStateToProps, { fetchMyPlugins, resetDetailsData })(ControlBaner);
export default ControlBaner;

