import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { oss, details } from '../../actions';
import { orderBy, find, get, isEmpty } from 'lodash';
import { stripPluginSuffixPrefix } from '../../helpers';
import { ListView, CardView } from '../../components';

class CommercialPlugins extends Component {
  goToDetails = (selectedArtifact, selectedWidget) => {
    const allArtifactVersions = orderBy(
      this.props.allPlugins.filter(
        (artifact) => artifact.name === selectedArtifact.name
      ),
      'version',
      'desc'
    ).map((artifactVersion) => ({
      ...artifactVersion,
      activeTab: 'plugins',
      activeSubTab: 'cli',
      jsonFileContent: {
        parents: [],
      },
      jsonInfo: this.exctractArtifactJsonInfo(artifactVersion),
      hasDocumentation:
        artifactVersion.docs_file &&
        artifactVersion.docs_file.length === 1 &&
        artifactVersion.docs_file[0].link.startsWith('http'),
    }));
    const artifactIndex = allArtifactVersions.findIndex(
      (artifact) => artifact.version === selectedArtifact.version
    );
    const widgetName =
      selectedWidget && selectedWidget.name ? selectedWidget.name : null;
    const widgetIndex =
      selectedWidget !== null
        ? selectedArtifact.widgets.findIndex(
            (widget) => widget.name === selectedWidget.name
          )
        : -1;
    const widgetStrippedName =
      widgetIndex !== -1
        ? stripPluginSuffixPrefix(selectedWidget.name).toLowerCase()
        : null;
    this.props.setDetailsArtifact(
      allArtifactVersions,
      artifactIndex,
      widgetIndex,
      widgetName
    );
    this.props.history.push({
      pathname:
        widgetIndex === -1
          ? `/details/${selectedArtifact.name}/${selectedArtifact.version}/`
          : `/details/${selectedArtifact.name}/${selectedArtifact.version}/${widgetStrippedName}/`,
    });
  };

  exctractArtifactJsonInfo = (artifact) => {
    const artifactArguments = get(
      artifact,
      'spec_json.actions[0].arguments',
      []
    );
    const findArtifactJsonFileName = find(artifactArguments, {
      name: 'config',
    });
    const artifactJsonFileName = !isEmpty(findArtifactJsonFileName)
      ? findArtifactJsonFileName.value
      : null;

    const findArtifactName = find(artifactArguments, { name: 'name' });
    const artifactName = !isEmpty(findArtifactName)
      ? findArtifactName.value
      : null;

    const findArtifactJarFileName = find(artifactArguments, { name: 'jar' });
    const artifactJarFileName = !isEmpty(findArtifactJarFileName)
      ? findArtifactJarFileName.value
      : null;

    return {
      artifactJsonFileName,
      artifactName,
      artifactJarFileName,
    };
  };

  calculateItemsToRender = () => {
    let itemsToRender = [];
    this.props.data.forEach((artifactItem, index) => {
      const artifactIsSingleton =
        !artifactItem.widgets || artifactItem.widgets.length === 1;
      const artifactIsBundle = !artifactIsSingleton;
      const renderArtifact =
        artifactIsSingleton ||
        this.props.filters.showBundles === artifactIsBundle;
      const renderWidgets =
        artifactItem.widgets && artifactItem.widgets.length > 1;
      if (renderArtifact) {
        itemsToRender.push({
          type: 'artifact',
          artifact: artifactItem,
          widget: null,
        });
      }
      if (renderWidgets) {
        artifactItem.widgets.forEach((artifactItemWidget, widgetIndex) => {
          itemsToRender.push({
            type: 'widget',
            artifact: artifactItem,
            widget: artifactItemWidget,
          });
        });
      }
    });
    return itemsToRender;
  };

  render() {
    const { data, activeViewType } = this.props;
    const itemsToRender = this.calculateItemsToRender();
    this.props.setItemsCount(itemsToRender.length);
    return (
      <div className="col-lg-12">
        <div className="row">
          {data && (
            <div className="container">
              <div className="header-body">
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <div className="card-stats mb-4 mb-xl-0 pd-10 bg-white col-lg-12">
                      {activeViewType === 'card' ? (
                        <div className="row">
                          <CardView
                            data={itemsToRender}
                            filters={this.props.filters}
                            goToDetails={(artifact, widget) =>
                              this.goToDetails(artifact, widget)
                            }
                          />
                        </div>
                      ) : (
                        <div className="row">
                          <ListView
                            data={itemsToRender}
                            filters={this.props.filters}
                            goToDetails={(artifact, widget) =>
                              this.goToDetails(artifact, widget)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

CommercialPlugins.propTypes = {
  data: PropTypes.array,
  activeViewType: PropTypes.string.isRequired,
  goToDetails: PropTypes.func.isRequired,
  setItemsCount: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  allPlugins: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  setDetailsArtifact: PropTypes.func.isRequired,
};

const mapStateFromProps = (state) => ({
  itemsCount: state.oss.itemsCount,
  commercialData: state.oss.commercialData,
  data: state.oss.commercialFilteredData,
  filters: state.oss.filters,
  allPlugins: state.oss.data,
});

const mapActionsToProps = {
  setItemsCount: oss.setItemsCount,
  setDetailsArtifact: details.setDetailsArtifact,
};

export default withRouter(
  connect(mapStateFromProps, mapActionsToProps)(CommercialPlugins)
);
