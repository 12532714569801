import React, {Component} from "react";
import {connect} from "react-redux";
import { activeState } from '../../../actions';


class ShoppingCartHeader extends Component {
    render() {
        return (
            <div className="row" style={{marginTop: "20px"}}>
                <div className="col row" style={{marginLeft:"30px"}}>
                    <button className='btn-no-bg'>
                        <i className="fa fa-arrow-left button-click" onClick={() => this.props.goBack()}
                           style={{color: "gray", cursor: 'pointer'}}/>
                    </button>
                    <h4 style={{margin: "2px 0 0 16px"}}>{this.props.componentName}</h4>
                </div>
                {/* <div className="col-lg-5">
                    <h4 style={{margin: "9px 0 0 16px"}}>{this.props.componentName}</h4>
                </div> */}
            </div>
          // </div>
        );
    };
}

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.currentUser,
    authToken: state.user.authToken,
    isUserSet: state.user.isUserSet,
    activeTabPipeline: state.activeState.activeTabPipeline,
    ...ownProps,
});
const mapActionsToProps = {
    setActiveTabPipeline: activeState.setActiveTabPipeline,
}
export default connect(mapStateToProps, mapActionsToProps)(ShoppingCartHeader);
