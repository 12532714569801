import React, { Component } from 'react';
import { details } from '../../actions';
import { connect } from 'react-redux';
import { find, get, isEmpty, orderBy } from 'lodash';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { storage } from '../../firebase';
import { withRouter } from 'react-router-dom';
import DetailsHeader from './DetailsHeader';
import ArtifactDescription from './ArtifactDescription';
import DetailsArtifacts from './DetailsArtifacts';
import ArtifactDocumentation from './ArtifactDocumentation';
import WidgetDescription from './WidgetDescription';
import WidgetDocumentation from './WidgetDocumentation';
import NoExtension from './NoExtension';
import {
  stripPluginSuffixPrefix,
  mapWidgetNamesToWigetRoot,
} from '../../helpers';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

class Details extends Component {
  navTabChange = (event, value) => {
    this.props.setActiveArtifactTab(value);
  };

  exctractArtifactJsonInfo = (artifact) => {
    const artifactArguments = get(
      artifact,
      'spec_json.actions[0].arguments',
      []
    );

    const findArtifactJsonFileName = find(artifactArguments, {
      name: 'config',
    });
    const artifactJsonFileName = !isEmpty(findArtifactJsonFileName)
      ? findArtifactJsonFileName.value
      : null;

    const findArtifactName = find(artifactArguments, { name: 'name' });
    const artifactName = !isEmpty(findArtifactName)
      ? findArtifactName.value
      : null;

    const findArtifactJarFileName = find(artifactArguments, { name: 'jar' });
    const artifactJarFileName = !isEmpty(findArtifactJarFileName)
      ? findArtifactJarFileName.value
      : null;

    return {
      artifactJsonFileName,
      artifactName,
      artifactJarFileName,
    };
  };

  componentDidUpdate = () => {
    const dataToManipulate = this.props.activeTab === 'myPlugins' ? this.props.myPlugins : this.props.allOss;
    if (dataToManipulate.length > 0 && this.props.data.length === 0) {
      const { widgetName } = this.props.match.params;
      const { artifactName, artifactVersion } = this.props.match.params;
      const allArtifactVersions = orderBy(
        dataToManipulate.filter((artifact) => artifact.name === artifactName),
        'version',
        'desc'
      ).map((artifactVersion) => ({
        ...artifactVersion,
        activeTab: 'plugins',
        activeSubTab: 'cli',
        jsonFileContent: {
          parents: [],
        },
        jsonInfo: this.exctractArtifactJsonInfo(artifactVersion),
        hasDocumentation:
          artifactVersion.docs_file.length === 1 &&
          artifactVersion.docs_file[0].link.startsWith('http'),
        widgets: artifactVersion.widgets
          ? mapWidgetNamesToWigetRoot(artifactVersion.widgets)
          : null,
        // jsonFile: !isEmpty(artifactVersion.userId)?artifactVersion.jsonFile:this.exctractArtifactJsonName(artifactVersion),
      }));
      const versionIndex = allArtifactVersions.findIndex(
        (artifact) => artifact.version === artifactVersion
      );
      const widgetIndex = widgetName
        ? allArtifactVersions[versionIndex].widgets.findIndex((widget) => {
            const strippedWidgetName = stripPluginSuffixPrefix(
              widget.name
            ).toLowerCase();
            return strippedWidgetName === widgetName;
          })
        : -1;
      const selectedWidgetName =
        widgetIndex !== -1
          ? allArtifactVersions[versionIndex].widgets[widgetIndex].name
          : null;
      this.props.setDetailsArtifact(
        allArtifactVersions,
        versionIndex,
        widgetIndex,
        selectedWidgetName
      );
    }
  };

  toggleModal = () => {
    this.setState({
      modaleIsOpen: !this.state.modaleIsOpen,
    });
  };

  popUpViewer = () => {
    const artifact = this.props.data[this.props.selectedIndex];
    return (
      <div>
        <Modal isOpen={this.state.modaleIsOpen}>
          <ModalHeader toggle={this.toggleModal}>Alert</ModalHeader>
          <ModalBody>{`This file ${artifact.name}-${artifact.version} is not available.`}</ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    );
  };

  mdFileHandler = async (link) => {
    let secureLink = link;
    const artifact = this.props.data[this.props.selectedIndex];
    if (artifact.userId !== '') {
      secureLink = await storage.refFromURL(link).getDownloadURL();
    }
    const res = await fetch(secureLink);
    const text = await res.text();
    this.setState({
      mdFile: !this.state.mdFile,
      currentMdFile: text,
    });
  };

  renderMdFiles = () => {
    const artifact = this.props.data[this.props.selectedIndex];
    return (
      <div style={{ marginTop: '20px' }}>
        {artifact.docs_file &&
          artifact.docs_file.length > 1 &&
          artifact.docs_file.map((element, index) => (
            <p
              className="p-blue"
              key={index}
              onClick={() => this.mdFileHandler(element.link)}
              style={{ cursor: 'pointer', alignSelf: 'center' }}
            >
              {element.name}
            </p>
          ))}
      </div>
    );
  };

  documentationHeandler = () => {
    this.setState({
      renderDocumentation: true,
      renderArtifacts: false,
      documentationTextColor: true,
      artifactTexColor: false,
    });
  };

  pluginArtifactHeandler = () => {
    this.setState({
      renderArtifacts: true,
      artifactTexColor: true,
      renderDocumentation: false,
      documentationTextColor: false,
    });
  };

  componentWillUnmount() {
    this.props.clearDetails();
  }

  widgetObj = (artifact) => {
    let widgetObj = find(artifact.widgets, { name: this.props.widgetName });
    return widgetObj !== undefined ? widgetObj : "noExtension";
  };

  render() {
    const { isOssLoading } = this.props;
    const artifact = this.props.data[this.props.selectedIndex];
    const widget =
      this.props.widgetIndex !== -1 ? this.widgetObj(artifact) : null;
    // const widget =
    //   this.props.widgetIndex !== -1
    //     ? artifact.widgets[this.props.widgetIndex]
    //     : null;
    const versions = this.props.data.map((artifact) => artifact.version);
    if (isOssLoading || this.props.data.length === 0) {
      return (
        <div className="header pt-md-6">
          <div className="container">
            <div className="header-body">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card-stats mb-4 mb-xl-0 pd-10">
                    <div className="card-body bg-white rounder-borders card-body-shadow">
                      <DetailsHeader
                        data={this.props.data}
                        artifact={artifact}
                        versions={versions}
                      />
                      <div className="row">
                        <div className="bg-white col-lg-12">
                          <div className="col-lg-12">
                            <div className="card-body">
                              <div className="col-lg-12">
                                <div className="col-lg-12 mgTopBottom border-top pd-10">
                                  <div>Loading details</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="header pt-md-6">
        <div className="container">
          <div className="header-body">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="card-stats mb-4 mb-xl-0 pd-10">
                  <div className="card-body bg-white rounder-borders card-body-shadow">
                    <DetailsHeader
                      data={this.props.data}
                      artifact={artifact}
                      versions={versions}
                    />
                    <div className="card-body">
                      <div className="col-lg-12">
                        <div className="mgTopBottom border-top pd-10">
                          <div className="">
                            {widget === null && (
                              <ArtifactDescription
                                artifact={artifact}
                                versions={versions}
                                data={this.props.data}
                                setSelectedArtifactIndex={(index) =>
                                  this.props.setSelectedArtifactIndex(index)
                                }
                              />
                            )}
                            {widget !== null && widget !== 'noExtension' && (
                              <WidgetDescription
                                widget={widget}
                                artifact={artifact}
                                versions={versions}
                                data={this.props.data}
                                setSelectedArtifactIndex={(index) =>
                                  this.props.setSelectedArtifactIndex(index)
                                }
                              />
                            )}
                            {widget === 'noExtension' && (
                              <NoExtension
                                artifact={artifact}
                                versions={versions}
                                data={this.props.data}
                                setSelectedArtifactIndex={(index) =>
                                  this.props.setSelectedArtifactIndex(index)
                                }
                              />
                            )}
                            <div className="row">
                              <div className="col-lg-2"></div>
                              <div className="col-lg-10">
                                <div className="">
                                  <Paper className="paper-nav-tabs">
                                    <Tabs
                                      value={artifact.activeTab}
                                      indicatorColor="primary"
                                      textColor="primary"
                                      onChange={(event, value) => {
                                        this.navTabChange(event, value);
                                      }}
                                    >
                                      <Tab
                                        label="Plugin Artifacts"
                                        value="plugins"
                                      />
                                      <Tab
                                        disabled ={widget === 'noExtension'}
                                        label="Documentation"
                                        value="documentation"
                                      />
                                    </Tabs>
                                  </Paper>
                                  {artifact.activeTab === 'documentation' &&
                                    (widget === null ? (
                                      <ArtifactDocumentation
                                        artifact={artifact}
                                      />
                                    ) : (
                                      <WidgetDocumentation
                                        artifact={artifact}
                                        widget={widget}
                                      />
                                    ))}
                                  <div className="row">
                                    <div className="smallText-13"></div>
                                  </div>
                                  <ol />
                                  {artifact.activeTab === 'plugins' && (
                                    <div>
                                      <DetailsArtifacts
                                        artifact={artifact}
                                        widget={widget}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="row col-lg-12">
                                  <div className="smallText-13 pd-40">
                                    <label className="mgLeft-10">
                                      <i
                                        className="fa fa-info-circle"
                                        style={{ color: 'gray' }}
                                      />{' '}
                                      {artifact.widgets &&
                                        (artifact.widgets.length > 1
                                          ? 'Bundle'
                                          : artifact.widgets[0]['pluginType'])}
                                    </label>
                                    <label className="mgLeft10">
                                      <i
                                        className="fa fa-balance-scale"
                                        style={{ color: 'gray' }}
                                      />
                                      {artifact.License
                                        ? artifact.License
                                        : artifact.license}
                                    </label>
                                    {artifact.userId ? (
                                      <label className="mgLeft10">
                                        <i className="fa fa-link" /> Uploaded
                                      </label>
                                    ) : (
                                      <label className="mgLeft10">
                                        <i className="fa fa-github github" />
                                        <a
                                          href={
                                            artifact.widgets &&
                                            artifact.widgets[0].html_url
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {artifact.widgets &&
                                            artifact.widgets[0].orgName}
                                        </a>
                                      </label>
                                    )}
                                    {artifact.tags && (
                                      <label className="mgLeft10">
                                        <i className="fa fa-tags" />
                                        {artifact.tags.join(', ')}
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.details.isLoading,
  isOssLoading: state.oss.isLoading,
  data: state.details.data,
  selectedIndex: state.details.selectedIndex,
  widgetIndex: state.details.widgetIndex,
  allOss: state.oss.data,
  jsonFile: state.details.jsonData,
  packages: state.oss.data,
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  widgetName: state.details.widgetName,
  activeTab: state.activeState.activeTab,
  myPlugins: state.myPlugins.data,
});

const mapActionsToProps = {
  fetchJsonFile: details.fetchJsonFile,
  fetchMyPluginJson: details.fetchMyPluginJson,
  setDetailsArtifact: details.setDetailsArtifact,
  setSelectedArtifactIndex: details.setSelectedArtifactIndex,
  setActiveArtifactTab: details.setArtifactTab,
  setActiveArtifactSubTab: details.setArtifactSubTab,
  clearDetails: details.clearDetails,
  setWidgetIndex: details.setWidgetIndex,
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Details));
