import React, { Component } from 'react';
import { connect } from 'react-redux';
import ArtifactCardViewItem  from '../ArtifactCardViewItem';
import WidgetCardViewItem from '../WidgetCardViewItem';
import EditPlugin from '../EditPlugin';
import DeletePlugin from '../DeletePlugin';


class CardView extends Component {

    render() {
        const items = this.props.data.map((viewItem, index)=>{
            if(viewItem.type==='artifact'){
                return <ArtifactCardViewItem
                                key={index}
                                artifact={viewItem.artifact}
                                goToDetails={() => this.props.goToDetails(viewItem.artifact, null)} />
            }
            return  <WidgetCardViewItem
                            key={index}
                            artifact={viewItem.artifact}
                            artifactItemWidget={viewItem.widget}
                            goToDetails={() => this.props.goToDetails(viewItem.artifact, viewItem.widget)} />
        });
        return (
            <div className="col-lg-12">
                <div className="row">{items}</div>
                {this.props.editPluginPopup && <EditPlugin/>}
                {this.props.deletePluginPopup && <DeletePlugin/>}
            </div>
        )
    }
}



const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.currentUser,
    authToken: state.user.authToken,
    isUserSet: state.user.isUserSet,
    activeTab: state.activeState.activeTab,
    editPluginPopup: state.myPlugins.editPluginPopup,
    deletePluginPopup: state.myPlugins.deletePluginPopup,
    ...ownProps,
  });

  export default connect(mapStateToProps)(CardView);