import * as actionTypes from "../actions/types";
const initialShoppingCartState = {
    error: null,
    price: null,
    chargeRes:null,
    subscriptionRes:null,
    data: [],
    shoppingCartArticles: [],
    alreadyPurchased: [],
    isLoading: true,
};

export default (state = {
    ...initialShoppingCartState,
    data: sessionStorage.getItem('shoppingCartData')!==null?JSON.parse(sessionStorage.getItem('shoppingCartData')):[],
}, action) => {
    switch (action.type) {
        case actionTypes.SHOPPING_CART_FETCH_BEGIN:
            console.debug(actionTypes.SHOPPING_CART_FETCH_BEGIN);
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.SHOPPING_CART_FETCH_FAIL:
            console.debug(actionTypes.SHOPPING_CART_FETCH_FAIL);
            return {
                ...state
            };
        case actionTypes.SHOPPING_CART_FETCH_SUCCESS:
            console.debug(actionTypes.SHOPPING_CART_FETCH_SUCCESS);
            return {
                ...state,
                shoppingCartArticles: action.payload.data,
                isLoading: false
            };
        case actionTypes.SHOPPING_CART_ADD_FAIL:
            console.debug(actionTypes.SHOPPING_CART_ADD_FAIL);
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.SHOPPING_CART_ADD_SUCCESS:
            state.data.push(action.payload.pluginID);
            console.debug(actionTypes.SHOPPING_CART_ADD_SUCCESS);
            return {
                ...state,
                data:[...state.data],
                isLoading: false
            };
        case actionTypes.SHOPPING_CART_REMOVE_SUCCESS:
            state.data.splice(state.data.indexOf(action.payload.pluginID), 1);
            console.debug(actionTypes.SHOPPING_CART_REMOVE_SUCCESS);
            return {
                ...state,
                data: [...state.data],
                isLoading: false
            };
        case actionTypes.SHOPPING_CART_REMOVE_FAIL:
            console.debug(actionTypes.SHOPPING_CART_REMOVE_FAIL);
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.CHARGE_FETCH_BEGIN:
            console.debug(actionTypes.CHARGE_FETCH_BEGIN);
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.CHARGE_FETCH_SUCCESS:
            console.debug(actionTypes.CHARGE_FETCH_SUCCESS, action);
            return {
                ...state,
                error: null,
                chargeRes: action.payload.chargeRes,
                isLoading: false
            };
        case actionTypes.CHARGE_FETCH_FAIL:
            console.debug(actionTypes.CHARGE_FETCH_FAIL);
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case actionTypes.SET_CHECKOUT_PRICE:
            console.debug(actionTypes.SET_CHECKOUT_PRICE);
            return {
                ...state,
                price: action.payload.price,
                isLoading: false
            };
        case actionTypes.CLEAR_SHOPPING_CART:
            console.debug(actionTypes.CLEAR_SHOPPING_CART);
            sessionStorage.removeItem('shoppingCartData');
            return{
                ...state,
                data: [],
                shoppingCartArticles: [],
                price: null,
                error: null,
            };
            case actionTypes.SUBSCRIPTION_FETCH_BEGIN:
                console.debug(actionTypes.SUBSCRIPTION_FETCH_BEGIN);
                return {
                    ...state,
                    isLoading: true
                };
            case actionTypes.SUBSCRIPTION_FETCH_SUCCESS:
                console.debug(actionTypes.SUBSCRIPTION_FETCH_SUCCESS);
                return {
                    ...state,
                    error: null,
                    subscriptionRes: action.payload.subscriptionRes,
                    isLoading: false
                };
            case actionTypes.SUBSCRIPTION_FETCH_FAIL:
                console.debug(actionTypes.SUBSCRIPTION_FETCH_FAIL);
                return {
                    ...state,
                    error: action.payload.error,
                    isLoading: false
                };
                case actionTypes.ALREADY_PURCHASED_FETCH_BEGIN:
                    console.debug(actionTypes.ALREADY_PURCHASED_FETCH_BEGIN);
                    return {
                        ...state,
                        isLoading: true
                    };
                case actionTypes.ALREADY_PURCHASED_FETCH_SUCCESS:
                    console.debug(actionTypes.ALREADY_PURCHASED_FETCH_SUCCESS);
                    return {
                        ...state,
                        error: null,
                        alreadyPurchased: action.payload.data,
                        isLoading: false
                    };
                case actionTypes.ALREADY_PURCHASED_FETCH_FAIL:
                    console.debug(actionTypes.ALREADY_PURCHASED_FETCH_FAIL);
                    return {
                        ...state,
                        error: action.payload.error,
                        isLoading: false
                    };
                case actionTypes.CANCEL_SUBSCRIPTION_BEGIN:
                    console.debug(actionTypes.CANCEL_SUBSCRIPTION_BEGIN);
                    return {
                        ...state,
                        isLoading: true
                    };
                case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
                    console.debug(actionTypes.CANCEL_SUBSCRIPTION_SUCCESS);
                    return {
                        ...state,
                        error: null,
                        subscriptionRes: action.payload.subscriptionRes,
                        isLoading: false
                    };
                case actionTypes.CANCEL_SUBSCRIPTION_FAIL:
                    console.debug(actionTypes.CANCEL_SUBSCRIPTION_FAIL);
                    return {
                        ...state,
                        error: action.payload.error,
                        isLoading: false
                    };
        default:
            return state;
    }
}
