import React, {Component} from "react";
import PropTypes from 'prop-types';
import assets from "../../assets";
import {
    Properties,
} from '../../config';
import {shoppingCart} from "../../actions";
import {connect} from "react-redux";

class ShoppingCartArticle extends Component {

    removeArticle = () => {
        this.props.removeArticleFromShoppingCart(this.props.artifact.uniqueId, this.props.shoppingCartData);
        this.props.fetchShoppingCart(this.props.shoppingCartData);
    };

    render() {
        const {artifact} = this.props;
        const {authToken, isUserSet, currentUser} = this.props;
        const uid = currentUser ? currentUser.uid : null;
        const imgSrc = (isUserSet && currentUser) ? `${Properties.API_HOST}u/${uid}/s/${authToken}/packages/${artifact.name}/${artifact.version}/icon.png` : `${Properties.API_HOST}packages/${artifact.name}/${artifact.version}/icon.png`;
        return (
            <div className="shadow col-lg-12 border mg-bottom-10 ">
                <div className="text-right">
                    <button className="btn btn-gray-shoppingCart mg-remove-btn"
                    onClick={()=>{this.removeArticle()}}>x</button>
                </div>
                <div className="col-lg-12 mgTopBottom">
                    <button className='full-link-shopping-list btn-no-bg'
                            onClick={() => this.props.goToDetails(artifact, null)}>
                    </button>
                    <div className="row">
                        <div className="col-lg-2 text-center" style={{height: "100px"}}>
                            <img className="card-img-top-list" src={imgSrc}
                                 alt='Not found'
                                 onError={(e) => {
                                     e.target.onerror = null;
                                     e.target.src = assets.images.ProEDMS
                                 }}
                                 style={{width: "90px", height: "90px"}}/>
                        </div>
                        <div className="col-lg-9" style={{padding: "0 0 0 0", marginLeft: "15px"}}>
                            <h4 className="text-center mg-30">{artifact.converted_name}</h4>
                            <div className="text-right font-bold-price">${artifact.price}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ShoppingCartArticle.propTypes = {
    currentUser: PropTypes.object.isRequired,
    authToken: PropTypes.string.isRequired,
    isUserSet: PropTypes.bool.isRequired,
    shoppingCartData: PropTypes.array.isRequired,
    fetchShoppingCart: PropTypes.func.isRequired,
    removeArticleFromShoppingCart: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.currentUser,
    authToken: state.user.authToken,
    isUserSet: state.user.isUserSet,
    shoppingCartData: state.shoppingCart.data,
    ...ownProps,
});
const mapActionsToProps = {
    removeArticleFromShoppingCart: shoppingCart.removeArticleFromShoppingCart,
    fetchShoppingCart: shoppingCart.fetchShoppingCart,
};

export default connect(mapStateToProps, mapActionsToProps)(ShoppingCartArticle);
