import * as actionTypes from "../actions/types";
import {
    set as setIn,
} from 'lodash/fp';

const initialDetailsState = {
    error: null,
    data: [],
    jsonData: null,
    isLoading: false,
    selectedIndex: 0,
    activeMDIndex: 0,
    widgetIndex: -1,
    widgetName: null,
};
export default (state = initialDetailsState, action) => {
    switch (action.type) {
        case actionTypes.BEGIN_DETAILS_FETCH: {
            console.debug(actionTypes.BEGIN_DETAILS_FETCH);
            return {
                ...state,
                isLoading: true,
            };
        }
        case actionTypes.JSON_FETCH_SUCCESS: {
            console.debug(actionTypes.JSON_FETCH_SUCCESS);
            return{
                ...state,
                error: null,
                isLoading: false,
                jsonData: action.payload.json,
            }
        }
        case actionTypes.DETAILS_FETCH_FAIL: {
            console.debug(actionTypes.DETAILS_FETCH_FAIL);
            return {
                ...state,
                error: action.payload.error
            }
        }
        case actionTypes.SET_DETAILS_ARTIFACT: {
            console.debug(actionTypes.SET_DETAILS_ARTIFACT, action);
            return{
                ...state,
                data: action.payload.artifact,
                selectedIndex: action.payload.selectedIndex,
                widgetIndex: action.payload.widgetIndex,
                widgetName: action.payload.widgetName,
            }
        }
        case actionTypes.SET_WIDGET_NAME: {
            console.debug(actionTypes.SET_DETAILS_ARTIFACT, action);
            return{
                ...state,
                widgetName: action.payload.widgetName,
            }
        }
        case actionTypes.SET_DETAILS_SELECTED_INDEX: {
            console.debug(actionTypes.SET_DETAILS_SELECTED_INDEX, action);
            return {
                ...state,
                selectedIndex: action.payload.selectedIndex,
            }
        }
        case actionTypes.SET_DETAILS_ARTIFACT_TAB: {
            console.debug(actionTypes.SET_DETAILS_ARTIFACT_TAB, action);
            const data = setIn(`${state.selectedIndex}.activeTab`, action.payload.tabName, state.data);
            return {
                ...state,
                data,
            }
        }
        case actionTypes.SET_DETAILS_ARTIFACT_SUB_TAB: {
            console.debug(actionTypes.SET_DETAILS_ARTIFACT_SUB_TAB, action);
            const data = setIn(`${state.selectedIndex}.activeSubTab`, action.payload.subTabName, state.data);
            return {
                ...state,
                data,
            }
        }
        case actionTypes.SET_DETAILS_ARTIFACT_JSON_FILE_CONTENT: {
            console.debug(actionTypes.SET_DETAILS_ARTIFACT_JSON_FILE_CONTENT, action);
            const data = setIn(`${state.selectedIndex}.jsonFileContent`, action.payload.jsonContent, state.data);
            return {
                ...state,
                data,
            }
        }
        case actionTypes.SET_DETAILS_ARTIFACT_DOC: {
            console.debug(actionTypes.SET_DETAILS_ARTIFACT_DOC, action);
            const data = setIn(`${state.selectedIndex}.docs_file[${action.payload.selectedDocIndex}].content`, action.payload.documentData, state.data);
            return {
                ...state,
                activeMDIndex: action.payload.selectedDocIndex,
                data,
            }
        }
        case actionTypes.SET_DETAILS_WIDGET_DOC: {
            const documentData = setIn(
              `doc_file.content`,
              action.payload.documentData,
              state.data[state.selectedIndex].widgets.find(
                (element) => element.name === state.widgetName
              )
            );
            console.debug(actionTypes.SET_DETAILS_WIDGET_DOC, action);
            const data = setIn(`${state.selectedIndex}.widgets[${state.widgetIndex}]`, documentData, state.data);
            return {
                ...state,
                data,
            }
        }
        case actionTypes.SET_DETAILS_WIDGET_INDEX: {
            console.debug(actionTypes.SET_DETAILS_WIDGET_INDEX, action);
            return {
                ...state,
                widgetIndex: action.payload.widgetIndex,
            }
        }
        case actionTypes.CLEAR_DETAILS: {
            console.debug(actionTypes.CLEAR_DETAILS, action);
            return {
                ...initialDetailsState,
            }
        }
        default:
            return state;
    }
}