import {
  BEGIN_MYPLUGINS_FETCH,
  MYPLUGINS_FETCH_SUCCESS,
  MYPLUGINS_FETCH_FAIL,
  CHANGE_MY_PLUGIN_STATE,
  UPLOAD_PLUGIN_BEGIN,
  UPLOAD_PLUGIN_SUCCESS,
  UPLOAD_PLUGIN_FAIL,
  SET_TOGGAL_MODAL,
  UPLOAD_PLUGIN_PERCENTAGE_UPDATE,
  EDIT_POPUP_HANDLER,
  DELETE_POPUP_HANDLER,
  EDIT_PLUGIN_SUCCESS,
  EDIT_PLUGIN_BEGIN,
  EDIT_PLUGIN_FAIL,
  DELETE_PLUGIN_BEGIN,
  DELETE_PLUGIN_SUCCESS,
  DELETE_PLUGIN_FAIL,
} from './types';
import { Properties } from '../config';
import { oss } from './index';
import axios from 'axios';

const beginMyPLuginsFetch = () => ({
  type: BEGIN_MYPLUGINS_FETCH,
});

const myPluginsFetchSuccess = (data) => ({
  type: MYPLUGINS_FETCH_SUCCESS,
  payload: { data },
});

const myPluginsFetchFail = (error) => ({
  type: MYPLUGINS_FETCH_FAIL,
  payload: { error },
});

export const fetchMyPlugins = (uid) => {
  return async (dispatch) => {
    try {
      dispatch(beginMyPLuginsFetch());
      const res = await fetch(`${Properties.API_HOST}u/${uid}/my-plugins`);
      const json = await res.json();
      return dispatch(myPluginsFetchSuccess(json));
    } catch (error) {
      return dispatch(myPluginsFetchFail(error));
    }
  };
};

export const changeMyPluginState = (activeComponent) => ({
  type: CHANGE_MY_PLUGIN_STATE,
  payload: { activeComponent },
});

const uploadPluginBegin = () => ({
  type: UPLOAD_PLUGIN_BEGIN,
});

const uploadPluginSuccess = (data) => ({
  type: UPLOAD_PLUGIN_SUCCESS,
  payload: { data },
});

const uploadPluginFail = (error) => ({
  type: UPLOAD_PLUGIN_FAIL,
  payload: { error },
});

const uploadPluginProgress = (percentage) => ({
  type: UPLOAD_PLUGIN_PERCENTAGE_UPDATE,
  payload: {
    percentage,
  },
});

export const uploadPlugin = (uid, currerntUser, authToken, dataToStore) => {
  return async (dispatch) => {
    try {
      dispatch(uploadPluginBegin());
      const json = JSON.stringify(dataToStore.data);
      let formData = new FormData();
      formData.append('dataToStore', new Blob([json], {type: 'application/json'}))
      formData.append('json_file', dataToStore['json_file']);
      formData.append('jar_file', dataToStore['jar_file']);
      formData.append('img_file', dataToStore['img_file']);
      formData.append('license_file', dataToStore['license_file']);
      const res = await axios.request({
        url: `${Properties.API_HOST}u/${uid}/upload-plugin`,
        method: 'post',
        data: formData,
        headers: { Authorization: currerntUser },
          onUploadProgress: (progressEvent) => {
            dispatch(uploadPluginProgress((progressEvent.loaded/progressEvent.total)*100));
        }
      });
      if (res.status === 403) {
        dispatch(uploadPluginFail('Maximum limit reached'));
        return dispatch(
          setToggleModal("You have reached upload 'Private plugins' limit.")
        );
      }
      dispatch(uploadPluginSuccess(res.data));
      dispatch(fetchMyPlugins(uid));
      // dispatch(oss.fetchOSS(uid, authToken));
      dispatch(oss.fetchPagination(uid, authToken));
      return dispatch(changeMyPluginState('myPlugins'));
    } catch (error) {
      dispatch(uploadPluginFail(error));
    }
  };
};

export const setToggleModal = (alert) => ({
  type: SET_TOGGAL_MODAL,
  payload: { alert },
});

export const EditPluginPopupHandler = (pluginUniqueId) => ({
  type: EDIT_POPUP_HANDLER,
  payload: pluginUniqueId,
});

export const DeletePluginPopupHandler = (pluginUniqueId) => ({
  type: DELETE_POPUP_HANDLER,
  payload: pluginUniqueId,
});

const editPluginBegin = () => ({
  type: EDIT_PLUGIN_BEGIN,
});

const editPluginSuccess = (data) => ({
  type: EDIT_PLUGIN_SUCCESS,
  payload: { data },
});

const editPluginFail = (error) => ({
  type: EDIT_PLUGIN_FAIL,
  payload: { error },
});

export const editPlugin = (uid, pluginId, state, authToken) => {
  const body = { editedData: state };
  return async (dispatch) => {
    try {
      dispatch(editPluginBegin());
      const res = await fetch(`${Properties.API_HOST}u/${uid}/plugin/${pluginId}/edit`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      }
      );
      const json = await res.json();
      return dispatch(editPluginSuccess(json));
    } catch (error) {
      return dispatch(editPluginFail(error));
    }
  };
};

const deletePluginBegin = () => ({
  type: DELETE_PLUGIN_BEGIN,
});

const deletePluginSuccess = (data) => ({
  type: DELETE_PLUGIN_SUCCESS,
  payload: { data },
});

const deletePluginFail = (error) => ({
  type: DELETE_PLUGIN_FAIL,
  payload: { error },
});

export const deletePlugin = (uid, pluginId, authToken) => {
  return async (dispatch) => {
    try {
      dispatch(deletePluginBegin());
      const res = await fetch(`${Properties.API_HOST}u/${uid}/plugin/${pluginId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      }
      );
      const json = await res.json();
      return dispatch(deletePluginSuccess(json));
    } catch (error) {
      return dispatch(deletePluginFail(error));
    }
  };
};