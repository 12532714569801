import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import assets from '../../../assets';
import ArtifactLinks from '../ArtifactLinks';
import { Properties } from '../../../config';
import WidgetLinks from '../WidgetLinks';
import { isEmpty } from 'lodash';

class DetailsArtifacts extends Component {
  downloadFile = (link) => {
    window.open(link, 'Download');
  };

  render() {
    const { artifact, widget } = this.props;
    if (widget !== null) {
      return <WidgetLinks artifact={artifact} widget={widget} />;
    }
    return (
      <div>
        {isEmpty(artifact.jsonInfo.artifactJsonFileName) ||
        isEmpty(artifact.jsonInfo.artifactJarFileName) ? (
          <div>
            <ol></ol>
            <p>
              For this file "{artifact.name}-{artifact.version}" there is no
              JSON or JAR file.
            </p>
          </div>
        ) : (
          <div>
            <div className="row mgBottom-10">
              <div className="col-lg-2 img-icon">
                <img src={assets.images.jar} alt="" />
              </div>
              <div className="col-text text-mgTop">
                <div className="active">
                  {artifact.userId !== '' && artifact.commercial === true ? (
                    <div>
                      {artifact.commercial === true &&
                      artifact.isAlreadyPurchased ? (
                        <label
                          onClick={() =>
                            this.downloadFile(
                              artifact.visibility === 'private'
                                ? `${Properties.API_HOST}u/${this.props.currentUser.uid}/s/${this.props.userProfile.authToken}/packages/${artifact.name}/${artifact.version}/${artifact.jsonInfo.artifactJarFileName}`
                                : `${Properties.API_HOST}packages/${artifact.name}/${artifact.version}/${artifact.jsonInfo.artifactJarFileName}`
                            )
                          }
                          style={{ cursor: 'pointer', alignSelf: 'center' }}
                        >
                          {artifact.jsonInfo.artifactJarFileName}
                        </label>
                      ) : (
                        <label>{artifact.jsonInfo.artifactJarFileName}</label>
                      )}
                    </div>
                  ) : (
                    <label
                      onClick={() =>
                        this.downloadFile(
                          artifact.visibility === 'private'
                            ? `${Properties.API_HOST}u/${this.props.currentUser.uid}/s/${this.props.userProfile.authToken}/packages/${artifact.name}/${artifact.version}/${artifact.jsonInfo.artifactJarFileName}`
                            : `${Properties.API_HOST}packages/${artifact.name}/${artifact.version}/${artifact.jsonInfo.artifactJarFileName}`
                        )
                      }
                      style={{ cursor: 'pointer', alignSelf: 'center' }}
                    >
                      {artifact.jsonInfo.artifactJarFileName}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2 img-icon">
                <img src={assets.images.json} alt="" />
              </div>
              <div className="col-text text-mgTop">
                <div className="active">
                  {artifact.userId !== '' && artifact.commercial === true ? (
                    <div>
                      {artifact.commercial === true &&
                      artifact.isAlreadyPurchased ? (
                        <label
                          onClick={() =>
                            this.downloadFile(
                              artifact.visibility === 'private'
                                ? `${Properties.API_HOST}u/${this.props.currentUser.uid}/s/${this.props.userProfile.authToken}/packages/${artifact.name}/${artifact.version}/${artifact.jsonInfo.artifactJsonFileName}`
                                : `${Properties.API_HOST}packages/${artifact.name}/${artifact.version}/${artifact.jsonInfo.artifactJsonFileName}`
                            )
                          }
                          style={{ cursor: 'pointer', alignSelf: 'center' }}
                        >
                          {artifact.jsonInfo.artifactJsonFileName}
                        </label>
                      ) : (
                        <label>{artifact.jsonInfo.artifactJsonFileName}</label>
                      )}
                    </div>
                  ) : (
                    <label
                      onClick={() =>
                        this.downloadFile(
                          artifact.visibility === 'private'
                            ? `${Properties.API_HOST}u/${this.props.currentUser.uid}/s/${this.props.userProfile.authToken}/packages/${artifact.name}/${artifact.version}/${artifact.jsonInfo.artifactJsonFileName}`
                            : `${Properties.API_HOST}packages/${artifact.name}/${artifact.version}/${artifact.jsonInfo.artifactJsonFileName}`
                        )
                      }
                      style={{ cursor: 'pointer', alignSelf: 'center' }}
                    >
                      {artifact.jsonInfo.artifactJsonFileName}
                    </label>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <ArtifactLinks artifact={artifact} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  alreadyPurchased: state.shoppingCart.alreadyPurchased,
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  userProfile: state.user.userProfile,
});

export default withRouter(connect(mapStateToProps)(DetailsArtifacts));
