import React, { Component } from 'react';
import { connect } from 'react-redux';
import ShoppingCartHeader from '../ShoppingCart/ShoppingCartHeader';
import PricingTable from './PricingTable';

class PricingDetails extends Component {
  render() {
    return (
      <div>
        <div className="header pt-md-6">
          <div className="container">
            <div className="header-body">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card-stats mb-4 mb-xl-0 pd-10">
                    <div className="card-body bg-white rounder-borders card-body-shadow">
                      <ShoppingCartHeader
                        goBack={() => {
                          this.props.history.goBack();
                        }}
                        componentName="Pricing Details"
                      />
                      <PricingTable history={this.props.history} />

                      {/* <div className="col-lg-12 mgTopBottom border-top pd-10">
                        <div className="col-xl-12 col-lg-12">
                          <div className="card card-stats mb-4 mb-xl-0 pd-10">
                            <div className="row">
                              <div
                                className="empty"
                                style={{ flex: '0 0 25.888%' }}
                              />
                              <div
                                className="col-lg-2 text-center base-pd"
                              >
                                <div className="base base-free">
                                  <h3 className="transform text-center pro-free text-color-free">
                                    FREE
                                  </h3>
                                  <hr />
                                  <h3 className="transform text-center pro-free1 text-color-free">
                                    $0/month
                                  </h3>
                                </div>
                              </div>
                              <div
                                className="col-lg-2 text-center base-pd"
                              >
                                <div className="base base-basic">
                                  <h3 className="transform text-center pro-basic text-color-free">
                                    BASIC
                                  </h3>
                                  <hr />
                                  <h3 className="transform text-center text-color-free">
                                    $100/month
                                  </h3>
                                </div>
                              </div>
                              <div
                                className="col-lg-2 text-center base-pd"
                              >
                                <div className="base base-professional">
                                  <h3 className="transform text-center pro-professional">
                                    PROFESSIONAL
                                  </h3>
                                  <hr />
                                  <h3 className="transform text-center pro-professional1">
                                    $150/month
                                  </h3>
                                </div>
                              </div>
                              <div
                                className="col-lg-2 text-center base-pd"
                              >
                                <div className="base base-enterprise">
                                  <h3 className="transform text-center pro-enterprise">
                                    ENTERPRISE
                                  </h3>
                                  <hr />
                                  <h3 className="transform text-center pro-enterprise1">
                                    $495/month
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <PricingTable history={this.props.history} />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  // ...ownProps,
});
export default connect(mapStateToProps)(PricingDetails);
