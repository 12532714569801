import React, { Component } from 'react';
import { connect } from 'react-redux';
import { oss, activeState, details } from '../../actions';
import {
  Search,
  MyPlugins,
  ListView,
  CardView,
  LoadingSpinner,
  SkeletonComp,
} from '../../components';
import { orderBy, find, get, isEmpty } from 'lodash';
import { stripPluginSuffixPrefix } from '../../helpers';
import NavigationTabs from '../NavigationTabs/NavigationTabs';
import CommercialPlugins from '../CommercialPlugins/CommercialPlugins';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { sortBy } from 'lodash';

class OssPlugins extends Component {
  state = {
    dataVersions: [
      'all',
      '6.10.0',
      '6.9.2',
      '6.9.1',
      '6.9.0',
      '6.8.3',
      '6.8.2',
      '6.8.1',
      '6.8.0',
      '6.7.3',
      '6.7.2',
      '6.7.1',
      '6.7.0',
      '6.6.0',
      '6.5.1',
      '6.5.0',
      '6.4.1',
      '6.3.0',
      '6.2.3',
      '6.1.4',
    ],
    version: '6.10.0',
    pluginType: 'All types',
    pluginNr: '',
  };

  filterByPluginType = async (event) => {
    const newFilters = {
      ...this.props.filters,
      artifactType: event.target.value,
    };
    this.props.setOssFilters(newFilters);
  };

  filterDataVersions = (event) => {
    const newFilters = {
      ...this.props.filters,
      cdapVersion: event.target.value,
    };
    this.props.setOssFilters(newFilters);
  };

  filterByBundle = () => {
    const newFilters = {
      ...this.props.filters,
      showBundles: !this.props.filters.showBundles,
    };
    this.props.setOssFilters(newFilters);
  };

  exctractArtifactJsonInfo = (artifact) => {
    const artifactArguments = get(
      artifact,
      'spec_json.actions[0].arguments',
      []
    );

    const findArtifactJsonFileName = find(artifactArguments, {
      name: 'config',
    });
    const artifactJsonFileName = !isEmpty(findArtifactJsonFileName)
      ? findArtifactJsonFileName.value
      : null;

    const findArtifactName = find(artifactArguments, { name: 'name' });
    const artifactName = !isEmpty(findArtifactName)
      ? findArtifactName.value
      : null;

    const findArtifactJarFileName = find(artifactArguments, { name: 'jar' });
    const artifactJarFileName = !isEmpty(findArtifactJarFileName)
      ? findArtifactJarFileName.value
      : null;

    return {
      artifactJsonFileName,
      artifactName,
      artifactJarFileName,
    };
  };

  goToDetails = (selectedArtifact, selectedWidget) => {
    const allArtifactVersions = orderBy(
      this.props.allPlugins.filter(
        (artifact) => artifact.name === selectedArtifact.name
      ),
      'version',
      'desc'
    ).map((artifactVersion) => ({
      ...artifactVersion,
      activeTab: 'plugins',
      activeSubTab: 'cli',
      jsonFileContent: {
        parents: [],
      },
      jsonInfo: this.exctractArtifactJsonInfo(artifactVersion),
      hasDocumentation:
        artifactVersion.docs_file &&
        artifactVersion.docs_file.length === 1 &&
        artifactVersion.docs_file[0].link.startsWith('http'),
    }));
    const artifactIndex = allArtifactVersions.findIndex(
      (artifact) => artifact.version === selectedArtifact.version
    );
    const widgetName =
      selectedWidget && selectedWidget.name ? selectedWidget.name : null;
    const widgetIndex =
      selectedWidget !== null
        ? selectedArtifact.widgets.findIndex(
            (widget) => widget.name === selectedWidget.name
          )
        : -1;
    const widgetStrippedName =
      widgetIndex !== -1
        ? stripPluginSuffixPrefix(selectedWidget.name).toLowerCase()
        : null;
    this.props.setDetailsArtifact(
      allArtifactVersions,
      artifactIndex,
      widgetIndex,
      widgetName
    );
    this.props.history.push({
      pathname:
        widgetIndex === -1
          ? `/details/${selectedArtifact.name}/${selectedArtifact.version}/`
          : `/details/${selectedArtifact.name}/${selectedArtifact.version}/${widgetStrippedName}/`,
    });
  };

  goToListView = () => {
    this.props.setActiveViewType(
      this.props.activeViewType === 'list' ? 'card' : 'list'
    );
  };

  calculateItemsToRender = () => {
    let itemsToRender = [];
    this.props.data.forEach((artifactItem, index) => {
      const artifactIsSingleton =
        !artifactItem.widgets || artifactItem.widgets.length === 1;
      const artifactIsBundle = !artifactIsSingleton;
      const renderArtifact =
        artifactIsSingleton ||
        this.props.filters.showBundles === artifactIsBundle;
      const renderWidgets =
        artifactItem.widgets && artifactItem.widgets.length > 1;

      if (renderArtifact) {
        itemsToRender.push({
          type: 'artifact',
          artifact: artifactItem,
          widget: null,
        });
      }
      if (renderWidgets) {
        sortBy(artifactItem.widgets, 'name').forEach(
          (artifactItemWidget, widgetIndex) => {
            itemsToRender.push({
              type: 'widget',
              artifact: artifactItem,
              widget: artifactItemWidget,
            });
          }
        );
      }
    });
    return itemsToRender;
  };

  renderCardView = (itemsToRender) => {
    if (
      this.props.isLoading ||
      (this.props.userIsLoading && this.props.paginationIsLoading === false)
    ) {
      return <SkeletonComp />;
    }
    return (
      <CardView
        data={itemsToRender}
        filters={this.props.filters}
        goToDetails={(artifact, widget) => this.goToDetails(artifact, widget)}
      />
    );
  };

  renderListView = (itemsToRender) => {
    if (
      this.props.isLoading ||
      (this.props.userIsLoading && this.props.paginationIsLoading === false)
    ) {
      return <SkeletonComp />;
    }
    return (
      <ListView
        data={itemsToRender}
        filters={this.props.filters}
        goToDetails={(artifact, widget) => this.goToDetails(artifact, widget)}
      />
    );
  };
  itemsToRender = () => {
    const { activeViewType } = this.props;
    const items = this.calculateItemsToRender();
    const itemsToRender =
      activeViewType === 'card'
        ? this.renderCardView(items)
        : this.renderListView(items);
    this.props.setItemsCount(items.length);
    return itemsToRender;
  };

  pipelineNavHandler = (event, value) => {
    if (value === 'pipelines') {
      this.props.setActiveTabPipeline(value);
      this.props.history.push('/pipelines');
      return;
    }
    this.props.setActiveTabPipeline(value);
    this.props.history.push('/');
    return;
  };

  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        ['all', 'openSource'].includes(this.props.activeTab) &&
        this.props.data.length > 0 &&
        !this.props.paginationIsLoading
      ) {
          if (this.props.userLoggedIn) {
            this.props.fetchPagination(
              this.props.currentUser.uid,
              this.props.authToken,
              this.props.data.pop().uniqueId
            );
          } else {
            this.props.fetchPagination(
              null,
              null,
              this.props.data.pop().uniqueId
            );
        }
      }
    });
  }

  render() {
    this.props.setActiveTabPipeline('plugins');
    return (
      <div>
        <div className="header pt-md-6">
          <div className="container">
            <div className="header-body">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card-stats mb-4 mb-xl-0 pd-10">
                    <div className="card-body bg-white rounder-borders card-body-shadow">
                      {this.props.currentUser &&
                        this.props.currentPlan === 'enterprise-plus' && (
                          <div
                            className="col-lg-12 custom-tabs"
                            style={{ background: '#f8f9fe' }}
                          >
                            <Paper
                              className="paper-nav-tabs col-lg-4"
                              style={{
                                background: '#f8f9fe',
                                border: 'none !important',
                              }}
                            >
                              <Tabs
                                variant="fullWidth"
                                value={this.props.activeTabPipeline}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={(event, value) => {
                                  this.pipelineNavHandler(event, value);
                                }}
                                aria-label="disabled tabs example"
                              >
                                <Tab label="Plugins" value="plugins" />
                                <Tab label="Pipelines" value="pipelines" />
                              </Tabs>
                            </Paper>
                          </div>
                        )}
                      <div className="row" style={{ marginTop: '15px' }}>
                        <NavigationTabs />
                        <Search keyword={this.props.match.params.keyword} />
                      </div>
                      {this.props.activeComponent === 'myPlugins' && (
                        <div className="row filter-row">
                          <i
                            className="fa fa-filter"
                            style={{
                              color: 'gray',
                              padding: '10px',
                              fontSize: '25px',
                            }}
                          ></i>
                          <div className="col">
                            <TextField
                              variant="outlined"
                              id="standard-select-version"
                              select
                              label="CDAP Version:"
                              color="primary"
                              fullWidth
                              value={this.props.filters.cdapVersion}
                              onChange={this.filterDataVersions}
                            >
                              {this.state.dataVersions.map((element, index) => (
                                <MenuItem key={element} value={element}>
                                  {element === 'all' ? 'All versions' : element}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          <div className="col">
                            <TextField
                              variant="outlined"
                              id="standard-select-type"
                              select
                              label="Type:"
                              color="primary"
                              fullWidth
                              value={this.props.filters.artifactType}
                              onChange={this.filterByPluginType}
                            >
                              {this.props.artifactTypes.map(
                                (element, index) => (
                                  <MenuItem key={index} value={element}>
                                    {element === 'all' ? 'All types' : element}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          </div>
                          <div className="col filter-bundle pd-top-12">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    color="primary"
                                    checked={this.props.filters.showBundles}
                                    onChange={() => {
                                      this.filterByBundle();
                                    }}
                                  />
                                }
                                label="Show Plugin Bundles"
                              />
                            </FormGroup>
                          </div>
                          {window.innerWidth > 991 && (
                            <label
                              className="text-right fa-list-card"
                              onClick={this.goToListView}
                            >
                              <i
                                className={`fa ${
                                  this.props.activeViewType === 'card'
                                    ? 'fa-list'
                                    : 'fa-th'
                                } button-click`}
                                style={{
                                  color: 'gray',
                                  fonts: '21px',
                                  cursor: 'pointer',
                                }}
                              />
                            </label>
                          )}
                        </div>
                      )}
                      <div className="card-list-wrapper">
                        {['all', 'openSource'].indexOf(this.props.activeTab) >
                          -1 && <div>{this.itemsToRender()}</div>}
                        {this.props.paginationIsLoading && (
                          <LoadingSpinner textToShow={'Loading...'} />
                        )}
                        {this.props.activeTab === 'myPlugins' && (
                          <div>
                            <MyPlugins
                              goToDetails={(artifact, widget) =>
                                this.goToDetails(artifact, widget)
                              }
                              history={this.props.history}
                            />
                          </div>
                        )}
                        {this.props.activeTab === 'commercial' && (
                          <CommercialPlugins
                            activeViewType={this.props.activeViewType}
                            goToDetails={(artifact, widget) =>
                              this.goToDetails(artifact, widget)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateFromProps = (state) => ({
  isLoading: state.oss.isLoading,
  userIsLoading: state.user.isLoading,
  data: state.oss.filteredData,
  allPlugins: state.oss.data,
  filters: state.oss.filters,
  artifactTypes: state.oss.artifactTypes,
  activeViewType: state.activeState.activeViewType,
  currentPlan: state.user.currentPlan,
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  activeTab: state.activeState.activeTab,
  isUserSet: state.user.isUserSet,
  commercialFilteredData: state.oss.commercialFilteredData,
  itemsCount: state.oss.itemsCount,
  activeComponent: state.myPlugins.activeComponent,
  activeTabPipeline: state.activeState.activeTabPipeline,
  paginationIsLoading: state.oss.paginationIsLoading,
});

const mapActionsToProps = {
  filterDataOSS: oss.filterDataOSS,
  setActiveViewType: activeState.setActiveViewType,
  resetDetailsData: oss.resetDetailsData,
  setOssFilters: oss.setOssFilters,
  setDetailsArtifact: details.setDetailsArtifact,
  setActiveTab: activeState.setActiveTab,
  setItemsCount: oss.setItemsCount,
  setActiveTabPipeline: activeState.setActiveTabPipeline,
  fetchOSS: oss.fetchOSS,
  fetchPagination: oss.fetchPagination,
};

export default connect(mapStateFromProps, mapActionsToProps)(OssPlugins);
