import React, { Component } from 'react';
import { connect } from 'react-redux';

class UserProfileInfo extends Component {
  render() {
    const { uid, email } = this.props.currentUser;
    return (
      <div
        className="pd-10"
        style={{ margin: '20px' }}
      >
        <div className="row">
          <div className="col-lg-12">
              <div className="row mg-left-17">
                <label className="bold"> Email: </label>
                <label>&nbsp;{email}</label>
              </div>
              <div className="row mg-left-17">
                <label className="bold"> Authorization Key: </label>
                <label>&nbsp; {uid}</label>
              </div>
              <div className="row mg-left-17">
                <label className="bold">Secret Key: </label>
                <label>&nbsp; {this.props.authToken}</label>
              </div>
            <br />
            <p>
              &nbsp;{' '}
              {`Add the following XML snippet to your cdap-site.xml file to use your private plugin hub with your CDAP instance.`}
            </p>
            <br />
            <div className="user-info">
              <pre style={{ margin: 0 }}>
                {`<property>\n   <name>market.base.url</name>\n       <value>https://hub.adaptivescale.com/u/${uid}/s/${this.props.authToken}</value>\n       <description>\n            Local Cask Market base url\n       </description>\n</property>`}
              </pre>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  isLoading: state.user.isLoading,
  secretKey: state.myPlugins.secretKey,
  currentPlan: state.user.currentPlan,
});
export default connect(mapStateToProps)(UserProfileInfo);
