import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shoppingCart } from '../../../actions';

class Plans extends Component {
  professionalPlan = () => {
    let professionalPlan = '';
    if (this.props.currentPlan === 'professional') {
      professionalPlan = <div className="current-plan">Current Plan</div>;
    }
    if (
      this.props.currentPlan === 'enterprise-basic' ||
      this.props.currentPlan === 'enterprise-plus'
    ) {
      professionalPlan = (
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            this.props.setPrice(199);
            this.props.currentUser !== null
              ? this.props.history.push('/payment/professional')
              : this.props.history.push('/login');
          }}
        >
          Downgrade
        </button>
      );
    }
    return professionalPlan;
  };

  enterpriseBasic = () => {
    let enterpriseBasic = '';
    if (this.props.currentPlan === 'enterprise-basic') {
      enterpriseBasic = <div className="current-plan">Current Plan</div>;
    }
    if (this.props.currentPlan === 'enterprise-plus') {
      enterpriseBasic = (
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            this.props.setPrice(499);
            this.props.currentUser !== null
              ? this.props.history.push('/payment/enterprise-basic')
              : this.props.history.push('/login');
          }}
        >
          Downgrade
        </button>
      );
    }
    return enterpriseBasic;
  };

  basicPlan = () => {
    let basicPlan = '';
    if (this.props.currentPlan === 'basic') {
      basicPlan = <div className="current-plan">Current Plan</div>;
    } else if (
      this.props.currentPlan === 'enterprise-basic' ||
      this.props.currentPlan === 'enterprise-plus' ||
      this.props.currentPlan === 'professional'
    ) {
      basicPlan = (
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            this.props.setPrice(49);
            this.props.currentUser !== null
              ? this.props.history.push('/payment/basic')
              : this.props.history.push('/login');
          }}
        >
          Downgrade
        </button>
      );
    }
    return basicPlan;
  };

  cancelSubscription = () => {
    this.props.cancelSubscription(
      this.props.currentUser.ma,
      this.props.currentUser.uid
    );
    this.props.history.push('/receipt/cancel-subscription');
  };

  render() {
    if (this.props.currentPlan === null) {
      return <div style={{ paddingTop: '2.5rem' }}>Loading...</div>;
    } else {
      return (
        <div className="pricing-plans" style={{ paddingTop: '2.0rem' }}>
          <b style={{ fontSize: '20px' }}>Chose a Plan</b>
          <p>Select a plan that's right for you.</p>
          <div className="row" style={{ paddingTop: '1.0rem' }}>
            <div className="col-lg-4">
              <div
                className="bg-color-shoppingList plan-card"
                style={{ padding: '1rem 10px' }}
              >
                <div className="plan-header text-center">
                  <h4>Free</h4>
                  <h2>$0</h2>
                  <h5>per month</h5>
                </div>
                <hr style={{ margin: '20px' }} />
                <div
                  className="plan-body"
                  style={{ textAlign: 'left', paddingRight: '5px' }}
                >
                  <ul
                    style={{
                      marginBottom: '1rem',
                      paddingLeft: '24px',
                      fontSize: '13px',
                    }}
                  >
                    <li>Unlimited OSS plugins.</li>
                    <li>Must includec a GitHub repo.</li>
                  </ul>
                </div>
                <div className="plan-footer">
                  {this.props.currentPlan === 'professional' ||
                  this.props.currentPlan === 'enterprise-basic' ||
                  this.props.currentPlan === 'enterprise-plus' ||
                  this.props.currentPlan === 'basic' ? (
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        //   this.props.setPrice(150);
                        this.props.currentUser !== null
                          ? this.cancelSubscription()
                          : this.props.history.push('/login');
                      }}
                    >
                      Cancel Subscription
                    </button>
                  ) : (
                    <div className="current-plan">Current Plan</div>
                  )}
                </div>
                <button
                  className="full-link-plan"
                  onClick={() => {
                    this.props.history.push('/pricingDetails');
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="bg-color-shoppingList plan-card"
                style={{ padding: '1rem 10px' }}
              >
                <div className="plan-header text-center">
                  <h4>Basic</h4>
                  <h2>$49</h2>
                  <h5>per month</h5>
                </div>
                <hr style={{ margin: '20px' }} />
                <div
                  className="plan-body"
                  style={{ textAlign: 'left', paddingRight: '5px' }}
                >
                  <ul
                    style={{
                      marginBottom: '1rem',
                      paddingLeft: '24px',
                      fontSize: '13px',
                    }}
                  >
                    <li>Unlimited OSS plugins.</li>
                    <li>Allows 10 private plugins to be uploaded.</li>
                    <li>
                      Can only be used by a single CDAP sandbox instance (single
                      IP / dynamic).
                    </li>
                    <li>
                      The plugins can be shared publicly and may contain closed
                      source artifacts and a commercial license.
                    </li>
                  </ul>
                </div>
                <div className="plan-footer">
                  {this.props.currentPlan === 'free' ? (
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        this.props.setPrice(49);
                        this.props.currentUser !== null
                          ? this.props.history.push('/payment/basic')
                          : this.props.history.push('/login');
                      }}
                    >
                      Upgrade
                    </button>
                  ) : (
                    <div>{this.basicPlan()}</div>
                  )}
                </div>
                <button
                  className="full-link-plan"
                  onClick={() => {
                    this.props.history.push('/pricingDetails');
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="bg-color-shoppingList plan-card"
                style={{ padding: '1rem 10px' }}
              >
                <div className="plan-header text-center">
                  <h4>Professional</h4>
                  <h2>$199</h2>
                  <h5>per month</h5>
                </div>
                <hr style={{ margin: '20px' }} />
                <div
                  className="plan-body"
                  style={{ textAlign: 'left', paddingRight: '5px' }}
                >
                  <ul
                    style={{
                      marginBottom: '1rem',
                      paddingLeft: '24px',
                      fontSize: '13px',
                    }}
                  >
                    <li>Unlimited OSS plugins.</li>
                    {/* <li>
                      Targeted at enterprises that need to store artifacts
                      centrally and securely.
                    </li> */}
                    <li>Allows 50 private plugins to be uploaded.</li>
                    <li>Can be used by 3 CDAP sandbox instances.</li>
                    <li>
                      The plugins can be shared publicly and may contain clossed
                      source artisfacts and a commercial license.
                    </li>
                    {/* <li>Costumer can not sell plugins.</li> */}
                  </ul>
                </div>
                <div className="plan-footer">
                  {this.props.currentPlan === 'free' ||
                  this.props.currentPlan === 'basic' ? (
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        this.props.setPrice(199);
                        this.props.currentUser !== null
                          ? this.props.history.push('/payment/professional')
                          : this.props.history.push('/login');
                      }}
                    >
                      Upgrade
                    </button>
                  ) : (
                    <div>{this.professionalPlan()}</div>
                  )}
                </div>
                <button
                  className="full-link-plan"
                  onClick={() => {
                    this.props.history.push('/pricingDetails');
                  }}
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="col-lg-8 mx-auto">
                <div class="row">
                  <div className="col-lg-6">
                    <div
                      className="bg-color-shoppingList plan-card"
                      style={{ padding: '1rem 10px' }}
                    >
                      <div className="plan-header text-center">
                        <h4 className="text-center text-plan-card">
                          Enterprise Basic
                        </h4>
                        <h2 className="text-center text-plan-card">$499</h2>
                        <h5 className="text-center text-plan-card">
                          per month
                        </h5>
                      </div>
                      <hr style={{ margin: '20px' }} />
                      <div
                        className="plan-body"
                        style={{ textAlign: 'left', paddingRight: '5px' }}
                      >
                        <ul
                          style={{
                            marginBottom: '1rem',
                            paddingLeft: '24px',
                            fontSize: '13px',
                          }}
                        >
                          <li>Unlimited OSS plugins.</li>
                          <li>
                            Allows unlimited private plugins to be uploaded.
                          </li>
                          <li>Can be used by 3 CDAP sandbox instances.</li>
                          <li>
                            The plugins can be shared publicly and may contain
                            clossed source artisfacts and a commercial license.
                          </li>
                          {/* <li>
                      Targeted enterprises and ISV's or developers that chose to
                      sell and market their plugins.
                    </li>
                    <li>
                      No limit on the number of plugins that vendor can upload.
                    </li>
                    <li>
                      Plugins can be assigned a promotional discount and a
                      volume discount.
                    </li>
                    <li>Fees include 10% of sales cost + transactions fees.</li>
                    <li>
                      Companies that chose to sell plugins will have a page to
                      include company info and support details.
                    </li>
                    <li>
                      Download or transfer pipelines from one instance to
                      another with batch operation or singleton. Including
                      plugin deployment that are relevat to their pipelines.
                    </li> */}
                        </ul>
                      </div>
                      <div className="plan-footer">
                        {this.props.currentPlan === 'free' ||
                        this.props.currentPlan === 'basic' ||
                        this.props.currentPlan === 'professional' ? (
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => {
                              this.props.setPrice(499);
                              this.props.currentUser !== null
                                ? this.props.history.push(
                                    '/payment/enterprise-basic'
                                  )
                                : this.props.history.push('/login');
                            }}
                          >
                            Upgrade
                          </button>
                        ) : (
                          <div>{this.enterpriseBasic()}</div>
                        )}
                      </div>
                      <button
                        className="full-link-plan"
                        onClick={() => {
                          this.props.history.push('/pricingDetails');
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="bg-color-shoppingList plan-card"
                      style={{ padding: '1rem 10px' }}
                    >
                      <div className="plan-header text-center">
                        <h4 className="text-center text-plan-card">
                          Enterprise Plus
                        </h4>
                        <h2 className="text-center text-plan-card">$2,750</h2>
                        <h5 className="text-center text-plan-card">
                          per month
                        </h5>
                      </div>
                      <hr style={{ margin: '20px' }} />
                      <div
                        className="plan-body"
                        style={{ textAlign: 'left', paddingRight: '5px' }}
                      >
                        <ul
                          style={{
                            marginBottom: '1rem',
                            paddingLeft: '24px',
                            fontSize: '13px',
                          }}
                        >
                          <li>Unlimited OSS plugins.</li>
                          <li>Unlimited PRIVATE plugins</li>
                          <li>
                            Can be used by Unlimited CDAP sandbox instances.
                          </li>
                          <li>
                            Pipelines Feature (Managing pipelines across
                            multiple instances. Transfer from one instance to
                            anothe with batch operations or singleton. Including
                            plugin deployment that are relevat to their
                            pipelines. Also start, delete, export pipelines in
                            batch operations are avaliable.)
                          </li>
                        </ul>
                      </div>
                      <div className="plan-footer">
                        {this.props.currentPlan === 'enterprise-plus' ? (
                          <div className="current-plan">Current Plan</div>
                        ) : (
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => {
                              this.props.setPrice(2750);
                              this.props.currentUser !== null
                                ? this.props.history.push(
                                    '/payment/enterprise-plus'
                                  )
                                : this.props.history.push('/login');
                            }}
                          >
                            Upgrade
                          </button>
                        )}
                      </div>
                      <button
                        className="full-link-plan"
                        onClick={() => {
                          this.props.history.push('/pricingDetails');
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  currentPlan: state.user.currentPlan,
  // ...ownProps,
});

const mapActionsToProps = {
  setPrice: shoppingCart.setPrice,
  cancelSubscription: shoppingCart.cancelSubscription,
};
export default connect(mapStateToProps, mapActionsToProps)(Plans);
