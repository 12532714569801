import React, { Component } from 'react';
import { userActions } from '../../actions/index';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Assets from '../../assets';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { shoppingCart } from '../../actions';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      errors: [],
      loading: false,
      showPassword: false,
    };
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  isFormEmpty = ({ email, password, confirmPassword }) => {
    return !email.length || !password.length || !confirmPassword.length;
  };
  isPasswordValid = () => {
    if (this.state.password.length < 6 || this.state.confirmPassword.length < 6)
      return true;
    else if (this.state.password !== this.state.confirmPassword) return true;
    else return false;
  };

  isFormValid = () => {
    let errors = [];
    let error;
    if (this.isFormEmpty(this.state)) {
      error = { message: 'Fill in all fields!' };
      this.setState({ errors: errors.concat(error) });
      return false;
    } else if (this.isPasswordValid(this.state)) {
      error = { message: 'Password is not valid!' };
      this.setState({ errors: errors.concat(error) });
      return false;
    } else {
      console.debug('isFormValid->User Registered!');
      return true;
    }
  };

  displayErrors = (error) => {
    return error.map((error, i) => <p key={i}>{error.message}</p>);
  };

  googleSignIn = async () => {
    this.props.setErrorToNull();
    await this.props.googleSignIn();
    if (this.props.error) {
      this.setState({
        errors: this.state.errors.concat(this.props.error),
        loading: false,
      });
      return;
    }
    if (this.props.location.pathname === '/register') {
      this.props.history.push('/');
    } else {
      this.checkPaths();
    }
  };

  gitHubSignIn = async () => {
    this.props.setErrorToNull();
    await this.props.gitHubSignIn();
    if (this.props.error) {
      this.setState({
        errors: this.state.errors.concat(this.props.error),
        loading: false,
      });
      return;
    }
    if (this.props.location.pathname === '/register') {
      this.props.history.push('/');
    } else {
      this.checkPaths();
    }
  };

  checkPaths = () => {
    if (this.props.location.pathname === '/register-payment/professional') {
      this.props.setPrice(199);
      this.props.history.push('/payment/professional');
    }
    if (this.props.location.pathname === '/register-payment/basic') {
      this.props.setPrice(49);
      this.props.history.push('/payment/basic');
    }
    if (this.props.location.pathname === '/register-payment/enterprise-basic') {
      this.props.setPrice(499);
      this.props.history.push('/payment/enterprise-basic');
    }
    if (this.props.location.pathname === '/register-payment/enterprise-plus') {
      this.props.setPrice(2750);
      this.props.history.push('/payment/enterprise-plus');
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.props.setErrorToNull();
    if (this.isFormValid(this.state)) {
      this.setState({ errors: [], loading: true });
      await this.props.regularSignUp(this.state.email, this.state.password);
      if (this.props.error) {
        this.setState({
          errors: this.state.errors.concat(this.props.error),
          loading: false,
        });
        return;
      }
      if (this.props.location.pathname === '/register') {
        this.props.history.push('/');
      } else {
        this.checkPaths();
      }
    }
  };

  render() {
    const { email, password, confirmPassword } = this.state;
    if (this.props.userLoggedIn) {
      return <div style={{padding:"20px"}}>You are already signed in.</div>;
    }
    return (
      <div>
        <div
          className="header bg-gradient-primary py-7 py-lg-8 login-page"
          style={{ minHeight: 0 }}
        >
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-6 text-center">
              <img
                src="https://d33wubrfki0l68.cloudfront.net/23317a66889fb01027aeb64af443fce2c03623b4/74d74/adaptivescale/img/logo.png"
                style={{ width: '40%', padding: '0px 0px 35px 0px' }}
                alt=""
              />
              <div style={{ marginBottom: '50px' }}>
                <center>
                  <p className="text-white" style={{ fontSize: '25px' }}>
                    Sign up on AdaptiveScale Hub
                  </p>
                </center>
              </div>
            </div>
          </div>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              x="0"
              y="0"
              viewBox="0 0 2560 100"
              preserveAspectRatio="none"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>
        <div className="background" style={{ background: '#172B4D' }}>
          <div className="container mt--8" style={{ height: '100vh' }}>
            <div className="row justify-content-center">
              <div className="row justify-content-center">
                <div className="card bg-secondary shadow border-0">
                  <div className="card-header bg-transparent pb-5">
                    <div className="text-muted text-center mt-2 mb-3">
                      <small>Sign up with</small>
                    </div>
                    <div className="btn-wrapper text-center">
                      <button
                        className="btn btn-neutral btn-icon"
                        onClick={(event) => this.gitHubSignIn()}
                      >
                        <span className="btn-inner--icon">
                          <img alt="" src={Assets.images.github} />
                        </span>
                        <span className="btn-inner--text">Github</span>
                      </button>
                      <button
                        className="btn btn-neutral btn-icon"
                        onClick={(event) => this.googleSignIn()}
                      >
                        <span className="btn-inner--icon">
                          <img alt="" src={Assets.images.google} />
                        </span>
                        <span className="btn-inner--text">Google</span>
                      </button>
                    </div>
                  </div>
                  <div className="card-body px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Or sign up with credentials</small>
                    </div>
                    <form onSubmit={(event) => this.handleSubmit(event)}>
                      <div className="form-group mb-3">
                        <FormControl
                          className="input-group bg-white"
                          variant="outlined"
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Email
                          </InputLabel>
                          <OutlinedInput
                            name="email"
                            label="Email"
                            value={email}
                            onChange={(event) => this.handleChange(event)}
                            id="standard-start-adornment"
                          />
                        </FormControl>
                      </div>
                      <div className="form-group mb-3">
                        <FormControl
                          className="input-group bg-white"
                          variant="outlined"
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Password
                          </InputLabel>
                          <OutlinedInput
                            value={password}
                            name="password"
                            label="Password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            onChange={(event) => this.handleChange(event)}
                            id="standard-start-adornment"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    this.handleClickShowPassword();
                                  }}
                                  edge="end"
                                >
                                  {this.state.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>

                      <div className="form-group mb-3">
                        <FormControl
                          className="input-group bg-white"
                          variant="outlined"
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Confirm password
                          </InputLabel>
                          <OutlinedInput
                            value={confirmPassword}
                            name="confirmPassword"
                            label="Confirm password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            onChange={(event) => this.handleChange(event)}
                            id="standard-start-adornment"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    this.handleClickShowPassword();
                                  }}
                                  edge="end"
                                >
                                  {this.state.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary my-4"
                          disabled={this.state.loading}
                        >
                          Sign Up
                        </button>
                      </div>
                    </form>
                    <div className="row mt-3">
                      <div className="col-6"></div>
                      <div className="col-6 text-right">
                        <Link to="/login" className="text-light">
                          <small>Sign in</small>
                        </Link>
                      </div>
                    </div>
                    <div className="mg-top20">
                      {this.state.errors.length > 0 && (
                        <div
                          className="login-error"
                          style={{ color: '#d86c6c' }}
                        >
                          {this.displayErrors(this.state.errors)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateFromProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  isLoading: state.user.isLoading,
  error: state.user.error,
});

const mapActionsToProps = {
  setUser: userActions.setUser,
  gitHubSignIn: userActions.gitHubSignIn,
  googleSignIn: userActions.googleSignIn,
  regularSignUp: userActions.regularSignUp,
  setErrorToNull: userActions.setErrorToNull,
  setPrice: shoppingCart.setPrice,
};

export default connect(mapStateFromProps, mapActionsToProps)(Register);
