import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers/reducers';
import { Provider, connect } from 'react-redux';
import thunk from 'redux-thunk';
import { userActions, oss, shoppingCart } from './actions';
import App from './App';
import firebase from './firebase';
const store = createStore(rootReducer, applyMiddleware(thunk));

class Root extends Component {
  componentDidMount = () => {
    firebase.auth().onAuthStateChanged( (user) => {
      if (user) {
        this.props.fetchUserProfile(user);
      } else {
        this.props.setUserState(null, null, null);
        // this.props.fetchOss(null, null);
        this.props.fetchPagination(null, null);

      }
    });
  };

  render() {
    return <App />;
  }
}

const mapStateFromProps = (state) => ({
  isLoading: state.user.isLoading,
  userLoggedIn: state.user.userLoggedIn,
  isUserChecked: state.user.isUserChecked,
});

const mapActionsToProps = {
  setUser: userActions.setUser,
  clearUser: userActions.clearUser,
  loggedIn: userActions.loggedIn,
  setUserState: userActions.setUserState,
  fetchOss: oss.fetchOSS,
  fetchPagination: oss.fetchPagination,
  fetchAlreadyPurchased: shoppingCart.fetchAlreadyPurchased,
  fetchUserProfile: userActions.fetchUserProfile,
};

const RootWithAuth = withRouter(
  connect(mapStateFromProps, mapActionsToProps)(Root)
);
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <RootWithAuth />
    </Router>
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
