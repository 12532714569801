import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import assets from '../../assets';
import { connect } from 'react-redux';
import firebase from '../../firebase';
import { activeState, shoppingCart } from '../../actions';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Tooltip from '@material-ui/core/Tooltip';

class Navbar extends Component {
  state = {
    open: false,
  };

  handleClose = () => {
    this.setState({ open: !this.state.open });
  };

  logOutAndReloud = () => {
    firebase.auth().signOut();
    this.props.clearShoppingCart();
    this.props.setActiveTab('all');
    this.props.history.push('/');
  };

  signOut = () => {
    return (
      <div className="mg-left-17">
        <span className="avatar avatar-sm rounded-circle">
          <img
            src={this.props.currentUser.photoURL || assets.images.defaultUser}
            onClick={() => {
              this.handleClose();
            }}
            style={{ cursor: 'pointer' }}
            alt="User Profile"
          />
        </span>
        <Popper
          style={{ top: 'none', left: 'none', marginLeft: '-80px'}}
          open={this.state.open}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    this.setState({ open: !this.state.open });
                  }}
                >
                  <MenuList autoFocusItem={this.state.open} id="menu-list-grow">
                    <MenuItem
                      onClick={() => {
                        this.props.history.push('/userProfile');
                        this.setState({ open: !this.state.open });
                      }}
                    >
                      User Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.logOutAndReloud();
                        this.setState({ open: !this.state.open });
                      }}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  signIn = () => {
    return (
      <div className="media align-items-center">
        <div className="media-body ml-2 d-none d-lg-block">
          <Link
            to="/login"
            title="SignIn"
            className="mb-0 text-sm text-black font-weight-bold mx-3"
          >
            Login
          </Link>
        </div>
      </div>
    );
  };

  render() {
    const itemCount = this.props.shoppingCartData.length;
    return (
      <nav
        className="navbar navbar-top navbar-expand-md navbar-dark"
        id="navbar-main"
      >
        <div className="container-fluid">
          <div><img
            className="logo-adaptivescale"
            src={assets.images.adaptiveScale}
            alt=""
            onClick={() => {
              this.props.history.push('/');
              this.props.setActiveTabPipeline('plugins');
            }}
          />
          {/* <sup>BETA</sup> */}
          </div>
          <ul className="navbar-nav align-items-center d-none d-md-flex">
            <li>
              <Tooltip title="Shopping Cart">
              <label
                style={{ cursor: 'pointer' }}
                // title="ShoppingCartList"
                className="mb-0 text-sm text-black font-weight-bold"
                onClick={() => this.props.history.push('/shoppingCart')}
              >
                <i
                  className="fa fa-shopping-cart fa-2x"
                  style={{ marginRight: '5px' }}
                />
              </label>
              </Tooltip>
            </li>
            <li>
              <button className="btn-shoppingCart-nr">
                <p style={{ color: 'white', fontSize: '13px' }}>{itemCount}</p>
              </button>
            </li>
            <li className="nav-item dropdown">
              {this.props.userLoggedIn ? this.signOut() : this.signIn()}
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  userLoggedIn: PropTypes.bool,
  currentUser: PropTypes.object,
  shoppingCartData: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  shoppingCartData: state.shoppingCart.data,
});

const mapActionToProps = {
  setActiveTab: activeState.setActiveTab,
  clearShoppingCart: shoppingCart.clearShoppingCart,
  setActiveTabPipeline: activeState.setActiveTabPipeline,
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(Navbar));
