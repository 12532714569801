import React, { Component } from 'react';
import { connect } from 'react-redux';
import { transferPipelines, pipelines } from '../../../actions';
import { groupBy, map } from 'lodash';

class BatchTransferResponse extends Component {
  artifats_caltulation_status = (property) => {
    const { deployingResponse } = this.props;
    const artifactStatus = groupBy(
      deployingResponse[property]['deployed_plugin'],
      'status.code'
    );
    return (
      <div>
        {artifactStatus[200] !== undefined && (
          <li>
            The following missing artifacts were added:{' '}
            {map(artifactStatus[200], 'name').join(', ')}
          </li>
        )}
        {artifactStatus[403] !== undefined && (
          <li>
            {map(artifactStatus[403], 'status.message')}
          </li>
        )}
        {artifactStatus[404] !== undefined && (
          <li>
            {map(artifactStatus[404], 'status.message')}
          </li>
        )}
      </div>
    );
  };

  addedArtifacts = (property) => {
    const artifacts = this.artifacts_calculations(property);
    const addedArtifacts = map(artifacts, function(el) {
      return el.deployed_successfully;
    }).join(', ');
    return addedArtifacts;
  };

  responseHandler = () => {
    const { deployingResponse } = this.props;
    const items = Object.keys(deployingResponse).map((property, index) => {
      return (
        <div key={index} className="batch-transfer-status row">
          <div style={{ width: '80%' }}>
            <p>{property}</p>
            <ul style={{ margin: '10px 0 0 0' }}>
              {deployingResponse[property]['deployed_plugin'].length === 0 ? (
                // <li>No artifact was added</li>
                ''
              ) : (
                this.artifats_caltulation_status(property)
                // <li>
                //   The following missing artifacts were added:{' '}
                //   {this.artifats_caltulation_status(property)}.
                // </li>
              )}
              <li>
                {deployingResponse[property]['pipeline_status']['message']}
              </li>
            </ul>
          </div>
          <div style={{ width: '20%', position: 'relative' }}>
            {deployingResponse[property]['pipeline_status']['code'] !== 200 ? (
              <i className="fa fa-times-circle batch-x-circle"></i>
            ) : (
              <i className="fa fa-check-circle batch-check-circle"></i>
            )}
          </div>
        </div>
      );
    });
    return items;
  };

  closeHandler = async () => {
    await this.props.clearDeployResponse();
    await this.props.clearActionResponse();
    // await this.props.fetchInstancePipelines(instance_id, namespace, authToken);
    const body = document.body;
    body.style.overflow = 'auto';
  };

  render() {
    return (
      <div className="popup" style={{ overflow: 'auto' }}>
        <div className="inerPopup col-lg-6" style={{ margin: '5.75rem auto' }}>
          <div className="batch-transfer-status">
            <p style={{ fontWeight: 'bold', fontSize: '20px' }}>
              Response Status
            </p>
          </div>
          {this.responseHandler()}
          <div
            className="row"
            style={{ justifyContent: 'center', marginTop: '20px' }}
          >
            <button
              className="btn btn-light"
              onClick={() => {
                this.closeHandler();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // deployingResponse: state.transferPipelines.deployingResponse,
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  activeComponent: state.myPlugins.activeComponent,
});

const mapActionsToProps = {
  clearDeployResponse: transferPipelines.clearDeployResponse,
  clearActionResponse: pipelines.clearActionResponse,
  fetchInstancePipelines: pipelines.fetchInstancePipelines,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(BatchTransferResponse);
