import React, { Component } from 'react';
import { connect } from 'react-redux';
// import ShoppingCartHeader from '../ShoppingCart/ShoppingCartHeader';
import { activeState, pipelines } from '../../actions';
import PipelineView from './PipelineView';
import BatchTransferResponse from './BatchTrasferResponse';
import CheckBeforeDepRes from './CheckBeforeDepRes';
import ResponseErrorPopup from './ResponseErrorPopup';
import Properties from '../../config/properties';
import TimeAgo from 'react-timeago';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grow from '@material-ui/core/Grow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Popper from '@material-ui/core/Popper';
import CircularProgress from '@material-ui/core/CircularProgress';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

class Pipelines extends Component {
  state = {
    open: false,
    download_tasks: [],
  };

  downloadTask = async (task) => {
    let exported_file = task.response['result']
    window.open(exported_file);
    await fetch(
      `${Properties.API_HOST}u/${this.props.currentUser.uid}/task-id/${this.state.download_tasks[0]}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.props.currentUser.ma,
        },
      }
    );
    this.state.download_tasks.splice(0, 1);
  };

  longPooling = async () => {
    this.props.getQueueTasks(
      this.props.currentUser.uid,
      this.props.currentUser.ma
    );
    if (this.state.download_tasks.length > 0) {
      const task = this.props.queueTasks.find(
        (element) => element.uniqueId === this.state.download_tasks[0]
      );
      if (task.response) {
        await this.downloadTask(task);
      }
    }
  };

  componentDidMount = () => {
    this.timer = setInterval(() => this.longPooling(), 3000);
  };

  componentWillUnmount = () => {
    this.timer = clearInterval(this.timer);
  };

  batchDeploymentResponse = (responseData) => {
    const body = document.body;
    body.style.overflow = 'hidden';
    return <BatchTransferResponse deployingResponse={responseData} />;
  };

  checkBatchDeploymentResponse = (responseData) => {
    const body = document.body;
    body.style.overflow = 'hidden';
    return <CheckBeforeDepRes deployingResponse={responseData} />;
  };

  pipelineNavHandler = (event, value) => {
    if (value === 'pipelines') {
      this.props.setActiveTabPipeline(value);
      this.props.history.push('/pipelines');
      return;
    }
    this.props.setActiveTabPipeline(value);
    this.props.history.push('/');
    return;
  };

  updateNotifications = () => {
    const notification_tasks = this.props.queueTasks
      .filter((task) => task.notification_show)
      .map((task) => task.uniqueId);
    if (notification_tasks.length > 0) {
      fetch(
        `${Properties.API_HOST}u/${this.props.currentUser.uid}/update-notification`,
        {
          method: 'POST',
          body: JSON.stringify(notification_tasks),
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.props.currentUser.ma,
          },
        }
      );
    }
    this.setState({ open: !this.state.open });
  };

  notificationNumber = () => {
    if (this.props.queueTasks.length > 0) {
      return this.props.queueTasks.filter((task) => task.notification_show)
        .length;
    }
    return 0;
  };

  notificationText = (notificationData) => {
    switch (notificationData.task) {
      case 'git_clone_repo':
        return 'Cloning repository.';
      case 'git_upload_pipeline':
        return 'Uploading pipelines on GIT-Repo.';
      case 'git_delete_pipeline':
        return 'Deleting pipelines from GIT-Repo.';
      case 'git_export_pipelines':
        if (
          !this.state.download_tasks.includes(notificationData.uniqueId) &&
          (notificationData.status === 'pending' ||
            notificationData.status === 'processing')
        ) {
          this.state.download_tasks.push(notificationData.uniqueId);
        }
        return 'Downloading pipelines from GIT-Repo.';
      case 'git_batch_operations':
        return 'Transfer pipelines from GIT-Repo to CDAP Instance.';
      case 'transfer_from_cdap_to_git':
        return 'Transfer pipelines from CDAP Instance to GIT-Repo.';
      default:
        return 'No tasks in queue.';
    }
  };

  render() {
    const items = this.props.queueTasks.map((notificationData, index) => {
      return (
        <div key={index}>
          <ListItem alignItems="flex-start" style={{ paddingBottom: '3px' }}>
            {notificationData.status === 'done' ? (
              <div style={{ margin: '15px 20px 0px 2px' }}>
                <CheckCircleOutlineIcon
                  color="primary"
                  style={{ width: '1.2em', height: '1.2em' }}
                />
              </div>
            ) : notificationData.status === 'pending' ? (
              <div style={{ margin: '13px 7px 0px 0px' }}>
                <HourglassEmptyIcon
                  color="primary"
                  style={{ width: '1.2em', height: '1.2em' }}
                />
              </div>
            ) : notificationData.status === 'processing' ? (
              <div style={{ margin: '15px 20px 0px 2px' }}>
                <CircularProgress size="1.5rem" />
              </div>
            ) : (
              <div style={{ margin: '15px 20px 0px 2px' }}>
                <ErrorOutlineIcon
                  color="error"
                  style={{ width: '1.2em', height: '1.2em' }}
                />
              </div>
            )}
            <ListItemText
              primary={
                notificationData.status === 'done'
                  ? 'Task has completed.'
                  : notificationData.status === 'pending'
                  ? 'Task is pending.'
                  : notificationData.status === 'processing'
                  ? 'Task is processing.'
                  : 'Task has failed.'
              }
              secondary={
                <React.Fragment>
                  {this.notificationText(notificationData)}
                </React.Fragment>
              }
            />
          </ListItem>
          <div className="time-ago">
            <TimeAgo date={notificationData.updatedAt} />
          </div>
          <Divider variant="inset" component="li" style={{ margin: '0' }} />
        </div>
      );
    });
    this.props.setActiveTabPipeline('pipelines');
    if (this.props.currentPlan !== 'enterprise-plus') {
      return (
        <div className="header pt-md-6">
          <div className="container">
            <div className="header-body">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card-stats mb-4 mb-xl-0 pd-10">
                    <div className="card-body bg-white rounder-borders card-body-shadow">
                      <div>
                        Upgrade to enterprise plant if you want to use this
                        feature.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="header pt-md-6">
        <div className="container">
          <div className="header-body">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="card-stats mb-4 mb-xl-0 pd-10">
                  <div className="card-body bg-white rounder-borders card-body-shadow">
                    <div
                      className="col-lg-12 custom-tabs"
                      style={{ background: '#f8f9fe' }}
                    >
                      <div className="row">
                        <Paper
                          className="paper-nav-tabs col-lg-4"
                          style={{
                            background: '#f8f9fe',
                            border: 'none !important',
                          }}
                        >
                          <Tabs
                            value={this.props.activeTabPipeline}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(event, value) => {
                              this.pipelineNavHandler(event, value);
                            }}
                            aria-label="disabled tabs example"
                          >
                            <Tab label="Plugins" value="plugins" />
                            <Tab label="Pipelines" value="pipelines" />
                          </Tabs>
                        </Paper>
                        <div className="col-lg-8 text-right">
                          <Tooltip title="Notifications">
                            <IconButton
                              disabled={this.props.queueTasks.length === 0}
                              aria-label="cart"
                              // style={{ padding: '17px 20px 7px' }}
                              onClick={() => {
                                this.updateNotifications();
                              }}
                            >
                              <Badge
                                style={{ zIndex: '0' }}
                                badgeContent={this.notificationNumber()}
                                color="primary"
                              >
                                <NotificationsIcon />
                              </Badge>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <Popper
                      style={{
                        position: 'absolute',
                        zIndex: '10',
                        right: '0',
                        top: 'inherit',
                        left: 'inherit',
                      }}
                      open={this.state.open}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom'
                                ? 'center top'
                                : 'center bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener
                              onClickAway={() => {
                                this.setState({ open: !this.state.open });
                              }}
                            >
                              <List
                                style={{ width: '100%', maxWidth: '"36ch' }}
                              >
                                {items}
                              </List>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                    {this.props.pipelineDataError !== null && (
                      <ResponseErrorPopup
                        textToShow={this.props.pipelineDataError.message}
                      />
                    )}
                    <div className="card-body">
                      <PipelineView history={this.props.history} />
                      {this.props.deployingResponse !== null &&
                        this.batchDeploymentResponse(
                          this.props.deployingResponse
                        )}
                      {this.props.actionsResponse !== null &&
                        this.batchDeploymentResponse(
                          this.props.actionsResponse
                        )}
                      {this.props.checkDeployingResponse !== null &&
                        this.checkBatchDeploymentResponse(
                          this.props.checkDeployingResponse
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  currentPlan: state.user.currentPlan,
  deployingResponse: state.transferPipelines.deployingResponse,
  checkDeployingResponse: state.transferPipelines.checkDeployingResponse,
  actionsResponse: state.pipelines.actionsResponse,
  activeTabPipeline: state.activeState.activeTabPipeline,
  pipelineDataError: state.pipelines.namespaceError,
  queueTasks: state.pipelines.queueTasks,
  ...ownProps,
});
const mapActionsToProps = {
  setActiveTabPipeline: activeState.setActiveTabPipeline,
  getQueueTasks: pipelines.getQueueTasks,
};
export default connect(mapStateToProps, mapActionsToProps)(Pipelines);
