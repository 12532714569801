import React, { Component } from 'react';
import { connect } from 'react-redux';
import assets from '../../../assets';
import PaymentFailed from '../PaymentFaild';
import { LoadingSpinner } from '../../../components';

class CheckoutReceipt extends Component {
  convertDate = (unixTimestamp) => {
    const dateObj = new Date(unixTimestamp * 1000);
    const utcString = dateObj.toUTCString();
    return utcString;
  };

  render() {
    const { error } = this.props;
    const { chargeRes } = this.props;
    if (error !== null) {
      return (
        <div>
          <PaymentFailed history={this.props.history} />
        </div>
      );
    }
    if (chargeRes === null) {
      return <LoadingSpinner textToShow="Loading..." />;
    }
    return (
      <div className="row">
        <div className="col-lg-3">
          <img alt="Placeholder" src={assets.images.checkIcon} />
        </div>
        <div className="col-lg-7 payment">
          <div className="card-stats mb-4 mb-xl-0 pd-10">
            <div className="card-body bg-color-shoppingList rounder-borders">
              <div className="col-lg-12">
                <h2 className="text-center">Payment Successful!</h2>
                <h3 className="text-center">
                  Thank you! Your payment has been received.
                </h3>
                <br />
                <br />
                <h4>Date: {this.convertDate(chargeRes.created)}</h4>
                <h4>Status: {chargeRes.status}</h4>
                <h4>
                  Card ending: {chargeRes.payment_method_details.card.last4}
                </h4>
                <h4>Amount: ${chargeRes.amount / 100}</h4>
                <br />
                <div className="text-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.props.history.push('/');
                    }}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      window.print();
                    }}
                  >
                    Print
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  price: state.shoppingCart.price,
  chargeRes: state.shoppingCart.chargeRes,
  error: state.shoppingCart.error,
  ...ownProps,
});
export default connect(mapStateToProps)(CheckoutReceipt);
