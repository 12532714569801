import {
    get as getByKey,
} from 'lodash';

const pluginTypes = ['-action', '-batchsource', '-batchsink', '-splittertransform',
    '-streamingsource', '-transform', '-postaction', '-sparksink', '-sparkcompute',
    '-sparkprogram', '-batchaggregator', '-batchjoiner',  '-alertpublisher', '-windower',
    '.json', '.png', '.md', '.jar'
];

const extensions = ['.json', '.md', '.jar','.png'];

export const stripPluginSuffixPrefix = (plugName) => {
    let newPluginName = plugName;
    pluginTypes.forEach((suffixPrefix) => {
        newPluginName = newPluginName.replace(suffixPrefix, '');
    });
    return newPluginName;
};

export const stripExtension = (name) =>{
    let newName = name;
    extensions.forEach((extension)=>{
        newName = newName.replace(extension, '');
    });
    return newName;
};

export const mapWidgetNamesToWigetRoot = (widgets) => {
    return widgets.map((widget) => ({
        ...widget,
        widgetName: getByKey(widget, 'data.configuration-groups[0].label', null) || widget.name,
    }));
};