import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pipelines } from '../../../../actions';
import clsx from 'clsx';
import CommitMessage from '../../CommitMessage';
import { withStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TransferPopup from '../../TransferPopup';

// import TextField from '@material-ui/core/TextField';
// import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';

const styles = (theme) => ({
  root: {
    paddingLeft: '10px',
    paddingRight: '5px',
  },
  highlight: '#ebedf7'
    ? {
        backgroundColor: '#ebedf7',
        borderRadius: '3px',
      }
    : {
        color: 'primary.main',
        backgroundColor: '#bac6d1',
      },
  title: {
    flex: '1 1 45%',
  },
});

class ActionsToolbar extends Component {
  state = {
    commitPopup: false,
    actionType: null,
    openMenu: false,
    anchorEl: null,
    transferPopup: false,
  };

  transferPopupHandler = () => {
    this.setState({
      actionType: 'git-transfer',
      transferPopup: !this.state.transferPopup,
    });
  };

  deleteHandler = () => {
    this.setState({
      actionType: 'delete',
      commitPopup: !this.state.commitPopup,
    });
  };

  uploadHandler = () => {
    this.setState({
      actionType: 'upload',
      commitPopup: !this.state.commitPopup,
    });
  };

  parse = async (file) => {
    const reader = new FileReader();
    reader.readAsText(file);
    const result = await new Promise((resolve, reject) => {
      reader.onload = function(event) {
        resolve(JSON.parse(reader.result));
      };
    });
    return result;
  };

  setMultiplePipelines = async (files) => {
    const uploadedPipelines = Promise.all(
      Array.from(files).map(async (file) => await this.parse(file))
    );
    return await uploadedPipelines;
  };

  uploadPipeline = async (event) => {
    // const { instanceData } = this.props;
    const files = event.target.files;
    const files_data = await this.setMultiplePipelines(files);
    this.props.setCommitedData(files_data, this.props.instanceIndex);
    this.uploadHandler();
  };

  deletePipelines = () => {
    const { instanceData } = this.props;
    return (
      <div className="popup">
        <div className="inerPopup text-center" style={{ padding: '50px' }}>
          <p style={{ fontWeight: 'bold' }}>Are you sure you want to delete?</p>
          <div
            className="row"
            style={{ justifyContent: 'center', marginTop: '20px' }}
          >
            <button
              className="btn btn-primary"
              onClick={async () => {
                this.setState({
                  deletePopUp: !this.state.deletePopUp,
                });
                await this.props.deletePipelines(
                  this.props.currentUser.ma,
                  instanceData.uniqueId,
                  instanceData.currentNamespace,
                  instanceData.checkedPipelines
                );
                await this.props.fetchInstancePipelines(
                  instanceData.uniqueId,
                  instanceData.currentNamespace,
                  this.props.currentUser.ma
                );
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-light"
              onClick={() =>
                this.setState({
                  deletePopUp: !this.state.deletePopUp,
                })
              }
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { classes, instanceData, numSelected } = this.props;
    return (
      <div>
        <Toolbar className={clsx(classes.root, { [classes.highlight]: true })}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Actions
          </Typography>
          {numSelected > 0 && (
            <div className="row" style={{ margin: '0' }}>
              <Tooltip
                title={
                  numSelected > 1
                    ? 'Download as ZIP file.'
                    : 'Download as JSON file.'
                }
              >
                <IconButton
                  aria-label="export"
                  onClick={async () => {
                    await this.props.gitExportPipelines(
                      this.props.currentUser.uid,
                      this.props.currentUser.ma,
                      instanceData.uniqueId,
                      {
                        checkedPipelines: instanceData.checkedPipelines,
                        branch: instanceData.currentBranch,
                      }
                    );
                    // await this.props.fetchInstancePipelines(
                    //   instanceData.uniqueId,
                    //   instanceData.currentNamespace,
                    //   this.props.currentUser.ma
                    // );
                  }}
                >
                  <i className="far fa-file-archive"></i>
                  <i
                    className={
                      numSelected > 1
                        ? 'fa fa-file-archive-o'
                        : 'fa fa-download'
                    }
                  ></i>
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    this.deleteHandler();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Transfer">
                <IconButton
                  aria-label="transfer"
                  onClick={async () => {
                    await this.transferPopupHandler();
                  }}
                >
                  <i className="fa fa-exchange"></i>
                </IconButton>
              </Tooltip>
            </div>
          )}
          <div className="gitUploadPipeline">
            {' '}
            <input
              id={'gitUploadPlugin' + this.props.instanceIndex}
              type="file"
              onChange={this.uploadPipeline}
              accept=".json"
              className="hide-input"
              multiple
            />
            <label
              htmlFor={'gitUploadPlugin' + this.props.instanceIndex}
              style={{ marginBottom: '0px' }}
            >
              <Tooltip title="Upload pipelines">
                <IconButton component="span">
                  <i className="fa fa-upload" />
                </IconButton>
              </Tooltip>
            </label>
          </div>
        </Toolbar>
        {this.state.commitPopup && (
          <CommitMessage
            actionType={this.state.actionType}
            deleteHandler={this.deleteHandler.bind(this)}
            uploadHandler={this.uploadHandler.bind(this)}
            instanceData={instanceData}
            instanceIndex={this.props.instanceIndex}
          />
        )}
        {this.state.transferPopup && (
          <TransferPopup
            actionType={this.state.actionType}
            transferPopupHandler={this.transferPopupHandler.bind(this)}
            instanceData={instanceData}
            instanceIndex={this.props.instanceIndex}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pipelineData: state.pipelines.data,
  instanceNamespaces: state.pipelines.instanceNamespaces,
  nameSpace: state.pipelines.nameSpace,
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  activeComponent: state.myPlugins.activeComponent,
  isLoading: state.pipelines.isLoading,
  pipeline_data_instance_id: state.pipelines.instance_id,
});

const mapActionsToProps = {
  setNameSpace: pipelines.setNameSpace,
  fetchInstancePipelines: pipelines.fetchInstancePipelines,
  resetPipelineData: pipelines.resetPipelineData,
  fetchInstances: pipelines.fetchInstances,
  getNamespaces: pipelines.getNamespaces,
  handleExpand: pipelines.handleExpand,
  exportAllPipelines: pipelines.exportAllPipelines,
  setCommitedData: pipelines.setCommitedData,
  gitExportPipelines: pipelines.gitExportPipelines,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(ActionsToolbar));
