import {
  INSTANCE_PIPELINES_FETCH_BEGIN,
  INSTANCE_PIPELINES_FETCH_SUCCESS,
  INSTANCE_PIPELINES_FETCH_FAIL,
  RESET_PIPELINE_DATA,
  ADD_INSTANCE_BEGIN,
  ADD_INSTANCE_FAIL,
  ADD_INSTANCE_SUCCESS,
  FETCH_INSTANCE_BEGIN,
  FETCH_INSTANCE_SUCCESS,
  FETCH_INSTANCE_FAIL,
  EDIT_INSTANCE_BEGIN,
  EDIT_INSTANCE_SUCCESS,
  EDIT_INSTANCE_FAIL,
  DELETE_INSTANCE_BEGIN,
  DELETE_INSTANCE_SUCCESS,
  DELETE_INSTANCE_FAIL,
  RESET_PIPELINE_ERROR,
  SET_NAME_SPACE,
  GET_NAMESPACES_BEGIN,
  GET_NAMESPACES_SUCCESS,
  GET_NAMESPACES_FAIL,
  SET_CHECKED_PIPELINES,
  SET_INSTANCE_ID,
  EXPORT_PIPELINES_BEGIN,
  EXPORT_PIPELINES_FAIL,
  EXPORT_PIPELINES_SUCCESS,
  FILTER_PIPELINES,
  HANDLE_EXPAND,
  DELETE_PIPELINES_BEGIN,
  DELETE_PIPELINES_FAIL,
  DELETE_PIPELINES_SUCCESS,
  CLEAR_ACTION_RESPONSE,
  START_PIPELINES_BEGIN,
  START_PIPELINES_FAIL,
  START_PIPELINES_SUCCESS,
  DENSE_PADDING,
  UPLOAD_PIPELINE_BEGIN,
  UPLOAD_PIPELINE_SUCCESS,
  UPLOAD_PIPELINE_FAIL,
  EXPORT_ALL_PIPELINES_BEGIN,
  EXPORT_ALL_PIPELINES_SUCCESS,
  EXPORT_ALL_PIPELINES_FAIL,
  GIT_INSTANCE_EXPAND_TREE,
  COMMIT_PIPELINE_UPLOAD_BEGIN,
  COMMIT_PIPELINE_UPLOAD_FAIL,
  COMMIT_PIPELINE_UPLOAD_SUCCESS,
  COMMIT_PIPELINE_DELETE_BEGIN,
  COMMIT_PIPELINE_DELETE_FAIL,
  COMMIT_PIPELINE_DELETE_SUCCESS,
  SET_COMMITED_DATA,
  FETCH_JSON_TREE_BEGIN,
  FETCH_JSON_TREE_SUCCESS,
  FETCH_JSON_TREE_FAIL,
  CLONE_REPO_BEGIN,
  CLONE_REPO_FAIL,
  CLONE_REPO_SUCCESS,
  RESET_GIT_ITEM_DATA,
  GIT_EXPORT_BEGIN,
  GIT_EXPORT_SUCCESS,
  GIT_EXPORT_FAIL,
  SET_ERROR_MESSAGE,
  UPDATE_GIT_INSTANCE_BEGIN,
  UPDATE_GIT_INSTANCE_SUCCESS,
  UPDATE_GIT_INSTANCE_FAIL,
  GET_QUEUE_TASKS_SUCCESS,
  GET_QUEUE_TASKS_FAIL,
} from './types';
import Properties from '../config/properties';

const fetchInstancePipelinesBegin = () => ({
  type: INSTANCE_PIPELINES_FETCH_BEGIN,
});

const fetchInstancePipelinesSuccess = (data, namespace) => ({
  type: INSTANCE_PIPELINES_FETCH_SUCCESS,
  payload: { data, namespace },
});

const fetchInstancePipelinesFail = (error) => ({
  type: INSTANCE_PIPELINES_FETCH_FAIL,
  payload: { error },
});

export const fetchInstancePipelines = (instance_id, namespace, authToken) => {
  return async (dispatch) => {
    try {
      dispatch(setInstanceId(instance_id));
      dispatch(fetchInstancePipelinesBegin());
      const res = await fetch(
        `${Properties.API_HOST}i/${instance_id}/ns/${namespace}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      if (res.status === 200) {
        const response = await res.json();
        return dispatch(fetchInstancePipelinesSuccess(response, namespace));
      } else {
        const error = await res.json();
        return dispatch(fetchInstancePipelinesFail(error.message));
      }
    } catch (error) {
      return dispatch(fetchInstancePipelinesFail(error));
    }
  };
};

export const resetPipelineData = () => ({
  type: RESET_PIPELINE_DATA,
  payload: null,
});

const addInstanceBegin = () => ({
  type: ADD_INSTANCE_BEGIN,
});

const addInstanceSuccess = (response) => ({
  type: ADD_INSTANCE_SUCCESS,
  payload: { response },
});

const addInstanceFail = (error) => ({
  type: ADD_INSTANCE_FAIL,
  payload: { error },
});

export const addInstance = (state, userId, authToken) => {
  return async (dispatch) => {
    try {
      dispatch(addInstanceBegin());
      const res = await fetch(`${Properties.API_HOST}u/${userId}/instance`, {
        method: 'POST',
        body: JSON.stringify(state),
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      });
      if (res.status === 200) {
        const response = await res.json();
        if (state.type === 'GIT') {
          await dispatch(
            cloneRepo(userId, authToken, response.unique_id, { branch: null })
          );
        }
        dispatch(addInstanceSuccess(response));
        return response;
      } else {
        const error = await res.json();
        return dispatch(addInstanceFail(error.message));
      }
    } catch (error) {
      return dispatch(addInstanceFail(error));
    }
  };
};

const fetchInstancesBegin = () => ({
  type: FETCH_INSTANCE_BEGIN,
});

const fetchInstancesSuccess = (data) => ({
  type: FETCH_INSTANCE_SUCCESS,
  payload: { data },
});

const fetchInstanceFail = (error) => ({
  type: FETCH_INSTANCE_FAIL,
  payload: { error },
});

export const fetchInstances = (userId, authToken) => {
  return async (dispatch) => {
    try {
      dispatch(fetchInstancesBegin());
      const res = await fetch(`${Properties.API_HOST}u/${userId}/instances`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      });
      const response = await res.json();
      dispatch(fetchInstancesSuccess(response));
      // if (response.length !== 0 ){
      //   dispatch(await getNamespaces(response[0].uniqueId, authToken))
      // }
      return response;
    } catch (error) {
      return dispatch(fetchInstanceFail(error));
    }
  };
};

const editInstanceBegin = () => ({
  type: EDIT_INSTANCE_BEGIN,
});

const editInstanceSuccess = (response) => ({
  type: EDIT_INSTANCE_SUCCESS,
  payload: { response },
});

const editInstanceFail = (error) => ({
  type: EDIT_INSTANCE_FAIL,
  payload: { error },
});

export const editInstance = (state, userInstance, userId, authToken) => {
  const body = { editedData: state, instanceToEdit: userInstance };
  return async (dispatch) => {
    try {
      dispatch(editInstanceBegin());
      const res = await fetch(
        `${Properties.API_HOST}u/${userId}/instance/${userInstance}/edit`,
        {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      if (res.status === 200) {
        const response = await res.json();
        if (state.type === 'GIT') {
          await dispatch(
            cloneRepo(userId, authToken, body.instanceToEdit, { branch: null })
          );
        }
        dispatch(editInstanceSuccess(response));
        return response;
      } else {
        const error = await res.json();
        return dispatch(editInstanceFail(error.message));
      }
    } catch (error) {
      return dispatch(editInstanceFail(error));
    }
  };
};

const deleteInstanceBegin = () => ({
  type: DELETE_INSTANCE_BEGIN,
});

const deleteInstanceSuccess = (response) => ({
  type: DELETE_INSTANCE_SUCCESS,
  payload: { response },
});

const deleteInstanceFail = (error) => ({
  type: DELETE_INSTANCE_FAIL,
  payload: { error },
});

export const deleteInstance = (instance, userId, authToken) => {
  return async (dispatch) => {
    try {
      dispatch(deleteInstanceBegin());
      const res = await fetch(
        `${Properties.API_HOST}u/${userId}/instance/${instance.instance_id}`,
        {
          method: 'DELETE',
          body: JSON.stringify(instance),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      const response = await res.json();
      dispatch(deleteInstanceSuccess(response));
      return response;
    } catch (error) {
      return dispatch(deleteInstanceFail(error));
    }
  };
};

export const clearError = () => ({
  type: RESET_PIPELINE_ERROR,
  payload: null,
});

export const setNameSpace = (ns) => ({
  type: SET_NAME_SPACE,
  payload: ns,
});

const getNamespacesBegin = () => ({
  type: GET_NAMESPACES_BEGIN,
});

const getNamespacesSuccess = (data) => ({
  type: GET_NAMESPACES_SUCCESS,
  payload: { data },
});

const getNamespacesFail = (error) => ({
  type: GET_NAMESPACES_FAIL,
  payload: { error },
});

export const getNamespaces = (instance_id, authToken) => {
  return async (dispatch) => {
    try {
      dispatch(getNamespacesBegin());
      dispatch(setInstanceId(instance_id));
      const res = await fetch(
        `${Properties.API_HOST}i/${instance_id}/namespaces`,
        {
          method: 'POST',
          // body: JSON.stringify(instance),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      const response = await res.json();
      if (res.status === 200) {
        dispatch(getNamespacesSuccess(response));
      } else {
        dispatch(getNamespacesFail(response));
      }
      return response;
    } catch (error) {
      return dispatch(
        getNamespacesFail({ message: 'Instance its not running.' })
      );
    }
  };
};

export const setCheckedPipelines = (data, index) => ({
  type: SET_CHECKED_PIPELINES,
  payload: { data: data, index: index },
});

export const gitInstanceExpandTree = (data, index) => ({
  type: GIT_INSTANCE_EXPAND_TREE,
  payload: { data: data, index: index },
});

export const setInstanceId = (data) => ({
  type: SET_INSTANCE_ID,
  payload: data,
});

export const filterPipelines = (keyword, index) => ({
  type: FILTER_PIPELINES,
  payload: { keyword: keyword, index: index },
});

const exportPipelinesBegin = () => ({
  type: EXPORT_PIPELINES_BEGIN,
});

const exportPipelinesSuccess = (response) => ({
  type: EXPORT_PIPELINES_SUCCESS,
  payload: { response },
});

const exportPipelinesFail = (error) => ({
  type: EXPORT_PIPELINES_FAIL,
  payload: { error },
});

export const exportPipelines = (
  authToken,
  instance_id,
  namespace,
  pipelineList
) => {
  return async (dispatch) => {
    try {
      dispatch(exportPipelinesBegin());
      const response = await fetch(
        `${Properties.API_HOST}i/${instance_id}/ns/${namespace}/export`,
        {
          method: 'POST',
          body: JSON.stringify(pipelineList),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      const blob = await response.blob();
      // response.headers.forEach(console.log)
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = response.headers
        .get('Content-Disposition')
        .replace('attachment;filename=', '');
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
      dispatch(exportPipelinesSuccess(response));
      return response;
    } catch (error) {
      return dispatch(exportPipelinesFail(error));
    }
  };
};

const exportAllPipelinesBegin = () => ({
  type: EXPORT_ALL_PIPELINES_BEGIN,
});

const exportAllPipelinesSuccess = (response) => ({
  type: EXPORT_ALL_PIPELINES_SUCCESS,
  payload: { response },
});

const exportAllPipelinesFail = (error) => ({
  type: EXPORT_ALL_PIPELINES_FAIL,
  payload: { error },
});

export const exportAllPipelines = (authToken, instance_id) => {
  return async (dispatch) => {
    try {
      dispatch(exportAllPipelinesBegin());
      const response = await fetch(
        `${Properties.API_HOST}i/${instance_id}/export-all`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      const blob = await response.blob();
      // response.headers.forEach(console.log)
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = response.headers
        .get('Content-Disposition')
        .replace('attachment;filename=', '');
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
      dispatch(exportAllPipelinesSuccess(response));
      return response;
    } catch (error) {
      return dispatch(exportAllPipelinesFail(error));
    }
  };
};

const deletePipelinesBegin = () => ({
  type: DELETE_PIPELINES_BEGIN,
});

const deletePipelinesSuccess = (response) => ({
  type: DELETE_PIPELINES_SUCCESS,
  payload: { response },
});

const deletePipelinesFail = (error) => ({
  type: DELETE_PIPELINES_FAIL,
  payload: { error },
});

export const deletePipelines = (
  authToken,
  instance_id,
  namespace,
  pipelineList
) => {
  return async (dispatch) => {
    try {
      dispatch(deletePipelinesBegin());
      const res = await fetch(
        `${Properties.API_HOST}i/${instance_id}/ns/${namespace}/delete-pipelines`,
        {
          method: 'POST',
          body: JSON.stringify(pipelineList),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      if (res.status === 200) {
        const response = await res.json();
        dispatch(deletePipelinesSuccess(response));
        return response;
      } else {
        const error = await res.json();
        return dispatch(deletePipelinesFail(error.message));
      }
    } catch (error) {
      return dispatch(deletePipelinesFail(error));
    }
  };
};

const startPipelinesBegin = () => ({
  type: START_PIPELINES_BEGIN,
});

const startPipelinesSuccess = (response) => ({
  type: START_PIPELINES_SUCCESS,
  payload: { response },
});

const startPipelinesFail = (error) => ({
  type: START_PIPELINES_FAIL,
  payload: { error },
});

export const startPipelines = (
  authToken,
  instance_id,
  namespace,
  pipelineList
) => {
  return async (dispatch) => {
    try {
      dispatch(startPipelinesBegin());
      const res = await fetch(
        `${Properties.API_HOST}i/${instance_id}/ns/${namespace}/start-pipelines`,
        {
          method: 'POST',
          body: JSON.stringify(pipelineList),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      if (res.status === 200) {
        const response = await res.json();
        dispatch(startPipelinesSuccess(response));
        return response;
      } else {
        const error = await res.json();
        return dispatch(startPipelinesFail(error.message));
      }
    } catch (error) {
      return dispatch(startPipelinesFail(error));
    }
  };
};

const uploadPipelineBegin = () => ({
  type: UPLOAD_PIPELINE_BEGIN,
});

const uploadPipelineSuccess = (response) => ({
  type: UPLOAD_PIPELINE_SUCCESS,
  payload: { response },
});

const uploadPipelineFail = (error) => ({
  type: UPLOAD_PIPELINE_FAIL,
  payload: { error },
});

export const uploadPipeline = (
  userId,
  authToken,
  instance_id,
  namespace,
  uploadedPipelines
) => {
  return async (dispatch) => {
    try {
      dispatch(uploadPipelineBegin());
      const res = await fetch(
        `${Properties.API_HOST}u/${userId}/i/${instance_id}/ns/${namespace}/upload-pipeline`,
        {
          method: 'POST',
          body: JSON.stringify(uploadedPipelines),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      if (res.status === 200) {
        const response = await res.json();
        dispatch(uploadPipelineSuccess(response));
        return response;
      } else {
        const error = await res.json();
        return dispatch(uploadPipelineFail(error.message));
      }
    } catch (error) {
      return dispatch(uploadPipelineFail(error));
    }
  };
};

const commitPipelineDeleteBegin = () => ({
  type: COMMIT_PIPELINE_DELETE_BEGIN,
});

const commitPipelineDeleteSuccess = (response) => ({
  type: COMMIT_PIPELINE_DELETE_SUCCESS,
  payload: { response },
});

const commitPipelineDeleteFail = (error) => ({
  type: COMMIT_PIPELINE_DELETE_FAIL,
  payload: { error },
});

export const commitPipelineDelete = (
  userId,
  authToken,
  instance_id,
  data,
  instanceIndex
) => {
  return async (dispatch) => {
    try {
      dispatch(commitPipelineDeleteBegin());
      const res = await fetch(
        `${Properties.API_HOST}u/${userId}/i/${instance_id}/commit-pipeline`,
        {
          method: 'DELETE',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      if (res.status === 200) {
        const response = await res.json();
        // await dispatch(
        //   fetchJSONTree(
        //     response['Upload pipelines on Git-Remote']['pipeline_status'][
        //       'json_tree_url'
        //     ],
        //     instanceIndex
        //   )
        // );
        dispatch(commitPipelineDeleteSuccess(response));
        return response;
      } else {
        const error = await res.json();
        return dispatch(commitPipelineDeleteFail(error.message));
      }
    } catch (error) {
      return dispatch(commitPipelineDeleteFail(error));
    }
  };
};

const commitPipelineUploadBegin = () => ({
  type: COMMIT_PIPELINE_UPLOAD_BEGIN,
});

const commitPipelineUploadSuccess = (response) => ({
  type: COMMIT_PIPELINE_UPLOAD_SUCCESS,
  payload: { response },
});

const commitPipelineUploadFail = (error) => ({
  type: COMMIT_PIPELINE_UPLOAD_FAIL,
  payload: { error },
});

export const commitPipelineUpload = (
  userId,
  authToken,
  instance_id,
  dataToStore,
  instanceIndex
) => {
  return async (dispatch) => {
    try {
      dispatch(commitPipelineUploadBegin());
      const res = await fetch(
        `${Properties.API_HOST}u/${userId}/i/${instance_id}/commit-pipeline`,
        {
          method: 'POST',
          body: JSON.stringify(dataToStore),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      if (res.status === 200) {
        const response = await res.json();
        // await dispatch(
        //   fetchJSONTree(
        //     response['Upload pipelines on Git-Remote']['pipeline_status'][
        //       'json_tree_url'
        //     ],
        //     instanceIndex
        //   )
        // );
        dispatch(commitPipelineUploadSuccess(response));
        return response;
      } else {
        const error = await res.json();
        return dispatch(commitPipelineUploadFail(error.message));
      }
    } catch (error) {
      return dispatch(commitPipelineUploadFail(error));
    }
  };
};

const fetchJSONTreeBegin = () => ({
  type: FETCH_JSON_TREE_BEGIN,
});

const fetchJSONTreeSuccess = (response, index) => ({
  type: FETCH_JSON_TREE_SUCCESS,
  payload: { data: response, index: index },
});

const fetchJSONTreeFail = (error, index) => ({
  type: FETCH_JSON_TREE_FAIL,
  payload: { error },
});

export const fetchJSONTree = (url, index, instance_id = null) => {
  return async (dispatch) => {
    try {
      if(instance_id !== null){
        dispatch(setInstanceId(instance_id));
      }
      dispatch(fetchJSONTreeBegin());
      const res = await fetch(url);
      const response = await res.json();
      dispatch(fetchJSONTreeSuccess(response, index));
    } catch (error) {
      dispatch(fetchJSONTreeFail(error, index));
    }
  };
};

export const resetGitItemData = (index) => ({
  type: RESET_GIT_ITEM_DATA,
  payload: {index},
});

const cloneRepoBegin = () => ({
  type: CLONE_REPO_BEGIN,
});

const cloneRepoSuccess = (response) => ({
  type: CLONE_REPO_SUCCESS,
  payload: { response },
});

const cloneRepoFail = (error) => ({
  type: CLONE_REPO_FAIL,
  payload: { error },
});

export const cloneRepo = (
  userId,
  authToken,
  instance_id,
  body,
) => {
  return async (dispatch) => {
    try {
      dispatch(cloneRepoBegin());
      dispatch(setInstanceId(instance_id));
      const res = await fetch(
        `${Properties.API_HOST}u/${userId}/i/${instance_id}/clone-repo`,
        {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      const response = await res.json();
      dispatch(cloneRepoSuccess(response));
      return response;
    } catch (error) {
      return dispatch(cloneRepoFail(error));
    }
  };
};

const gitExportBegin = () => ({
  type: GIT_EXPORT_BEGIN,
});

const gitExportSuccess = (response) => ({
  type: GIT_EXPORT_SUCCESS,
  payload: { response },
});

const gitExportFail = (error) => ({
  type: GIT_EXPORT_FAIL,
  payload: { error },
});

export const gitExportPipelines = (userId, authToken, instance_id, data) => {
  return async (dispatch) => {
    try {
      dispatch(gitExportBegin());
      const response = await fetch(
        `${Properties.API_HOST}u/${userId}/i/${instance_id}/git-export`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      const res = await response.json();
      // var url = window.URL.createObjectURL(blob);
      // var a = document.createElement('a');
      // a.href = url;
      // a.download = response.headers
      //   .get('Content-Disposition')
      //   .replace('attachment;filename=', '');
      // document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      // a.click();
      // a.remove(); //afterwards we remove the element again
      dispatch(gitExportSuccess(res));
      return response;
    } catch (error) {
      return dispatch(gitExportFail(error));
    }
  };
};

export const updateGitInstance = (userId, authToken, index) => {
  return async (dispatch) => {
    try {
      dispatch(updateGitInstanceBegin());
      const res = await fetch(`${Properties.API_HOST}u/${userId}/instances`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      });
      const response = await res.json();
      dispatch(updateGitInstancSuccess(response, index));
    } catch (error) {
      dispatch(updateGitInstanFail(error));
    }
  };
};

const updateGitInstanceBegin = () => ({
  type: UPDATE_GIT_INSTANCE_BEGIN,
});

const updateGitInstancSuccess = (response, index) => ({
  type: UPDATE_GIT_INSTANCE_SUCCESS,
  payload: { response, index },
});

const updateGitInstanFail = (error) => ({
  type: UPDATE_GIT_INSTANCE_FAIL,
  payload: { error },
});


export const getQueueTasks = (userId, authToken) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${Properties.API_HOST}u/${userId}/queue-tasks`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      });
      const response = await res.json();
      dispatch(getQueueTasksSuccess(response));
    } catch (error) {
      dispatch(getQueueTasksFail(error));
    }
  };
};

const getQueueTasksSuccess = (response) => ({
  type: GET_QUEUE_TASKS_SUCCESS,
  payload: { response },
});

const getQueueTasksFail = (error) => ({
  type: GET_QUEUE_TASKS_FAIL,
  payload: { error },
});


export const setErrorMessage = (error) => ({
  type: SET_ERROR_MESSAGE,
  payload: {error},
});

export const setCommitedData = (data, index) => ({
  type: SET_COMMITED_DATA,
  payload: { data: data, index: index },
});

export const clearActionResponse = () => ({
  type: CLEAR_ACTION_RESPONSE,
});

export const handleExpand = (index) => ({
  type: HANDLE_EXPAND,
  payload: { index },
});

export const densePadding = () => ({
  type: DENSE_PADDING,
});