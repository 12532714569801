import React, {Component} from "react";
import PropTypes from 'prop-types';
import ShoppingCartArticle from "../../ShoppingCartArticle"
import {connect} from "react-redux";

class ShoppingCartList extends Component {
    render() {
        const {artifact} = this.props;
        const items = artifact.map((viewItem, index) => {
            return <ShoppingCartArticle
                key={index}
                artifact={viewItem}
                goToDetails={(artifact, widget) => this.props.goToDetails(artifact, widget)}/>
        });
        return (
            <div className="col-lg-8">
                <div className="card-stats mb-4 mb-xl-0 pd-10">
                    <div className="card-body bg-color-shoppingList rounder-borders">
                        <div className="col row" style={{marginTop: "20px"}}>
                            <button className='btn btn-primary' onClick={() => {this.props.goToAllPlugins()}}>
                                Continue Shopping
                            </button>
                            <h5 style={{paddingTop: "12px"}}>{`Yout have ${items.length} items on your cart`}</h5>
                        </div>
                        <div className='row'>
                            <div className="col-lg-12">
                                <div className="card-body">
                                    <div className="col-lg-12">
                                        <div className="col-lg-12 mgTopBottom border-top pd-10">
                                            <div className="row">
                                                {items}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

ShoppingCartList.propTypes = {
    currentUser: PropTypes.object,
    authToken: PropTypes.string,
    isUserSet: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.currentUser,
    authToken: state.user.authToken,
    isUserSet: state.user.isUserSet,
    ...ownProps,
});
export default connect(mapStateToProps)(ShoppingCartList);
