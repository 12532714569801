import {
  OSS_FETCH_FAIL,
  BEGIN_OSS_FETCH,
  OSS_FETCH_SUCCESS,
  OSS_SEARCH,
  SET_OSS_FILTERS,
  UPDATE_PURCHASE_STATUS,
  SET_ITEMS_COUNT,
  BEGIN_PAGINATION_FETCH_CONTINUE,
  FIRST_BEGIN_PAGINATION_FETCH,
  PAGINATION_FETCH_SUCCESS,
  PAGINATION_FETCH_FAIL,
} from './types';

import { Properties } from '../config';

const beginFetchOss = () => ({
  type: BEGIN_OSS_FETCH,
});

const onFetchOssSuccess = (oss) => ({
  type: OSS_FETCH_SUCCESS,
  payload: { oss },
});

const onFetchOssFail = (error) => ({
  type: OSS_FETCH_FAIL,
  payload: { error },
});

export const fetchOSS = (uid, authToken) => {
  return async (dispatch) => {
    try {
      dispatch(beginFetchOss());
      const res = await fetch(
        !authToken
          ? `${Properties.API_HOST}packages`
          : `${Properties.API_HOST}u/${uid}/s/${authToken}/packages`
      );
      const json = await res.json();
      return dispatch(onFetchOssSuccess(json));
    } catch (error) {
      return dispatch(onFetchOssFail(error));
    }
  };
};

const beginFetchPaginationContinue = () => ({
  type: BEGIN_PAGINATION_FETCH_CONTINUE,
});

const firstBeginFetchPagination = () => ({
  type: FIRST_BEGIN_PAGINATION_FETCH,
});

const fetchPaginationSuccess = (oss) => ({
  type: PAGINATION_FETCH_SUCCESS,
  payload: { oss },
});

const fetchPaginationFail = (error) => ({
  type: PAGINATION_FETCH_FAIL,
  payload: { error },
});

export const fetchPagination = (uid, authToken, lastUniqueId = 0) => {
         return async (dispatch) => {
           try {
             if (lastUniqueId === 0) {
               dispatch(firstBeginFetchPagination());
             } else {
               dispatch(beginFetchPaginationContinue());
             }
             const res = await fetch(
               !authToken
                 ? `${Properties.API_HOST}pagination/id/${lastUniqueId}`
                 : `${Properties.API_HOST}pagination/u/${uid}/s/${authToken}/id/${lastUniqueId}`
               // `${Properties.API_HOST}pagination/${lastUniqueId}`
             );
             const json = await res.json();
             return dispatch(fetchPaginationSuccess(json));
           } catch (error) {
             return dispatch(fetchPaginationFail(error));
           }
         };
       };

export const setItemsCount = (itemsNr) => ({
  type: SET_ITEMS_COUNT,
  payload: { itemsNr },
});

export const Search = (keyword) => ({
  type: OSS_SEARCH,
  payload: { keyword },
});

export const setOssFilters = (filters) => ({
  type: SET_OSS_FILTERS,
  payload: {
    filters,
  },
});

export const updatePurchaseStatus = (purchasedArtifacts) => ({
  type: UPDATE_PURCHASE_STATUS,
  payload: {
    purchasedArtifacts,
  },
});
